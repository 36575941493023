import React, {Fragment, useState} from 'react';
import {connect} from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import {v4 as uuidv4} from 'uuid';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from "@material-ui/icons/Search";
import AutocompleteSearchInput from "../autocomplete-search-input";
import {getTrimmedStr} from "../../utils/formatters";
import Tooltip from '@material-ui/core/Tooltip';

import {useTranslation} from "react-i18next";

const $ = window.jQuery;

const SearchDialog = ({dialogTop, query, onSubmit, catalog}) => {

  const [uuid] = useState(uuidv4());
  const [isOpen, setIsOpen] = useState(false);

  const {t} = useTranslation();

  const datasets = Object.keys(catalog?.datasets || {}).map(key => catalog.datasets[key].title);
  const uncategorizedDatasets = (catalog?.uncategorizedDatasets || []).map(({title}) => title);

  return (
    <Fragment>
      <Tooltip title={t("components.header.actions.search.title")}>
        <IconButton
          color="inherit"
          onClick={() => setIsOpen(true)}
          aria-label={t("components.header.actions.search.ariaLabel")}
        >
          <SearchIcon/>
        </IconButton>
      </Tooltip>
      <Dialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        onRendered={() => $(`#search-dialog__dialog__${uuid} input`).focus()}
        PaperProps={{
          style: {
            position: "absolute",
            top: dialogTop,
            height: 40
          }
        }}
        maxWidth={false}
      >
        <div id={`search-dialog__dialog__${uuid}`} style={{width: "75vw"}}>
          <AutocompleteSearchInput
            query={query}
            onSubmit={value => {
              const trimmedValue = getTrimmedStr(value);
              if (trimmedValue.length > 0) {
                setIsOpen(false);
                onSubmit(trimmedValue);
              }
            }}
            options={datasets.concat(uncategorizedDatasets)}
          />
        </div>
      </Dialog>
    </Fragment>
  );
};

export default connect(state => ({catalog: state.catalog}))(SearchDialog);