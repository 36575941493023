import React, {Component, createRef} from 'react';
import {compose} from "redux";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {withStyles} from "@material-ui/core";
import ChartJs from 'chart.js';
import "chartjs-plugin-zoom";
import "chartjs-plugin-datalabels";
import {getFormattedValue} from "../../utils/formatters";
import {
  FREQ_DIMENSION_KEY,
  getAttributeLabel,
  getAttributeValueLabel,
  getDataIdxFromCoordinatesArray,
  getDimensionAttributeMap,
  getDimensionLabelFromJsonStat,
  getDimensionValueLabelFromJsonStat,
  getDimensionValuesIndexesMap,
  getObservationAttributeMap,
  getVariationValueLabels,
  TIME_PERIOD_DIMENSION_KEY,
  VARIATION_DIMENSION_KEY,
  VARIATION_VALUE_CYCLICAL_KEY,
  VARIATION_VALUE_TREND_KEY,
  VARIATION_VALUE_VALUE_KEY
} from "../../utils/jsonStat";
import CustomEmpty from "../custom-empty";
import _ from "lodash";
import {v4 as uuidv4} from 'uuid';
import {getLightOrDarkColorBasedOnContrastRatio, getNthValorizedElementIndexInBooleanArray} from "../../utils/other";
import {CHART_COLORS_ALL_DIMENSION_VALUES_KEY} from "../chart-settings/Colors";
import {LABEL_FORMAT_SELECTOR_LABEL_FORMAT_NAME} from "../label-format-selector/constants";
import {localizeI18nObj} from "../../utils/i18n";

const styles = theme => ({
  root: {
    width: "100%",
    height: "100%"
  }
});

const PERCENTAGE_AXES_ID = "PERCENTAGE_AXES_ID";

const AXIS_DECIMAL_PLACES_DEFAULT = 2;

export const CHART_LEGEND_POSITION_TOP = "top";
export const CHART_LEGEND_POSITION_RIGHT = "right";
export const CHART_LEGEND_POSITION_BOTTOM = "bottom";
export const CHART_LEGEND_POSITION_lEFT = "left";
export const CHART_LEGEND_POSITION_DEFAULT = CHART_LEGEND_POSITION_TOP;

export const CHART_DATA_LABEL_TYPE_NONE = "CHART_DATA_LABEL_TYPE_NONE";
export const CHART_DATA_LABEL_TYPE_VALUE = "CHART_DATA_LABEL_TYPE_VALUE";
export const CHART_DATA_LABEL_TYPE_PERCENTAGE = "CHART_DATA_LABEL_TYPE_PERCENTAGE";
export const CHART_DATA_LABEL_TYPE_DEFAULT = CHART_DATA_LABEL_TYPE_NONE;

const defaultColorArr = [
  "rgba(30, 136, 229, 0.5)",
  "rgba(194, 24, 91, 0.5)",
  "rgba(253, 216, 53, 0.5)",
  "rgba(14, 157, 89, 0.5)",
  "rgba(240, 98, 146, 0.5)",
  "rgba(255, 112, 67, 0.5)",
  "rgba(139, 195, 74, 0.5)",
  "rgba(0, 188, 212, 0.5)",
  "rgba(234, 63, 77, 0.5)",
  "rgba(170, 71, 188, 0.5)",
  "rgba(38, 166, 154, 0.5)",
  "rgba(255, 152, 0, 0.5)"
];

const defaultChartSettings = {
  chartStacked: false,
  chartLegendPosition: CHART_LEGEND_POSITION_DEFAULT,
  chartColors: {},
  chartShowAxesLabel: true,
  chartCustomizeCategoryAxis: false,
  chartCategoryAxisLabel: {},
  chartValueAxisLabel: {},
  chartDataLabelType: CHART_DATA_LABEL_TYPE_DEFAULT
};

const getCompleteChartSettings = chartSetting => {
  const newChartSettings = {};

  if (chartSetting === null || chartSetting === undefined) {
    return defaultChartSettings;
  }

  Object.keys(defaultChartSettings).forEach(key => {
    newChartSettings[key] = (chartSetting[key] !== null && chartSetting[key] !== undefined)
      ? chartSetting[key]
      : defaultChartSettings[key]
  });

  return newChartSettings;
};

export const CHART_TYPE_BAR = "bar";
export const CHART_TYPE_HORIZONTAL_BAR = "horizontalBar";
export const CHART_TYPE_LINE = "line";
export const CHART_TYPE_AREA = "area";
export const CHART_TYPE_DOUGHNUT = "doughnut";
export const CHART_TYPE_PIE = "pie";
export const CHART_TYPE_RADAR = "radar";
export const CHART_TYPE_POLAR_AREA = "polarArea";
export const CHART_TYPE_PYRAMID = "pyramid";

const getChartType = type => {
  switch (type) {
    case CHART_TYPE_BAR:
      return "bar"
    case CHART_TYPE_HORIZONTAL_BAR:
      return "horizontalBar"
    case CHART_TYPE_LINE:
      return "bar" // needed to fix line + bar chart
    case CHART_TYPE_AREA:
      return "line"
    case CHART_TYPE_DOUGHNUT:
      return "doughnut"
    case CHART_TYPE_PIE:
      return "pie"
    case CHART_TYPE_RADAR:
      return "radar"
    case CHART_TYPE_POLAR_AREA:
      return "polarArea"
    case CHART_TYPE_PYRAMID:
      return "horizontalBar"
    default:
      return type;
  }
};

const getVariationChartType = type => {
  switch (type) {
    case CHART_TYPE_BAR:
      return "line";
    case CHART_TYPE_LINE:
      return "bar";
    default:
      return null;
  }
};

const isBarChart = type => (type === CHART_TYPE_BAR || type === CHART_TYPE_HORIZONTAL_BAR || type === CHART_TYPE_PYRAMID);

const isLineChart = type => (type === CHART_TYPE_LINE || type === CHART_TYPE_AREA || type === CHART_TYPE_RADAR);

const isBarOrLineChart = type => (isBarChart(type) || isLineChart(type));

const getData = (
  chartType,
  jsonStat,
  layout,
  hiddenAttributes,
  timePeriodsByFreq,
  labelFormat,
  chartSettings,
  showTrend = false,
  showCyclical = false,
  t
) => {

  const {
    primaryDim: primaryDimArr,
    primaryDimValues: originalPrimaryDimValues,
    secondaryDim: secondaryDimArr,
    secondaryDimValues: originalSecondaryDimValues,
    filters,
    filtersValue
  } = layout;

  const primaryDim = primaryDimArr[0];
  const primaryDimValues = primaryDim
    ? jsonStat.dimension[primaryDim].category.index.filter(dimVal => originalPrimaryDimValues.includes(dimVal))
    : originalPrimaryDimValues;
  if (chartType === CHART_TYPE_PYRAMID) {
    primaryDimValues.reverse();
  }

  const secondaryDim = secondaryDimArr[0];
  const secondaryDimValues = secondaryDim
    ? jsonStat.dimension[secondaryDim].category.index.filter(dimVal => originalSecondaryDimValues.includes(dimVal))
    : originalSecondaryDimValues;

  const indexesMap = getDimensionValuesIndexesMap(jsonStat);
  const dimAttributeMap = getDimensionAttributeMap(jsonStat, hiddenAttributes);
  const obsAttributeMap = getObservationAttributeMap(jsonStat, hiddenAttributes);
  if (obsAttributeMap === null) {
    console.error(t("components.chart.error.observationAttribute"));
  }

  const valorizedDataMap = {};
  secondaryDimValues.forEach(secondaryDimValue => {
    valorizedDataMap[secondaryDimValue] = new Array(primaryDimValues.length).fill(false);
  });
  const valorizedDataArr = new Array(primaryDimValues.length).fill(false);

  const getParsedValue = value => {
    if (value !== null && value !== "" && !isNaN(value)) {
      return Number(value);
    } else {
      return null;
    }
  };

  let datasets = [];

  const getDataset = (secondaryDimValue, secDimIdx) => {
    const datasets = [];

    const getData = filtersValue => {
      const data = [];
      const dataAttributes = [];

      primaryDimValues.forEach((primaryDimValue, primDimIdx) => {
        const dimValueArray = jsonStat.id.map(dim => {
          if (dim === primaryDim) {
            return primaryDimValue;
          } else if (secondaryDim && dim === secondaryDim) {
            return secondaryDimValue;
          } else {
            return filtersValue[dim];
          }
        });
        const coordinates = dimValueArray.map((value, idx) => indexesMap[jsonStat.id[idx]][value]);
        const valueIdx = getDataIdxFromCoordinatesArray(coordinates, jsonStat.size);
        const value = getParsedValue(jsonStat.value[valueIdx]);
        if (value !== null) {
          if (secondaryDim && chartType === CHART_TYPE_PYRAMID && secondaryDimValues.length === 2 && secDimIdx === 0) {
            data.push(-value);
          } else {
            data.push(value);
          }
          if (secondaryDimValue) {
            valorizedDataMap[secondaryDimValue][primDimIdx] = true;
          } else {
            valorizedDataArr[primDimIdx] = true;
          }
        } else {
          data.push(null);
        }

        const attributes = {
          observation: obsAttributeMap?.[valueIdx] || null,
          primaryDim: dimAttributeMap?.[primaryDim]?.[primaryDimValue] || null,
          secondaryDim: dimAttributeMap?.[secondaryDim]?.[secondaryDimValue] || null
        }

        dataAttributes.push(attributes);
      });

      return {
        data,
        dataAttributes
      };
    };

    if (jsonStat.id.includes(VARIATION_DIMENSION_KEY)) {
      const variations = [VARIATION_VALUE_VALUE_KEY];
      if (showTrend && getVariationChartType(chartType)) {
        variations.push(VARIATION_VALUE_TREND_KEY);
      }
      if (showCyclical && getVariationChartType(chartType)) {
        variations.push(VARIATION_VALUE_CYCLICAL_KEY);
      }

      variations.forEach(variationDimValue => {
        const {data, dataAttributes} = getData({...filtersValue, [VARIATION_DIMENSION_KEY]: variationDimValue});
        const label = secondaryDim
          ? (
            getDimensionValueLabelFromJsonStat(jsonStat, secondaryDim, secondaryDimValue, labelFormat, t) +
            (variations.length > 1 ? ` (${getVariationValueLabels(t)[variationDimValue]})` : "")
          )
          : getVariationValueLabels(t)[variationDimValue];

        datasets.push({
          label: label,
          data: data,
          dataAttributes: dataAttributes,
          type: variationDimValue !== VARIATION_VALUE_VALUE_KEY
            ? getVariationChartType(chartType)
            : chartType === CHART_TYPE_LINE ? "line" : getChartType(chartType), // needed to fix line + bar chart
          yAxisID: variationDimValue !== VARIATION_VALUE_VALUE_KEY
            ? PERCENTAGE_AXES_ID
            : undefined,
          stack: variationDimValue !== VARIATION_VALUE_VALUE_KEY
            ? label
            : undefined,
          isVariation: variationDimValue !== VARIATION_VALUE_VALUE_KEY
        });
      });

    } else {
      const {data, dataAttributes} = getData(filtersValue);
      datasets.push({
        label: secondaryDim
          ? getDimensionValueLabelFromJsonStat(jsonStat, secondaryDim, secondaryDimValue, labelFormat, t)
          : "",
        data: data,
        type: chartType === CHART_TYPE_LINE ? "line" : getChartType(chartType), // needed to fix line + bar chart
        dataAttributes: dataAttributes
      });
    }

    return datasets;
  };

  if (secondaryDimValues && secondaryDimValues.length > 0) {
    secondaryDimValues.forEach((secondaryDimValue, secDimIdx) => {
      datasets = datasets.concat(getDataset(secondaryDimValue, secDimIdx));
    });

  } else {
    datasets = datasets.concat(getDataset());
  }

  let valorizedValues;

  if (chartType === CHART_TYPE_LINE && primaryDim === TIME_PERIOD_DIMENSION_KEY && timePeriodsByFreq) {
    let availableTimePeriods = [];
    if (filters.includes(FREQ_DIMENSION_KEY)) {
      availableTimePeriods = timePeriodsByFreq[filtersValue[FREQ_DIMENSION_KEY]];
    } else { // FREQ as secondary dimension
      secondaryDimValues.forEach(freq => availableTimePeriods = availableTimePeriods.concat(timePeriodsByFreq[freq]));
    }
    valorizedValues = primaryDimValues.map(primDimVal => availableTimePeriods.includes(primDimVal));

  } else if (secondaryDimValues && secondaryDimValues.length > 0) {
    valorizedValues = new Array(primaryDimValues.length);
    primaryDimValues.forEach((_, idx) => {
      let found = false;
      secondaryDimValues.forEach(secDimVal => {
        if (!found && valorizedDataMap[secDimVal][idx] === true) {
          found = true;
        }
      });
      valorizedValues[idx] = found;
    });

  } else {
    valorizedValues = [...valorizedDataArr];
  }

  datasets.forEach(dataset => {
    dataset.data = dataset.data.filter((_, idx) => valorizedValues[idx])
    dataset.dataAttributes = dataset.dataAttributes.filter((_, idx) => valorizedValues[idx])
  });

  const labels = [];
  primaryDimValues.forEach((value, idx) => {
    if (valorizedValues[idx]) {
      labels.push(getDimensionValueLabelFromJsonStat(jsonStat, primaryDim, value, labelFormat));
    }
  });

  const getPrimaryDimValueColors = datasetIdx => {
    return datasets[datasetIdx].data.map((_, valIdx) => {
      const idx = getNthValorizedElementIndexInBooleanArray(valorizedValues, valIdx);

      return chartSettings.chartColors?.[primaryDim]?.[primaryDimValues[idx]] ||
        chartSettings.chartColors?.[secondaryDim]?.[secondaryDimValues[datasetIdx]] ||
        (!secondaryDim && chartSettings.chartColors?.[primaryDim]?.[CHART_COLORS_ALL_DIMENSION_VALUES_KEY]) ||
        defaultColorArr[datasetIdx % defaultColorArr.length];
    });
  };

  const getDatasetColors = datasetIdx => {
    if (isBarChart(chartType)) {
      return getPrimaryDimValueColors(datasetIdx);

    } else if (isLineChart(chartType)) {
      return (secondaryDim && chartSettings.chartColors?.[secondaryDim]?.[secondaryDimValues[datasetIdx]]) ||
        (!secondaryDim && chartSettings.chartColors?.[primaryDim]?.[CHART_COLORS_ALL_DIMENSION_VALUES_KEY]) ||
        defaultColorArr[datasetIdx % defaultColorArr.length];

    } else {
      return datasets[datasetIdx].data.map((_, valIdx) => {
        const idx = getNthValorizedElementIndexInBooleanArray(valorizedValues, valIdx);

        return chartSettings.chartColors?.[primaryDim]?.[primaryDimValues[idx]] ||
          defaultColorArr[valIdx % defaultColorArr.length];
      });
    }
  };

  const getFill = datasetIdx => {
    let fill = false;
    if (chartType === CHART_TYPE_AREA) {
      fill = chartSettings.chartStacked
        ? datasetIdx === 0 ? "origin" : "-1"
        : "origin";
    } else if (chartType === CHART_TYPE_RADAR) {
      fill = "origin";
    }
    return fill
  };

  return {
    labels: labels,
    datasets: datasets.map((dataset, datasetIdx) => ({
      ...dataset,
      backgroundColor: getDatasetColors(datasetIdx),
      borderColor: isBarOrLineChart(chartType) ? getDatasetColors(datasetIdx) : "white",
      borderWidth: isBarOrLineChart(chartType) ? 2 : 1,
      lineTension: chartType === CHART_TYPE_AREA ? 0 : undefined,
      fill: getFill(datasetIdx),
      pointBackgroundColor: getPrimaryDimValueColors(datasetIdx),
      pointRadius: 4,
      pointHoverRadius: 4,
      pointBorderColor: "white",
      pointBorderWidth: 1,
      pointHoverBorderWidth: 1
    }))
  };
};

const getOptions = (
  chartType,
  jsonStat,
  data,
  labelFormat,
  primaryDimLabel,
  decimalSeparator,
  decimalPlaces,
  chartSettings,
  disableWheelZoom,
  showTrend = false,
  showCyclical = false,
  defaultLanguage,
  languages,
  t
) => {

  let max = 0;
  data.datasets.forEach(dataset => dataset.data.forEach(value => max = Math.max(max, Math.abs(value))));
  max += Math.floor(max / 100 * 20);

  const categoryAxisLabel = localizeI18nObj(chartSettings.chartCategoryAxisLabel, defaultLanguage, languages);
  const valueAxisLabel = localizeI18nObj(chartSettings.chartValueAxisLabel, defaultLanguage, languages);

  const xAxes = [];
  if ((isBarOrLineChart(chartType) && chartType !== CHART_TYPE_RADAR)) {
    xAxes.push({
      scaleLabel: (chartType !== CHART_TYPE_HORIZONTAL_BAR && chartType !== CHART_TYPE_PYRAMID)
        ? {
          display: chartSettings.chartShowAxesLabel,
          labelString: (chartSettings.chartCustomizeCategoryAxis && (categoryAxisLabel || "").length > 0)
            ? categoryAxisLabel
            : primaryDimLabel
        }
        : {
          display: (chartSettings.chartShowAxesLabel && (valueAxisLabel || "").length > 0),
          labelString: valueAxisLabel
        },
      gridLines: {
        display: chartType !== CHART_TYPE_BAR
      },
      ticks: {
        beginAtZero: (chartType === CHART_TYPE_HORIZONTAL_BAR || chartType === CHART_TYPE_PYRAMID),
        callback: value => {
          if (chartType === CHART_TYPE_HORIZONTAL_BAR || chartType === CHART_TYPE_PYRAMID) {
            const axisDecimalPlaces = decimalPlaces !== -1 ? decimalPlaces : AXIS_DECIMAL_PLACES_DEFAULT;
            if (chartType === CHART_TYPE_PYRAMID && data.datasets.length === 2 && value < 0) {
              return getFormattedValue(-value, decimalSeparator, axisDecimalPlaces);
            } else {
              return getFormattedValue(value, decimalSeparator, axisDecimalPlaces);
            }
          } else {
            return value;
          }
        },
        max: chartType === CHART_TYPE_PYRAMID ? max : undefined,
        min: chartType === CHART_TYPE_PYRAMID ? -(max) : undefined,
      },
      stacked: (chartType === CHART_TYPE_PYRAMID || (chartSettings.chartStacked && isBarOrLineChart(chartType)))
    });
  }

  const yAxes = [];
  if ((isBarOrLineChart(chartType) && chartType !== CHART_TYPE_RADAR)) {
    yAxes.push({
      scaleLabel: (chartType === CHART_TYPE_HORIZONTAL_BAR || chartType === CHART_TYPE_PYRAMID)
        ? {
          display: chartSettings.chartShowAxesLabel,
          labelString: (chartSettings.chartCustomizeCategoryAxis && (categoryAxisLabel || "").length > 0)
            ? categoryAxisLabel
            : primaryDimLabel
        }
        : {
          display: (chartSettings.chartShowAxesLabel && (valueAxisLabel || "").length > 0),
          labelString: valueAxisLabel
        },
      gridLines: {
        display: (chartType !== CHART_TYPE_HORIZONTAL_BAR && chartType !== CHART_TYPE_PYRAMID)
      },
      ticks: {
        beginAtZero: (chartType !== CHART_TYPE_HORIZONTAL_BAR && chartType !== CHART_TYPE_PYRAMID),
        callback: value => {
          if ((chartType !== CHART_TYPE_HORIZONTAL_BAR && chartType !== CHART_TYPE_PYRAMID)) {
            const axisDecimalPlaces = decimalPlaces !== -1 ? decimalPlaces : AXIS_DECIMAL_PLACES_DEFAULT;
            return getFormattedValue(value, decimalSeparator, axisDecimalPlaces);
          } else {
            return value;
          }
        }
      },
      stacked: (chartType === CHART_TYPE_PYRAMID || (chartSettings.chartStacked && isBarOrLineChart(chartType)))
    });
    if (jsonStat.id.includes(VARIATION_DIMENSION_KEY) && (showTrend || showCyclical) && getVariationChartType(chartType)) {
      yAxes.push({
        id: PERCENTAGE_AXES_ID,
        scaleLabel: {
          display: chartSettings.chartShowAxesLabel,
          labelString: t("components.chart.axis.variation.label") + " (%)"
        },
        gridLines: {
          display: false
        },
        position: "right"
      });
    }
  }

  return {
    responsive: true,
    maintainAspectRatio: false,
    hover: {
      animationDuration: 0
    },
    scales: {
      xAxes: xAxes,
      yAxes: yAxes,
    },
    legend: {
      display: (!isBarOrLineChart(chartType) || (data.datasets || []).length > 1),
      position: chartSettings.chartLegendPosition
    },
    tooltips: {
      enabled: true,
      mode: "point",
      callbacks: {
        title: (tooltipItems, data) => (data.labels[tooltipItems[0].index] || ''),
        label: (tooltipItem, data) => {
          const getValue = dataset => {
            let value = dataset.data[tooltipItem.index];
            if (!value) {
              return null;
            }
            value = getFormattedValue(value, decimalSeparator, decimalPlaces);
            if (chartType === CHART_TYPE_PYRAMID && data.datasets.length === 2 && tooltipItem.datasetIndex === 0 && value[0] === "-") {
              return value.slice(1);
            } else {
              return value;
            }
          };
          const dataset = data.datasets[tooltipItem.datasetIndex];
          const value = getValue(dataset);
          return value
            ? `${dataset.label}: ${value}` + (dataset.isVariation ? "%" : "")
            : null;
        },
        footer: (tooltipItems, data) => {
          const datasetIdx = tooltipItems[0].datasetIndex;
          const dataIdx = tooltipItems[0].index;

          const attributes = data.datasets[datasetIdx].dataAttributes[dataIdx];
          const labels = [];

          /** observation **/
          if (attributes.observation && (attributes.observation.attributes || []).length > 0) {
            labels.push(t("components.chart.tooltip.attributes.observation.label") + ":"); // TODO
            attributes.observation.attributes.forEach(attr => {
              labels.push(`- ${getAttributeLabel(attr, labelFormat)}: ${getAttributeValueLabel(attr, labelFormat)}`);
            });
          }

          return labels;
        }
      },
      footerFontStyle: "normal",
      footerMarginTop: 12,
    },
    plugins: {
      zoom: disableWheelZoom
        ? {}
        : {
          pan: {
            enabled: (isBarOrLineChart(chartType) && chartType !== CHART_TYPE_RADAR),
            mode: () => (chartType === CHART_TYPE_HORIZONTAL_BAR || chartType === CHART_TYPE_PYRAMID) ? "x" : "y"
          },
          zoom: {
            enabled: (isBarOrLineChart(chartType) && chartType !== CHART_TYPE_RADAR),
            mode: () => (chartType === CHART_TYPE_HORIZONTAL_BAR || chartType === CHART_TYPE_PYRAMID) ? "x" : "y"
          }
        },
      datalabels: {
        labels: {
          value: {
            display: chartSettings.chartDataLabelType === CHART_DATA_LABEL_TYPE_NONE
              ? false
              : "auto",
            formatter: (value, ctx) => {
              const dataset = data.datasets[ctx.datasetIndex];

              if (chartSettings.chartDataLabelType === CHART_DATA_LABEL_TYPE_VALUE) {
                return getFormattedValue(value, decimalSeparator, decimalPlaces) + (dataset.isVariation ? "%" : "");

              } else if (chartSettings.chartDataLabelType === CHART_DATA_LABEL_TYPE_PERCENTAGE) {
                let sum = 0;
                dataset.data.forEach(data => {
                  sum += data;
                });
                return (value * 100 / sum).toFixed(1) + "%";

              } else {
                return "";
              }
            },
            color: ctx => {
              const rgbaLightColor = "rgba(255, 255, 255, 0.9)";
              const rgbaDarkColor = "rgba(42, 42, 42, 1)";

              if (isLineChart(chartType)) {
                return rgbaDarkColor;
              }

              const color = ctx.dataset.backgroundColor[ctx.dataIndex];
              return color.startsWith("rgba")
                ? getLightOrDarkColorBasedOnContrastRatio(color, rgbaLightColor, rgbaDarkColor)
                : rgbaLightColor;
            },
            font: {
              size: 14
            },
            align: (chartType === CHART_TYPE_HORIZONTAL_BAR || chartType === CHART_TYPE_PYRAMID) ? "left" : "bottom",
            offset: 2
          },
          attribute: {
            formatter: (value, ctx) => {
              const attributes = data.datasets[ctx.datasetIndex].dataAttributes[ctx.dataIndex];
              return (attributes?.observation !== null && attributes?.observation !== undefined)
                ? "(*)"
                : null;
            },
            font: {
              size: 12,
              family: "Do Hyeon"
            },
            align: (chartSettings.chartDataLabelType === CHART_DATA_LABEL_TYPE_NONE)
              ? "center"
              : (chartType === CHART_TYPE_HORIZONTAL_BAR || chartType === CHART_TYPE_PYRAMID)
                ? "right"
                : "top",
            offset: 2
          }
        },
        clamp: true
      }
    }
  }
};

class Chart extends Component {

  constructor(props) {
    super(props);
    this.chartRef = createRef();
    this.state = {
      data: null
    }
  }

  componentDidMount() {
    const {
      t,
      defaultLanguage,
      languages,
      type,
      jsonStat,
      hiddenAttributes,
      layout,
      timePeriodsByFreq,
      disableWheelZoom = false,
      labelFormat = LABEL_FORMAT_SELECTOR_LABEL_FORMAT_NAME,
      decimalSeparator,
      decimalPlaces,
      chartSettings: externalChartSettings,
      showTrend,
      showCyclical
    } = this.props;

    ChartJs.defaults.global.defaultFontFamily = "'Roboto', 'Helvetica', 'Arial', sans-serif";

    const chartSettings = getCompleteChartSettings(externalChartSettings);

    const data = getData(
      type,
      jsonStat,
      layout,
      hiddenAttributes,
      timePeriodsByFreq,
      labelFormat,
      chartSettings,
      showTrend,
      showCyclical,
      t
    );
    this.setState({data: data});

    const options = getOptions(
      type,
      jsonStat,
      data,
      labelFormat,
      getDimensionLabelFromJsonStat(jsonStat, layout.primaryDim[0], labelFormat),
      decimalSeparator,
      decimalPlaces,
      chartSettings,
      disableWheelZoom,
      showTrend,
      showCyclical,
      defaultLanguage,
      languages,
      t
    );

    this.myChart = new ChartJs(this.chartRef.current, {
      type: getChartType(type),
      data: data,
      options: options,
      plugins: [
        {
          beforeDraw: function (chart) {
            const ctx = chart.chart.ctx;
            ctx.fillStyle = "white";
            ctx.fillRect(0, 0, chart.chart.width, chart.chart.height);
          }
        }
      ]
    });
  }

  componentDidUpdate(prevProps) {
    const {
      t,
      defaultLanguage,
      languages,
      type,
      jsonStat,
      hiddenAttributes,
      layout,
      timePeriodsByFreq,
      disableWheelZoom,
      labelFormat,
      decimalSeparator,
      decimalPlaces,
      chartSettings: externalChartSettings,
      showTrend,
      showCyclical
    } = this.props;

    const prevChartSettings = getCompleteChartSettings(prevProps.chartSettings);
    const newChartSettings = getCompleteChartSettings(externalChartSettings);

    if (
      prevProps.jsonStat !== jsonStat ||
      prevProps.type !== type ||
      !_.isEqual(prevProps.layout, layout) ||
      (prevProps.disableWheelZoom !== disableWheelZoom) ||
      (prevProps.labelFormat !== labelFormat) ||
      (prevProps.decimalSeparator !== decimalSeparator) ||
      (prevProps.decimalPlaces !== decimalPlaces) ||
      !_.isEqual(prevChartSettings, newChartSettings) ||
      (prevProps.showTrend !== showTrend) ||
      (prevProps.showCyclical !== showCyclical)
    ) {

      const data = getData(
        type,
        jsonStat,
        layout,
        hiddenAttributes,
        timePeriodsByFreq,
        labelFormat,
        newChartSettings,
        showTrend,
        showCyclical,
        t
      );
      this.setState({data: data});

      const options = getOptions(
        type,
        jsonStat,
        data,
        labelFormat,
        getDimensionLabelFromJsonStat(jsonStat, layout.primaryDim[0], labelFormat),
        decimalSeparator,
        decimalPlaces,
        newChartSettings,
        disableWheelZoom,
        showTrend,
        showCyclical,
        defaultLanguage,
        languages,
        t
      );

      this.myChart.config.type = getChartType(type);
      this.myChart.data = data;
      this.myChart.options = options;
      this.myChart.update();
    }
  }

  render() {
    const {
      data
    } = this.state;

    const {
      t,
      classes,
      chartId = `cart__${uuidv4()}`,
      type,
      layout
    } = this.props;

    const isInvalidPyramid = (type === CHART_TYPE_PYRAMID && ((layout.secondaryDim || []).length !== 1 || (layout.secondaryDimValues || []).length !== 2));
    const dataCount = (data?.datasets || []).reduce((acc, {data}) => acc + data.length, 0);
    const isChartEmpty = dataCount === 0;

    const isChartVisible = (!isInvalidPyramid && !isChartEmpty);

    return (
      <div className={classes.root} aria-hidden={true}>
        {!isChartVisible && (
          <CustomEmpty
            text={isChartEmpty
              ? t("components.chart.emptyChart")
              : t("components.chart.invalidPyramid")
            }
          />
        )}
        <canvas
          id={chartId}
          ref={this.chartRef}
          style={{
            display: isChartVisible ? "block" : "none"
          }}
        />
      </div>
    );
  }
}

export default compose(
  withStyles(styles),
  withTranslation(),
  connect(state => ({
    defaultLanguage: state.app.language,
    languages: state.app.languages
  }))
)(Chart);