import React, {Component, Fragment} from 'react';
import NodeHeader from "../node-header";
import {withStyles} from "@material-ui/core";
import Page from "../page";
import Container from "@material-ui/core/Container";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {getDatasetInternalUrl, getDatasetsInternalUrl, getHomeInternalUrl, getNodeCategoriesInternalUrl, getNodeInternalUrl} from "../../links";
import CardActions from '@material-ui/core/CardActions';
import IconButton from "@material-ui/core/IconButton";
import ListIcon from '@material-ui/icons/List';
import GridOnIcon from '@material-ui/icons/GridOn';
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import ResultTree from "./ResultTree";
import {withTranslation} from "react-i18next";
import {compose} from "redux";
import FolderIcon from "@material-ui/icons/Folder";
import DatasetCard from "./DatasetCard";
import {scrollResultsToDatasetByParam} from "./utils";
import {withRouter} from "react-router";
import {HashLink} from 'react-router-hash-link';
import CatalogInfoButton from "../catalog-info-button";
import CatalogMetadataButton from "../catalog-metadata-button";
import {getPageTitle} from "../../utils/other";
import Footer from "../footer";
import CustomLink from "../custom-link";
import Tooltip from "@material-ui/core/Tooltip";
import CustomEmpty from "../custom-empty";
import Divider from "@material-ui/core/Divider";

const styles = theme => ({
  fullWidthContainer: {
    width: "100%",
    minHeight: "100%",
    backgroundColor: "#f5f5f5",
    display: "flex",
    flexDirection: "column"
  },
  toolbar: {
    minHeight: 48,
    marginTop: theme.spacing(3)
  },
  searchTitle: {
    fontSize: 24,
    fontWeight: 400,
    letterSpacing: 0,
    whiteSpace: "pre-wrap"
  },
  categoriesTitle: {
    fontSize: 28,
    fontWeight: 300,
    letterSpacing: 0
  },
  breadcrumbs: {
    minHeight: 48,
    padding: "8px 0",
    display: "flex"
  },
  breadcrumbsSeparator: {
    margin: "0 4px"
  },
  breadcrumbsLastElem: {
    color: theme.palette.primary.main,
    padding: 4
  },
  results: {
    marginTop: theme.spacing(1)
  },
  row: {
    marginBottom: theme.spacing(1)
  },
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between"
  },
  cardActions: {
    padding: "4px 8px"
  },
  divider: {
    margin: "16px 0"
  }
});

class Results extends Component {

  state = {
    expandedIndexes: [],
    configIsListMode: this.props.hub.nodes
      .find(({code}) => code.toLowerCase() === this.props.nodeCode.toLowerCase())
      .catalogNavigationMode === "List",
    isListMode:
      this.props.hub.nodes
        .find(({code}) => code.toLowerCase() === this.props.nodeCode.toLowerCase())
        .catalogNavigationMode === "List"
  };

  static getDerivedStateFromProps(props, state) {

    const configIsListMode = props.hub.nodes
      .find(({code}) => code.toLowerCase() === props.nodeCode.toLowerCase())
      .catalogNavigationMode === "List";

    if (configIsListMode !== state.configIsListMode) {
      return {
        ...state,
        configIsListMode,
        isListMode: configIsListMode
      };
    } else {
      return null;
    }
  }

  componentDidMount() {
    scrollResultsToDatasetByParam();
  }

  expand = index =>
    this.setState({
      expandedIndexes: [...this.state.expandedIndexes, index]
    });

  collapse = index =>
    this.setState({
      expandedIndexes: [...this.state.expandedIndexes.filter(i => i !== index)]
    });

  onListModeToggle = () => {
    this.setState({isListMode: !this.state.isListMode});
  };

  render() {

    const {
      classes,
      query,
      hub,
      catalog,
      node,
      nodeCode,
      isDefault,
      categoryPath,
      datasets,
      subCategories,
      filters,
      categoryGroupsWithCount,
      uncategorizedDatasetsCount,
      t,
      isAccessible,
      onAccessibleDatasetFetch,
      location
    } = this.props;

    const {expandedIndexes, isListMode} = this.state;

    const nodeMinimalInfo = hub.nodes.find(({code}) => code.toLowerCase() === nodeCode.toLowerCase());

    const nodeExtras = nodeMinimalInfo?.extras || [];
    const hideLabelOnCategoryWithImage = JSON.parse(nodeExtras.find(({key}) => key === "HideLabelOnCategoryWithImage")?.value || "[]");

    let categoryPathLabels = [];
    if (categoryPath) {
      if (categoryPath[0] === "uncategorized") {
        categoryPathLabels = [t("commons.catalog.uncategorized")];
      } else {

        let category;

        if (catalog.categoryGroups.length > 1) {
          category = catalog.categoryGroups.find(({id}) => id === categoryPath[0]);
        } else {
          category = catalog.categoryGroups[0].categories.find(({id}) => id === categoryPath[0]);
        }

        categoryPathLabels.push(category.label);
        for (let i = 1; i < categoryPath.length; i++) {
          category = (category.childrenCategories || category.categories).find(({id}) => id === categoryPath[i]);
          categoryPathLabels.push(category.label);
        }
      }
    }

    const params = new URLSearchParams(location.search);
    params.set("accessible", "true");
    const paramsStr = params.toString();
    const path = location.pathname;

    return (
      <Fragment>
        {!isAccessible && (
          <a
            href={"./#" + path + (paramsStr.length > 0 ? "?" : "") + paramsStr}
            target="_self"
            className="skip-link sr-only"
          >
            {t("commons.hashLinks.accessible")}
          </a>
        )}
        <HashLink
          to={{hash: "#main", search: location.search}}
          className="skip-link sr-only"
        >
          {t("commons.hashLinks.main")}
        </HashLink>
        <HashLink
          to={{hash: "#footer", search: location.search}}
          className="skip-link sr-only"
        >
          {t("commons.hashLinks.footer")}
        </HashLink>
        <Page
          title={query
            ? getPageTitle([t("scenes.results.title", {query: query, nodeName: nodeMinimalInfo?.name}), hub?.hub?.name], t)
            : getPageTitle([categoryPathLabels[categoryPathLabels.length - 1], nodeMinimalInfo?.name, hub?.hub?.name], t)
          }
        >
          <NodeHeader
            hub={hub.hub}
            query={query}
            nodes={hub.nodes}
            catalog={catalog}
            title={nodeMinimalInfo.name}
            node={node}
            nodeId={nodeMinimalInfo.nodeId}
            isDefault={isDefault}
            selectedCategoryPath={categoryPath}
          />
          <main id="main" className={classes.fullWidthContainer}>
            <Container>
              <Grid container justify="space-between" alignItems="flex-start" wrap="nowrap" className={classes.toolbar}>
                <Grid item>
                  {query
                    ? (
                      <Typography variant={"h1"} className={classes.searchTitle}>
                        {t("scenes.results.searchTitle", {query})}
                      </Typography>
                    )
                    : (
                      <Breadcrumbs
                        aria-label={t("components.results.breadcrumbs.ariaLabel")}
                        classes={{
                          separator: classes.breadcrumbsSeparator
                        }}
                        className={classes.breadcrumbs}
                      >
                        <CustomLink
                          to={getHomeInternalUrl()}
                          text={t("components.results.breadcrumbs.links.home")}
                          textStyle={{padding: 4}}
                        />
                        {(hub.nodes || []).length > 1 && (
                          <CustomLink
                            to={getNodeInternalUrl(node.code)}
                            text={node?.name || node.code}
                            textStyle={{padding: 4}}
                          />
                        )}
                        <CustomLink
                          to={getNodeCategoriesInternalUrl(node.code)}
                          text={t("scenes.categories.title")}
                          textStyle={{padding: 4}}
                        />
                        {categoryPath.map((id, idx) => {
                          if (idx < categoryPath.length - 1 && id !== "uncategorized") {
                            return (
                              <CustomLink
                                key={idx}
                                to={getDatasetsInternalUrl(node.code, categoryPath.slice(0, idx + 1))}
                                text={categoryPathLabels[idx]}
                                textStyle={{padding: 4}}
                              />
                            );
                          } else {
                            return (
                              <span key={idx} className={classes.breadcrumbsLastElem}>
                                {categoryPathLabels[idx]}
                              </span>
                            );
                          }
                        })}
                      </Breadcrumbs>
                    )
                  }
                </Grid>
                {!isAccessible && (
                  <Grid item>
                    {datasets.length > 0 && (
                      <Tooltip
                        title={isListMode
                          ? t("components.results.actions.listModeToggle.grid.tooltip")
                          : t("components.results.actions.listModeToggle.list.tooltip")
                        }
                      >
                        <IconButton
                          onClick={this.onListModeToggle}
                          aria-label={isListMode
                            ? t("components.results.actions.listModeToggle.grid.ariaLabel")
                            : t("components.results.actions.listModeToggle.list.ariaLabel")
                          }
                        >
                          {isListMode
                            ? <GridOnIcon/>
                            : <ListIcon/>
                          }
                        </IconButton>
                      </Tooltip>
                    )}
                  </Grid>
                )}
              </Grid>
              {!query && (
                <Typography variant={"h1"} className={classes.categoriesTitle}>
                  {categoryPathLabels[categoryPath.length - 1]}
                </Typography>
              )}
              <Grid container spacing={2} className={classes.results}>
                {query && (
                  <Grid item style={{width: 256}}>
                    <ResultTree
                      query={query}
                      filters={filters}
                      catalog={catalog}
                      node={node}
                      datasets={datasets}
                      categoryGroupsWithCount={categoryGroupsWithCount}
                      uncategorizedDatasetsCount={uncategorizedDatasetsCount}
                    />
                  </Grid>
                )}
                <Grid item style={{width: query ? `calc(100% - 256px)` : "100%"}}>
                  {subCategories && subCategories.length
                    ? (
                      <Fragment>
                        <nav aria-label={t("components.results.subcategories.ariaLabel")}>
                          <Grid container spacing={2} className={classes.row}>
                            {subCategories.map(({id, label, datasetIdentifiers, description, metadataUrl, image}) =>
                              <Grid item key={id} md={4} xs={12}>
                                <Card className={classes.card}>
                                  <CustomLink
                                    to={getDatasetsInternalUrl(node.code.toLowerCase(), [...categoryPath, id])}
                                    text={(hideLabelOnCategoryWithImage && image)
                                      ? null
                                      : `${label} ${datasetIdentifiers && datasetIdentifiers.length ? `(${datasetIdentifiers.length})` : ''}`
                                    }
                                    textStyle={(hideLabelOnCategoryWithImage && image)
                                      ? {
                                        padding: 0
                                      }
                                      : {
                                        padding: 16,
                                        fontSize: 20
                                      }}
                                    image={image}
                                    imageStyle={{
                                      maxHeight: undefined
                                    }}
                                    icon={image
                                      ? undefined
                                      : <FolderIcon/>
                                    }
                                  />
                                  {(description || metadataUrl) && (
                                    <CardActions classes={{root: classes.cardActions}}>
                                      {description && (
                                        <CatalogInfoButton
                                          info={description}
                                          label={t("components.results.actions.description.title")}
                                          iconSize="small"
                                        />
                                      )}
                                      {metadataUrl && (
                                        <CatalogMetadataButton
                                          metadataUrl={metadataUrl}
                                          iconSize="small"
                                        />
                                      )}
                                    </CardActions>
                                  )}
                                </Card>
                              </Grid>
                            )}
                          </Grid>
                        </nav>
                        {datasets.length > 0 && <Divider className={classes.divider}/>}
                      </Fragment>
                    )
                    : null
                  }
                  {query && datasets.length
                    ? (
                      <Typography variant="body1" className={classes.row}>
                        {datasets.length > 1
                          ? t("scenes.results.datasetsCount.plural", {datasetsCount: datasets.length})
                          : t("scenes.results.datasetsCount.singular", {datasetsCount: datasets.length})
                        }:
                      </Typography>
                    )
                    : null
                  }
                  {datasets.length > 0
                    ? (
                      <nav aria-label={t("components.results.datasets.ariaLabel")}>
                        <Grid container spacing={2}>
                          {datasets.map((dataset, i) => (
                            <DatasetCard
                              key={i}
                              isExpanded={expandedIndexes.includes(i)}
                              onViewDataset={!isAccessible
                                ? getDatasetInternalUrl(node.code, dataset.categoryPath, dataset.identifier, undefined)
                                : location => location
                              }
                              onClick={!isAccessible
                                ? undefined
                                : () => onAccessibleDatasetFetch(dataset)
                              }
                              dataset={dataset}
                              onExpand={() => this.expand(i)}
                              onCollapse={() => this.collapse(i)}
                              xs={12}
                              md={isListMode ? 12 : 4}
                              isMultiCategorized={!!query && (JSON.stringify(catalog?.categoryGroups || {}).split(dataset.identifier).length - 1) > 1}
                            />
                          ))}
                        </Grid>
                      </nav>
                    )
                    : (query || !subCategories || !subCategories.length)
                      ? (
                        <CustomEmpty
                          text={t("scenes.results.datasetsCount.none")}
                          textStyle={{fontSize: 16}}
                          style={{marginTop: 32, marginLeft: query ? -128 : 0}}
                        />
                      )
                      : null
                  }
                </Grid>
              </Grid>
            </Container>
            <Footer/>
          </main>
        </Page>
      </Fragment>
    );
  }
}

export default compose(
  withStyles(styles),
  withTranslation(),
  withRouter
)(Results);