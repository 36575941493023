import React from "react";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import withStyles from "@material-ui/core/styles/withStyles";
import {DragDropContext} from "react-beautiful-dnd";
import Grid from "@material-ui/core/Grid";
import FilterListIcon from '@material-ui/icons/FilterList';
import ViewCompactIcon from '@material-ui/icons/ViewCompact';
import ViewStreamIcon from '@material-ui/icons/ViewStream';
import ViewColumnIcon from '@material-ui/icons/ViewColumn';
import Table from "../table";
import LayoutSection from "../layout-section";
import "./style.css";

const styles = theme => ({
  root: {
    margin: -8,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  layoutContainer: {
    width: "60%",
    height: "100%",
    padding: 8
  },
  previewContainer: {
    width: "40%",
    height: "100%",
    padding: 8
  },
  section: {
    height: 240,
    padding: 8
  }
});

const reorder = (list, startIndex, endIndex) => {
  const [removed] = list.splice(startIndex, 1);
  list.splice(endIndex, 0, removed);
};

const move = (source, destination, droppableSource, droppableDestination) => {
  const [removed] = source.splice(droppableSource.index, 1);
  destination.splice(droppableDestination.index, 0, removed);
};

const onDragEnd = (result, dimensions, setDimensions) => {

  const {
    source,
    destination
  } = result;

  // dropped outside the list
  if (!destination) {
    return;
  }

  const newDimensions = JSON.parse(JSON.stringify(dimensions));

  if (source.droppableId === destination.droppableId) {
    reorder(
      newDimensions[source.droppableId],
      source.index,
      destination.index
    );

  } else {
    move(
      newDimensions[source.droppableId],
      newDimensions[destination.droppableId],
      source,
      destination
    );
  }

  setDimensions(newDimensions);
};

const TableLayout = ({
                       t,
                       classes,
                       jsonStat,
                       layout,
                       labelFormat,
                       setLayout
                     }) => {
  const {
    filters,
    sections,
    rows,
    cols,
  } = layout;

  let colCount = 1;
  cols.forEach(col => colCount *= jsonStat.size[jsonStat.id.indexOf(col)]);
  let rowCount = 1;
  rows.forEach(row => rowCount *= jsonStat.size[jsonStat.id.indexOf(row)]);
  sections.forEach(section => rowCount *= jsonStat.size[jsonStat.id.indexOf(section)]);

  return (
    <div id="table-layout" className={classes.root}>
      <div id="table-layout__layout-container" className={classes.layoutContainer}>
        <DragDropContext onDragEnd={result => onDragEnd(result, layout, setLayout)}>
          <Grid container spacing={2} style={{height: "100%"}}>
            <Grid item xs={6} className={classes.section}>
              <LayoutSection
                id="filters"
                title={t("components.tableLayout.sections.filters")}
                Icon={<FilterListIcon/>}
                dimensions={filters}
                jsonStat={jsonStat}
              />
            </Grid>
            <Grid item xs={6} className={classes.section}>
              <LayoutSection
                id="sections"
                title={t("components.tableLayout.sections.sections")}
                Icon={<ViewCompactIcon/>}
                dimensions={sections}
                jsonStat={jsonStat}
              />
            </Grid>
            <Grid item xs={6} className={classes.section}>
              <LayoutSection
                id="rows"
                title={t("components.tableLayout.sections.rows")}
                Icon={<ViewStreamIcon/>}
                dimensions={rows}
                jsonStat={jsonStat}
              />
            </Grid>
            <Grid item xs={6} className={classes.section}>
              <LayoutSection
                id="cols"
                title={t("components.tableLayout.sections.columns")}
                Icon={<ViewColumnIcon/>}
                dimensions={cols}
                jsonStat={jsonStat}
              />
            </Grid>
          </Grid>
        </DragDropContext>
      </div>
      <div id="table-layout__preview-container" className={classes.previewContainer}>
        <Table
          jsonStat={jsonStat}
          layout={layout}
          labelFormat={labelFormat}
          isPreview={true}
        />
        <Grid container spacing={1} style={{marginTop: 16}}>
          <Grid item xs={12} style={{textAlign: "end"}}>
            {t("components.tableLayout.rowCount", {rowCount})}
          </Grid>
          <Grid item xs={12} style={{textAlign: "end"}}>
            {t("components.tableLayout.colCount", {colCount})}
          </Grid>
        </Grid>
      </div>
    </div>
  )
};

export default compose(
  withStyles(styles),
  withTranslation()
)(TableLayout)