import React, {Fragment, useCallback, useEffect, useState} from 'react';
import {compose} from "redux";
import {connect} from "react-redux";
import {v4 as uuidv4} from 'uuid';
import withStyles from "@material-ui/core/styles/withStyles";
import {getTableHtml} from "./utils";
import "./style.css"
import {setDatasetHtmlGeneratingTime} from "../../state/dataset/datasetActions";
import Scrollbars, {SLIDER_THICKNESS} from "./Scrollbars";
import {addSpinnerMessage, markSpinnerMessage} from "../../state/spinner/spinnerActions";
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import Dialog from "@material-ui/core/Dialog";
import CustomDialogTitle from "../custom-dialog-title";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import {useTranslation} from "react-i18next";
import {LABEL_FORMAT_SELECTOR_LABEL_FORMAT_NAME} from "../label-format-selector/constants";
import CustomEmpty from "../custom-empty";
import {sanitize} from "dompurify";
import {getAttributeLabel, getAttributeValueLabel, VARIATION_DIMENSION_KEY} from "../../utils/jsonStat";

const $ = window.jQuery;

export const JSONSTAT_TABLE_FONT_SIZE_SM = "s";
export const JSONSTAT_TABLE_FONT_SIZE_MD = "m";
export const JSONSTAT_TABLE_FONT_SIZE_LG = "l";

const COLS_PER_PAGE = 30;
const ROWS_PER_PAGE = 50;

const SLIDER_SAFETY_MARGIN_PERCENTAGE = 20;

let isFirstRender = false;

const styles = theme => ({
  root: {
    width: "100%",
    height: "100%"
  },
  table: {
    width: "100%",
    height: "100%"
  }
});

const mapDispatchToProps = dispatch => ({
  onTimeSet: time => dispatch(setDatasetHtmlGeneratingTime(time)),
  addSpinner: (uuid, message) => dispatch(addSpinnerMessage(uuid, message)),
  removeSpinner: (uuid, isError) => dispatch(markSpinnerMessage(uuid, isError)),
});

const isElementInContainer = (el, container, isVerticalScrollbarVisible, isHorizontalScrollbarVisible, checkOnlyVertical) => {
  const containerRect = container.getBoundingClientRect();
  const elementRect = el.getBoundingClientRect();

  return (
    elementRect.top >= containerRect.top &&
    elementRect.bottom <= (containerRect.bottom - (isHorizontalScrollbarVisible ? SLIDER_THICKNESS : 0)) &&
    (checkOnlyVertical === true || (
      elementRect.left >= containerRect.left &&
      elementRect.right <= (containerRect.right - (isVerticalScrollbarVisible ? SLIDER_THICKNESS : 0))
    ))
  );
}

function Table(props) {
  const {
    classes,
    jsonStat,
    layout,
    labelFormat = LABEL_FORMAT_SELECTOR_LABEL_FORMAT_NAME,
    fontSize,
    isFullscreen,
    isPreview = false,
    removeEmptyLines = !isPreview,
    decimalSeparator,
    decimalPlaces,
    emptyChar,
    hiddenAttributes,
    addSpinner,
    removeSpinner,
    hideSpinner = false,
    disableWheelZoom = false,
    showTrend = false,
    showCyclical = false,
    onTimeSet
  } = props;

  const {t} = useTranslation();

  const [uuid] = useState(uuidv4());
  const [spinnerUuid] = useState(uuidv4());

  const [tableSupportStructures, setTableSupportStructures] = useState(null);
  const [htmlTable, setHtmlTable] = useState("");

  const [row, setRow] = useState(0);
  const [col, setCol] = useState(0);

  const [visibleRowCount, setVisibleRowCount] = useState(1);
  const [visibleColCount, setVisibleColCount] = useState(1);

  const [isHorizontalScrollbarVisible, setHorizontalScrollbarVisibility] = useState(null);
  const [isVerticalScrollbarVisible, setVerticalScrollbarVisibility] = useState(null);

  const [attributes, setAttributes] = useState(null);
  const [isCopiedVisible, setCopiedVisibility] = useState(false);

  const [worker] = useState(() => new Worker("./workers/getTableSupportStructuresWorker.js"));

  useEffect(() => {
    return () => {
      if (worker) {
        worker.terminate();
      }
    }
  }, [worker]);

  const handleStyle = useCallback(
    (isFirstRender, isResizing) => {
      if (!isPreview && tableSupportStructures) {

        if (!isResizing) {

          /** attribute's tooltip handling **/
          const $tooltip = $(`.jsonstat-table__${uuid} #jsonstat-table__tooltip`);
          $(`.jsonstat-table__${uuid} .ca .ct`)
            .hover(
              function () {
                const $elem = $(this).get(0);
                const rect = $elem.getBoundingClientRect();

                const attributes = $elem.className.includes("ctd")
                  ? tableSupportStructures.obsAttributeMap[$elem.id].attributes
                  : tableSupportStructures.dimAttributeMap[$elem.id.split(",")[0]][$elem.id.split(",")[1]].attributes;

                const ATTRIBUTE_HEIGHT = 18;

                $tooltip.empty();
                attributes.forEach(attribute => {
                  $tooltip.append($(
                    `<li class="cttt"><b>${getAttributeLabel(attribute, labelFormat)}</b>: ${getAttributeValueLabel(attribute, labelFormat)}</li>`
                  ));
                });

                const left = rect.x < (window.innerWidth / 2)
                  ? rect.left
                  : rect.left - $tooltip.innerWidth();

                $tooltip.css({
                  visibility: "visible",
                  top: rect.top - (ATTRIBUTE_HEIGHT * attributes.length) - 30,
                  left: left
                });
              },
              function () {
                $tooltip.empty().css({visibility: "hidden"});
              }
            )
            .click(
              function () {
                const $elem = $(this).get(0);

                const attributes = $elem.className.includes("ctd")
                  ? tableSupportStructures.obsAttributeMap[$elem.id].attributes
                  : tableSupportStructures.dimAttributeMap[$elem.id.split(",")[0]][$elem.id.split(",")[1]].attributes;

                setAttributes(attributes);
              }
            );
        }

        removeSpinner(spinnerUuid);
      }
    },
    [uuid, tableSupportStructures, labelFormat, isPreview, removeSpinner, spinnerUuid]
  );

  const handleScrollbar = useCallback(
    updateVisibleRowAndColCount => {
      let $tableContainer = $(`.jsonstat-table__${uuid}`);

      if ($tableContainer && tableSupportStructures) {

        let isVerticalScrollbarVisible = null;
        let isHorizontalScrollbarVisible = null;

        const {rowCount, colCount} = tableSupportStructures;
        const $table = $(`.jsonstat-table__${uuid} table`);

        isVerticalScrollbarVisible = row !== null && row !== undefined && (
          row > 0 ||
          rowCount > ROWS_PER_PAGE ||
          ($table.height() > $tableContainer.height())
        );
        setVerticalScrollbarVisibility(isVerticalScrollbarVisible);

        isHorizontalScrollbarVisible = col !== null && col !== undefined && (
          col > 0 ||
          colCount > COLS_PER_PAGE ||
          ($table.width() > $tableContainer.width())
        );
        setHorizontalScrollbarVisibility(isHorizontalScrollbarVisible);

        let visibleColCount = 0;
        $(`.jsonstat-table__${uuid} tbody tr:not(.rs):first td`).each((idx, el) => {
          if (isElementInContainer(el, $tableContainer[0], isVerticalScrollbarVisible, isHorizontalScrollbarVisible)) {
            visibleColCount++;
          }
        });
        if (updateVisibleRowAndColCount) {
          setVisibleColCount(visibleColCount);
        }

        let visibleRowCount = 0;
        $(`.jsonstat-table__${uuid} tbody tr:not(.rs)`).each((idx, el) => {
          if (isElementInContainer($(el).children("td")[0], $tableContainer[0], isVerticalScrollbarVisible, isHorizontalScrollbarVisible)) {
            visibleRowCount++;
          }
        });
        $(`.jsonstat-table__${uuid} tbody tr.rs`).each((idx, el) => {
          if (isElementInContainer(el, $tableContainer[0], isVerticalScrollbarVisible, isHorizontalScrollbarVisible, true)) {
            visibleRowCount++;
          }
        });
        if (updateVisibleRowAndColCount) {
          setVisibleRowCount(visibleRowCount);
        }
      }
    },
    [uuid, tableSupportStructures, row, col]
  );

  useEffect(() => {
    if (jsonStat && layout) {
      setTableSupportStructures(null);
      setHtmlTable("");

      if (!hideSpinner && !isPreview) {
        addSpinner(spinnerUuid, t("components.table.spinners.rendering"));
      }

      const newLayout = Object.assign({}, layout);
      if (jsonStat.id.includes(VARIATION_DIMENSION_KEY)) {
        newLayout.cols = [...layout.cols, VARIATION_DIMENSION_KEY];
      }

      if (!isPreview) {
        worker.onmessage = event => {
          setTableSupportStructures({
            jsonStat,
            ...event.data
          });
          if (event.data.obsAttributeMap === null) {
            console.error(t("components.table.error.observationAttribute"));
          }
        };
        worker.onerror = () => {
          setTableSupportStructures(null);
          removeSpinner(spinnerUuid, true);
        };
        worker.postMessage({
          jsonStat,
          layout: newLayout,
          isPreview,
          removeEmptyLines,
          hiddenAttributes,
          showTrend,
          showCyclical
        });

      } else {
        setTableSupportStructures({
          jsonStat,
          layout: newLayout,
          isPreview: true
        });
      }

      setRow(0);
      setCol(0);
      isFirstRender = true;
    }
  }, [worker, jsonStat, layout, isPreview, removeEmptyLines, hiddenAttributes, showTrend, showCyclical, hideSpinner, addSpinner, removeSpinner, spinnerUuid, t]);

  useEffect(() => {
    if (tableSupportStructures) {
      const func = () => {
        handleStyle(false, true);
        handleScrollbar(true);
      }
      window.addEventListener("resize", func);
      return () => window.removeEventListener("resize", func);
    }
  }, [tableSupportStructures, handleStyle, handleScrollbar]);

  useEffect(() => {
    if (tableSupportStructures) {
      const {
        colCount,
        rowCount
      } = tableSupportStructures;

      const rowEnd = (row + ROWS_PER_PAGE) >= rowCount ? rowCount : (row + ROWS_PER_PAGE);
      const colEnd = (col + COLS_PER_PAGE) >= colCount ? colCount : (col + COLS_PER_PAGE);

      const paginationParams = isPreview
        ? null
        : {
          rowStart: row,
          rowEnd: rowEnd,
          colStart: col,
          colEnd: colEnd
        };

      setHtmlTable(getTableHtml(
        uuid,
        labelFormat,
        fontSize,
        decimalSeparator,
        decimalPlaces,
        sanitize(emptyChar),
        paginationParams,
        tableSupportStructures,
        onTimeSet,
        t
      ));

      $(`.jsonstat-table__${uuid}`).scrollTop(0).scrollLeft(0);
    }
  }, [tableSupportStructures, uuid, labelFormat, fontSize, decimalSeparator, decimalPlaces, emptyChar, isPreview, row, col, isFullscreen, onTimeSet, t]);

  useEffect(() => {
    if (htmlTable && htmlTable.length > 0) {
      handleStyle(isFirstRender, false);
      handleScrollbar(isFirstRender);

      isFirstRender = false;
    }
  }, [htmlTable, handleStyle, handleScrollbar]);

  return (
    <Fragment>
      {tableSupportStructures
        ? (tableSupportStructures.colCount + tableSupportStructures.rowCount === 0)
          ? (
            <CustomEmpty text={t("components.table.emptyTable")}/>
          )
          : (
            <div className={classes.root} aria-hidden={true}>
              {!isPreview
                ? (
                  <Scrollbars
                    verticalValue={row}
                    verticalMaxValue={tableSupportStructures.rowCount}
                    verticalTicks={tableSupportStructures.rowCount - (visibleRowCount - Math.floor(visibleRowCount / 100 * SLIDER_SAFETY_MARGIN_PERCENTAGE))}
                    onVerticalScroll={setRow}
                    isVerticalScrollbarVisible={isVerticalScrollbarVisible}
                    horizontalValue={col}
                    horizontalMaxValue={tableSupportStructures.colCount}
                    horizontalTicks={tableSupportStructures.colCount - (visibleColCount - Math.floor(visibleColCount / 100 * SLIDER_SAFETY_MARGIN_PERCENTAGE))}
                    onHorizontalScroll={setCol}
                    isHorizontalScrollbarVisible={isHorizontalScrollbarVisible}
                    disableWheelZoom={disableWheelZoom}
                  >
                    <div
                      className={`${classes.table} jsonstat-table jsonstat-table__${uuid}`}
                      style={{overflow: "hidden"}}
                      dangerouslySetInnerHTML={{__html: htmlTable}}
                    />
                  </Scrollbars>
                )
                : (
                  <div
                    className={`${classes.table} jsonstat-table jsonstat-table__${uuid}`}
                    style={{overflow: "auto"}}
                    dangerouslySetInnerHTML={{__html: htmlTable}}
                  />
                )
              }
            </div>
          )
        : (
          <CustomEmpty text={t("components.table.initializing") + "..."}/>
        )
      }
      <Snackbar
        open={isCopiedVisible}
        autoHideDuration={2000}
        onClose={() => setCopiedVisibility(prevVal => !prevVal)}
      >
        <Alert severity="success" onClose={() => setCopiedVisibility(prevVal => !prevVal)}>
          {t("components.table.alerts.attributeCopied")}
        </Alert>
      </Snackbar>
      <Dialog
        open={attributes !== null}
        onClose={() => setAttributes(null)}
      >
        <CustomDialogTitle onClose={() => setAttributes(null)}>
          {t("components.table.dialogs.attributes.title")}
        </CustomDialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            {(attributes || []).map((attribute, idx) =>
              <Grid item key={idx} xs={12}>
                <b>{getAttributeLabel(attribute, labelFormat)}</b>: {getAttributeValueLabel(attribute, labelFormat)}
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setAttributes(null)}>
            {t("commons.confirm.close")}
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  )
}

export default compose(
  connect(null, mapDispatchToProps),
  withStyles(styles)
)(Table);