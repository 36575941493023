export const FETCH_DASHBOARD_DATASET_ASYNC_HANDLER_INIT = "fetch/dashboard/dataset/async/handler/init"
export const FETCH_DASHBOARD_DATASET_ASYNC_HANDLER_SUCCESS = "fetch/dashboard/dataset/async/handler/success"
export const FETCH_DASHBOARD_DATASET_ASYNC_HANDLER_ERROR = "fetch/dashboard/dataset/async/handler/error"
export const FETCH_DASHBOARD_DATASET_ASYNC_HANDLER_RESET = "fetch/dashboard/dataset/async/handler/reset"

export const initFetchDashboardDatasetAsyncHandler = (dashboardId, isFetchingDynamicView, requestUuid) => ({
  type: FETCH_DASHBOARD_DATASET_ASYNC_HANDLER_INIT,
  payload: {
    dashboardId: dashboardId,
    isFetchingDynamicView: isFetchingDynamicView,
    requestUuid: requestUuid
  }
});

export const successFetchDashboardDatasetAsyncHandler = (dashboardId, isFetchingDynamicView, dashboardJsonStats, dashboardLayouts, dashboardFilterTrees, dashboardTimePeriodsByFreq, dashboardMaps) => ({
  type: FETCH_DASHBOARD_DATASET_ASYNC_HANDLER_SUCCESS,
  payload: {
    dashboardId: dashboardId,
    isFetchingDynamicView: isFetchingDynamicView,
    dashboardJsonStats: dashboardJsonStats,
    dashboardLayouts: dashboardLayouts,
    dashboardFilterTrees: dashboardFilterTrees,
    dashboardTimePeriodsByFreq: dashboardTimePeriodsByFreq,
    dashboardMaps: dashboardMaps,
  }
});

export const errorFetchDashboardDatasetAsyncHandler = (dashboardId, requestIds) => ({
  type: FETCH_DASHBOARD_DATASET_ASYNC_HANDLER_ERROR,
  payload: {
    dashboardId: dashboardId,
    requestIds: requestIds
  }
});

export const resetFetchDashboardDatasetAsyncHandler = (dashboardId, requestIds) => ({
  type: FETCH_DASHBOARD_DATASET_ASYNC_HANDLER_RESET,
  payload: {
    dashboardId: dashboardId,
    requestIds: requestIds
  }
});