import React, {Component} from 'react';
import Data from "../components/data";
import {connect} from "react-redux";
import {enableDatasetStructureFetch, fetchDatasetStructure, resetDatasetState} from "../state/dataset/datasetActions";
import Call from "../hocs/call";

const mapStateToProps = ({app, dataset}) => ({
  defaultLanguage: app.language,
  languages: app.languages,
  dsDatasetId: dataset.datasetId,
  dsNodeCode: dataset.nodeCode,
  dsCategoryPath: dataset.categoryPath,
  dsViewId: dataset.viewId,
  isFetchStructureDisabled: dataset.isFetchStructureDisabled
});

const mapDispatchToProps = dispatch => ({
  onFetchStructureEnable: (nodeCode, categoryPath, datasetId, viewId) =>
    dispatch(enableDatasetStructureFetch(nodeCode, categoryPath, datasetId, viewId)),
  fetchStructure: ({nodeId, datasetId, viewId, defaultLanguage, languages}) =>
    dispatch(fetchDatasetStructure(nodeId, datasetId, viewId, defaultLanguage, languages)),
  onDatasetReset: () => dispatch(resetDatasetState())
});

class DatasetDomain extends Component {

  componentDidMount() {
    const {
      nodeCode,
      categoryPath,
      datasetId,
      viewId,
      onFetchStructureEnable
    } = this.props;

    onFetchStructureEnable(nodeCode, categoryPath, datasetId, viewId);
  };

  componentDidUpdate() {
    const {
      dsDatasetId,
      dsNodeCode,
      dsCategoryPath,
      dsViewId,
      nodeCode,
      categoryPath,
      datasetId,
      viewId,
      onFetchStructureEnable
    } = this.props;

    const isStateAndUrlSynced = (
      nodeCode === dsNodeCode &&
      (categoryPath || []).join() === (dsCategoryPath || []).join() &&
      datasetId === dsDatasetId &&
      (viewId || "") === (dsViewId || "")
    );

    if (!isStateAndUrlSynced) {
      onFetchStructureEnable(nodeCode, categoryPath, datasetId, viewId);
    }
  };

  componentWillUnmount() {
    const {
      onDatasetReset
    } = this.props;

    onDatasetReset();
  };

  render() {

    const {
      defaultLanguage,
      languages,
      isFetchStructureDisabled,
      hub,
      node,
      isDefault,
      catalog,
      categoryPath,
      isAccessible,
      datasetId,
      datasetTitle,
      notes,
      attachedFiles,
      referenceMetadataUrl,
      viewId,
      fetchStructure
    } = this.props;

    return (
      <Call
        cb={fetchStructure}
        cbParam={{nodeId: node ? node.nodeId : null, datasetId, viewId, defaultLanguage, languages}}
        disabled={isFetchStructureDisabled}
      >
        <Data
          hub={hub}
          node={node}
          isDefault={isDefault}
          catalog={catalog}
          categoryPath={categoryPath}
          isAccessible={isAccessible}
          datasetId={datasetId}
          datasetTitle={datasetTitle}
          viewId={viewId}
          notes={notes}
          attachedFiles={attachedFiles}
          referenceMetadataUrl={referenceMetadataUrl}
        />
      </Call>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DatasetDomain);