import React, {Component, Fragment} from 'react';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import {withStyles} from "@material-ui/core";
import {getDatasetsInternalUrl} from "../../links";
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import ListIcon from '@material-ui/icons/List';
import FolderIcon from '@material-ui/icons/Folder';
import {withTranslation} from "react-i18next";
import {compose} from "redux";
import CatalogInfoButton from "../catalog-info-button";
import CatalogMetadataButton from "../catalog-metadata-button";
import CustomLink from "../custom-link";

const styles = () => ({
  node: {
    display: "flex",
    alignItems: "center",
    padding: 8
  },
  folderIcon: {
    marginRight: 8
  },
  nodeLabel: {},
  nodeIcon: {
    marginLeft: 8,
    "& button": {
      padding: 4
    }
  },
  nodeRoot: {
    "&:focus > .MuiTreeItem-content .MuiTreeItem-label": {
      outline: "-webkit-focus-ring-color auto 1px",
      outlineOffset: -1
    }
  },
  labelSelected: {
    background: "rgba(0, 41, 90, 0.2) !important"
  }
});

const getNodeId = (catId, prevPath) => (prevPath || []).length > 0
  ? prevPath.join("+") + "+" + catId
  : catId;

const getSelectedNodeIds = categoryPath => {
  const ret = [];

  categoryPath.forEach((category, idx) => {
    ret.push(getNodeId(category, categoryPath.slice(0, idx)));
  });

  return ret
}

class CategoriesTree extends Component {

  constructor(props) {
    super(props);
    this.state = {
      expanded: getSelectedNodeIds(props.selectedCategoryPath || []),
      selected: getSelectedNodeIds(props.selectedCategoryPath || [])
    };
    this.onExpand = this.onExpand.bind(this);
  }

  onExpand = expanded => this.setState({expanded});

  render() {

    const {
      classes,
      node,
      catalog,
      onClose,
      t
    } = this.props;

    const {
      expanded,
      selected
    } = this.state;

    const getTreeItems = (tree, prevPath) =>
      tree.map((cat, i) => {
        return (
          <TreeItem
            key={i}
            nodeId={getNodeId(cat.id, prevPath)}
            label={
              <div className={classes.node}>
                {(expanded.includes(getNodeId(cat.id, prevPath)))
                  ? <FolderOpenIcon className={classes.folderIcon}/>
                  : <FolderIcon className={classes.folderIcon}/>
                }
                <span className={classes.nodeLabel}>
                  {cat.label}
                </span>
                {cat.description && (
                  <div className={classes.nodeIcon}>
                    <CatalogInfoButton
                      info={cat.description}
                      label={t("components.categoriesTree.actions.description.title")}
                      iconSize="small"
                    />
                  </div>
                )}
                {cat.metadataUrl && (
                  <div className={classes.nodeIcon}>
                    <CatalogMetadataButton
                      metadataUrl={cat.metadataUrl}
                      iconSize="small"
                    />
                  </div>
                )}
              </div>
            }
            /* TODO: inefficente */
            classes={{
              root: classes.nodeRoot,
              label: selected.includes(getNodeId(cat.id, prevPath)) ? classes.labelSelected : null
            }}
            tabIndex={0}
          >
            {((cat.datasetIdentifiers && cat.datasetIdentifiers.length > 0) || (cat.childrenCategories && cat.childrenCategories.length > 0)) && (
              <Fragment>
                {(cat.datasetIdentifiers && cat.datasetIdentifiers.length > 0) && (
                  <TreeItem
                    nodeId={getNodeId(cat.id, prevPath) + "-results"}
                    label={
                      <CustomLink
                        to={getDatasetsInternalUrl(node.code.toLowerCase(), [...prevPath, cat.id])}
                        text={<i>{t("components.categoriesTree.goToData", {datasetsCount: cat.datasetIdentifiers.length})}</i>}
                        icon={<ListIcon/>}
                        onClick={onClose}
                      />
                    }
                  />
                )}
                {cat.childrenCategories && cat.childrenCategories.length > 0 && (
                  getTreeItems(cat.childrenCategories, [...prevPath, cat.id])
                )}
              </Fragment>
            )}
          </TreeItem>
        )
      });

    const getCategorySchemeTree = ({id, label, categories}) =>
      <TreeView
        defaultCollapseIcon={<ExpandMoreIcon/>}
        defaultExpandIcon={<ChevronRightIcon/>}
        expanded={expanded}
        onNodeToggle={(_, nodeIds) => this.onExpand(nodeIds)}
        key={id}
      >
        {getTreeItems([{id, label, childrenCategories: categories}], [])}
      </TreeView>;

    return (
      <Fragment>
        {catalog.categoryGroups.length > 0 && (
          catalog.categoryGroups.length > 1
            ? (
              <Fragment>
                {catalog.categoryGroups.map(getCategorySchemeTree)}
              </Fragment>
            )
            : (
              <TreeView
                defaultCollapseIcon={<ExpandMoreIcon/>}
                defaultExpandIcon={<ChevronRightIcon/>}
                expanded={expanded}
                onNodeToggle={(_, nodeIds) => this.onExpand(nodeIds)}
              >
                {getTreeItems(catalog.categoryGroups[0].categories, [])}
              </TreeView>
            )
        )}
        {catalog.uncategorizedDatasets && catalog.uncategorizedDatasets.length > 0 && (
          <TreeView
            defaultCollapseIcon={<ExpandMoreIcon/>}
            defaultExpandIcon={<ChevronRightIcon/>}
            expanded={expanded}
            onNodeToggle={(_, nodeIds) => this.onExpand(nodeIds)}
          >
            <TreeItem
              key={"uncategorized"}
              nodeId={"uncategorized"}
              label={
                <div className={classes.node}>
                  {expanded.includes("uncategorized")
                    ? <FolderOpenIcon className={classes.folderIcon}/>
                    : <FolderIcon className={classes.folderIcon}/>
                  }
                  <span className={classes.nodeLabel}>
                    {t("commons.catalog.uncategorized")}
                  </span>
                </div>
              }
              /* TODO: inefficente */
              classes={{
                root: classes.nodeRoot,
                label: selected.includes("uncategorized") ? classes.labelSelected : null
              }}
            >
              <TreeItem
                nodeId={"uncategorized-results"}
                label={
                  <CustomLink
                    to={getDatasetsInternalUrl(node.code.toLowerCase(), ["uncategorized"])}
                    text={<i>{t("components.categoriesTree.goToData", {datasetsCount: catalog.uncategorizedDatasets.length})}</i>}
                    icon={<ListIcon/>}
                    onClick={onClose}
                  />
                }
              />
            </TreeItem>
          </TreeView>
        )}
      </Fragment>
    );
  }
}

export default compose(
  withStyles(styles),
  withTranslation()
)(CategoriesTree);