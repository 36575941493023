import React from 'react';
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";
import {DatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import enLocale from "date-fns/locale/en-US";
import itLocale from "date-fns/locale/it";
import frLocale from "date-fns/locale/fr";
import esLocale from "date-fns/locale/es";
import ptLocale from "date-fns/locale/pt";

const getLocale = lang => {
  const map = {
    it: itLocale,
    en: enLocale,
    fr: frLocale,
    es: esLocale,
    pt: ptLocale
  }

  return map[lang] || enLocale;
};

const getFormat = lang => {
  const map = {
    it: "dd-MM-yyyy",
    en: "yyyy-MM-dd"
  };

  return map[lang] || "yyyy-MM-dd";
}

const CustomDatePicker = ({
                            appLang,
                            
                            label,
                            value,
                            minDate,
                            maxDate,
                            onChange,

                            openTo = "year",
                            views = ["year", "month", "date"],
                            format = getFormat(appLang),
                            inputVariant = undefined,
                            className = undefined
                          }) => {

  const {t} = useTranslation();

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={getLocale(appLang)}>
      <DatePicker
        label={label}
        value={value}
        minDate={minDate}
        maxDate={maxDate}
        onChange={onChange}

        openTo={openTo}
        views={views}
        format={format}
        inputVariant={inputVariant}
        className={className}
        
        clearable

        cancelLabel={t("components.customDatePicker.cancelLabel")}
        clearLabel={t("components.customDatePicker.clearLabel")}
        okLabel={t("components.customDatePicker.okLabel")}
        todayLabel={t("components.customDatePicker.todayLabel")}
      />
    </MuiPickersUtilsProvider>
  );
};

export default connect(state => ({
  appLang: state.app.language
}))(CustomDatePicker);