import React from 'react'
import {compose} from "redux";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {withStyles} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {getTrimmedStr} from "../../utils/formatters";

const styles = theme => ({
  root: {},
  logoContainer: {
    height: 64,
    background: theme.palette.primary.main,
    padding: 16
  },
  logo: {
    height: 32,
    display: "block",
    margin: "auto"
  },
  nameContainer: {
    padding: "24px 16px"
  },
  textContainer: {
    padding: 8
  },
  versionContainer: {
    padding: 8
  },
});

const mapStateToProps = state => ({
  baseURL: state.config.baseURL
});

const AppInfo = ({
                   t,
                   classes,
                   baseURL,
                   hub
                 }) =>
  <Grid container className={classes.root}>
    {hub.headerLogoURL && (
      <Grid item xs={12} className={classes.logoContainer}>
        <img src={baseURL + hub.headerLogoURL} alt="" className={classes.logo}/>
      </Grid>
    )}
    <Grid item xs={12} className={classes.nameContainer}>
      <Typography variant="h4" color="primary" align="center">
        {hub.name}
      </Typography>
    </Grid>
    {getTrimmedStr(t("components.appInfo.content")).length > 0 && (
      <Grid item xs={12} className={classes.textContainer}>
        {t("components.appInfo.content")}
      </Grid>
    )}
    <Grid item xs={12} className={classes.versionContainer}>
      {t("components.appInfo.poweredBy") + ": "}<b>Istat Data Browser <i>v1.3.5.4</i></b>
    </Grid>
  </Grid>;

export default compose(
  withStyles(styles),
  withTranslation(),
  connect(mapStateToProps)
)(AppInfo);