import React, {useEffect, useState} from "react";
import {compose} from "redux";
import {connect} from "react-redux";
import TreeItem from "@material-ui/lab/TreeItem";
import Checkbox from "@material-ui/core/Checkbox";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import TreeView from "@material-ui/lab/TreeView";
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import LibraryAddCheckIcon from "@material-ui/icons/LibraryAddCheck";
import FilterNoneIcon from "@material-ui/icons/FilterNone";
import HeightIcon from "@material-ui/icons/Height";
import VerticalAlignCenterIcon from "@material-ui/icons/VerticalAlignCenter";
import AutoSearchInput from "../auto-search-input";
import Divider from "@material-ui/core/Divider";
import CustomEmpty from "../custom-empty";
import {getFilteredTreeWithPaths, getMaxTreeDepth, getNodesAtDepth} from "../../utils/tree";
import _ from "lodash"
import {v4 as uuidv4} from "uuid";
import {useTranslation} from "react-i18next";
import {localizeI18nObj} from "../../utils/i18n";
import CustomLink from "../custom-link";

const $ = window.jQuery;

const DEFAULT_TREE_PAGE_SIZE = 30;

const TREE_NODE_KEY_OF_LEVEL_PREFIX = "TREE_NODE_KEY_OF_LEVEL_PREFIX_";

const styles = theme => ({
  root: {
    height: "100%",
    width: "100%"
  },
  treeActions: {
    marginBottom: 4,
    padding: 4
  },
  treeLevelSelector: {
    marginBottom: 4,
    padding: 4
  },
  tree: {
    overflow: "auto",
    "& .MuiTreeItem-label": {
      width: "calc(100% - 19px)"
    },
    padding: 4
  },
  treeNode: {
    padding: "2px 0",
    minHeight: 32
  },
  treeNodeCheckbox: {
    display: "inline-block",
    verticalAlign: "middle",
    padding: 4,
    marginRight: 4
  },
  treeNodeLabel: {
    display: "inline-block",
    verticalAlign: "middle",
    fontSize: 14,
    color: "rgba(0, 0, 0, 0.65)",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
  levelTreeNodeLabel: {
    color: "rgba(0, 0, 0, 0.85)",
    fontWeight: 500
  },
  treeNodeAction: {
    display: "inline-block",
    verticalAlign: "middle",
    marginLeft: 4,
    "& > button": {
      padding: 4
    }
  },
  divider: {
    margin: "4px 0"
  },
  showMoreNode: {
    marginLeft: 10,
    "& i": {
      fontSize: 14
    }
  },
  treeAction: {
    padding: 8
  }
});

const getKeys = (tree, childrenKey, getNodeKey, test) => {

  const res = [];

  const recursive = subTree => subTree
    ? (
      subTree.map(node => {
        if (node[childrenKey] && node[childrenKey].length) {
          recursive(node[childrenKey]);
        }
        if (!test || test(node)) {
          res.push(getNodeKey(node));
        }
        return null;
      })
    )
    : [];

  recursive(tree);

  return res;
};

const getFilteredTree = (tree, childrenKey, labelKey, getNodeLabel, searchText, defaultLanguage, languages, isLabelNotMultilingual) => {

  const filterNode = node => {
    const label = getNodeLabel
      ? getNodeLabel(node)
      : isLabelNotMultilingual
        ? node[labelKey]
        : localizeI18nObj(node[labelKey], defaultLanguage, languages);

    return label.toLowerCase().indexOf(searchText.toLowerCase()) >= 0;
  };

  const recursive = subtree => subtree
    ? ([
      ...subtree
        .map(node => ({...node}))
        .filter(node => {
          if (node[childrenKey] && node[childrenKey].length) {
            if (filterNode(node)) {
              return true;
            } else {
              const filteredChildren = [...recursive(node[childrenKey])];
              if (filteredChildren.length) {
                node[childrenKey] = filteredChildren;
                return true;
              } else {
                return false;
              }
            }
          } else {
            return filterNode(node);
          }
        })
    ])
    : null;

  return recursive(tree);
};

const isSearchedNode = (node, labelKey, searchText, isLabelNotMultilingual, defaultLanguage, languages) => {
  const searchedStr = searchText.toLowerCase();
  const nodeLabel = isLabelNotMultilingual
    ? (node[labelKey] || "").toLowerCase()
    : (localizeI18nObj(node[labelKey], defaultLanguage, languages) || "").toLowerCase();

  return nodeLabel.includes(searchedStr)
};

const handleHeight = (uuid, lsExpandedKeys, withLevelSelector) => {
  const actionHeight = $(`#enhanced-tree__${uuid} .enhanced-tree__actions`).outerHeight(true) || 0;

  let levelSelectorHeight = 0;
  if (withLevelSelector) {
    const nodeHeight = $(`#enhanced-tree__${uuid} .enhanced-tree__level-selector .enhanced-tree__level-selector__node:first`).outerHeight(true) || 0;
    levelSelectorHeight += nodeHeight;
    levelSelectorHeight += 12; // paddings + margins
    levelSelectorHeight += 9; // divider

    let found = false;
    for (let i = 0; i < lsExpandedKeys.length; i++) {
      if (lsExpandedKeys.find(key => key === TREE_NODE_KEY_OF_LEVEL_PREFIX + i) && !found) {
        levelSelectorHeight += nodeHeight;
      } else {
        found = true;
      }
    }
  }

  $(`#enhanced-tree__${uuid} .enhanced-tree__tree`).height(`calc(100% - ${actionHeight + levelSelectorHeight}px)`);
};

function EnhancedTree(props) {

  const {
    languages,
    defaultLanguage,
    hubExtras,
    classes,
    tree,
    getNodeKey,
    childrenKey,
    labelKey,
    getNodeLabel,
    checkable,
    isCheckDisabled,
    defaultExpandedKeys,
    nodeActions,
    withoutSearch,
    withoutCheckControls,
    withoutExpandControls,
    withLevelSelector,
    withoutPagination,
    getIsCheckable,
    isLabelNotMultilingual,
    checkboxCheckedIcon,
    checkboxUncheckedIcon,
    showDisabledCheckbox = false,
    treeActionsDirection
  } = props;

  const parsedHubExtras = JSON.parse(hubExtras || "{}");
  const treePageSize = (parsedHubExtras?.TreePageSize || "").length > 0
    ? Number(parsedHubExtras.TreePageSize)
    : DEFAULT_TREE_PAGE_SIZE;

  const {t} = useTranslation();

  const [uuid] = useState(uuidv4());

  const [filteredTree, setFilteredTree] = useState(null);

  const [searchText, setSearchText] = useState("");

  const [checkedKeys, setCheckedKeys] = useState(props.checkedKeys || []);
  const [expandedKeys, setExpandedKeys] = useState(null);
  const [showMoreKeyClicks, setShowMoreKeyClicks] = useState({});

  const [lsExpandedKeys, setLsExpandedKeys] = useState([]);

  const handleCheck = (checkedNode, checked) => {

    const newCheckedKeys = [...(props.checkedKeys || checkedKeys)];
    checked
      ? newCheckedKeys.push(getNodeKey(checkedNode))
      : newCheckedKeys.splice((props.checkedKeys || checkedKeys).indexOf(getNodeKey(checkedNode)), 1);

    setCheckedKeys(newCheckedKeys);
    if (props.onCheck) {
      props.onCheck(newCheckedKeys, checkedNode, checked);
    }
  };

  const handleLevelCheck = (checkedNode, checked) => {

    const nodesAtDepth = getNodesAtDepth(filteredTree, childrenKey, checkedNode.level + 1)
      .filter(node => getIsCheckable ? getIsCheckable(node) : true)
      .map(node => getNodeKey(node));

    let newCheckedKeys = [...(props.checkedKeys || checkedKeys)];
    newCheckedKeys = checked
      ? _.uniq(newCheckedKeys.concat(nodesAtDepth))
      : newCheckedKeys.filter(key => !nodesAtDepth.includes(key));

    setCheckedKeys(newCheckedKeys)
    if (props.onCheck) {
      props.onCheck(newCheckedKeys);
    }
  };

  const handleExpand = expandedKeys => {
    setExpandedKeys(expandedKeys);
    if (props.onExpand) {
      props.onExpand(expandedKeys);
    }
  };

  const handleLsExpand = expandedKeys => {
    setLsExpandedKeys(expandedKeys);
  };

  const handleSearch = searchText => {
    setSearchText(searchText);
  };

  useEffect(() => {
    handleHeight(uuid, lsExpandedKeys, withLevelSelector);
  });

  useEffect(() => {
    if (filteredTree && expandedKeys === null) {
      if (defaultExpandedKeys !== null && defaultExpandedKeys !== undefined) {
        const filtTree = getFilteredTreeWithPaths(filteredTree, childrenKey, node => defaultExpandedKeys.includes(getNodeKey(node)));
        const filtTreeKeys = getKeys(filtTree, childrenKey, getNodeKey);
        setExpandedKeys(filtTreeKeys);
      } else {
        setExpandedKeys([]);
      }
      setLsExpandedKeys([]);
    }
  }, [filteredTree, getNodeKey, childrenKey, expandedKeys, defaultExpandedKeys]);

  useEffect(() => {
    if (tree && tree.length > 0) {
      const newFilteredTree = getFilteredTree(tree, childrenKey, labelKey, getNodeLabel, searchText, defaultLanguage, languages, isLabelNotMultilingual);
      setFilteredTree(newFilteredTree);

      if (searchText && searchText.length > 0) {
        const filtTree = getFilteredTreeWithPaths(newFilteredTree, childrenKey, node => isSearchedNode(node, labelKey, searchText, isLabelNotMultilingual, defaultLanguage, languages));
        const filtTreeKeys = getKeys(filtTree, childrenKey, getNodeKey);
        setExpandedKeys(filtTreeKeys);
        setLsExpandedKeys([]);
      }
    }
  }, [tree, getNodeKey, childrenKey, labelKey, getNodeLabel, searchText, defaultLanguage, languages, isLabelNotMultilingual]);

  const getShowMoreNode = parent => {

    const key = `${parent ? getNodeKey(parent) : 'root'}`;

    const remaining = (parent ? parent[childrenKey] : (filteredTree || []).filter(node => node != null)).length - ((showMoreKeyClicks[key] || 0) + 1) * treePageSize;

    return (
      <TreeItem
        key={`${key}_showMore`}
        nodeId={`${key}_showMore`}
        className={classes.showMoreNode}
        tabIndex={-1}
        label={
          <CustomLink
            to={"#"}
            text={<i>{t("components.enhancedTree.showMore", {more: Math.min(remaining, treePageSize), remaining: remaining})}</i>}
            onClick={() => setShowMoreKeyClicks({
              ...showMoreKeyClicks,
              [key]: showMoreKeyClicks[key]
                ? showMoreKeyClicks[key] + 1
                : 1
            })}
          />
        }
      />
    );
  };

  const getTreeNode = (node, getNodeKey, isLevelTree, checkable, checkedKeys, onCheck, getIsCheckable) => {

    const actions = (nodeActions || [])
      .filter(act => act)
      .map(act => typeof (act) === "function" ? act(node) : act)
      .filter(act => act);

    const label = (getNodeLabel && !isLevelTree)
      ? getNodeLabel(node)
      : isLabelNotMultilingual
        ? node[labelKey]
        : localizeI18nObj(node[labelKey], defaultLanguage, languages);

    const isNodeCheckable = !isCheckDisabled && (getIsCheckable ? getIsCheckable(node) : true);

    const checked = !isLevelTree
      ? checkedKeys.includes(getNodeKey(node))
      : (
        !getNodesAtDepth(filteredTree, childrenKey, node.level + 1)
          .filter(node => getIsCheckable ? getIsCheckable(node) : true)
          .map(node => getNodeKey(node))
          .some(val => checkedKeys.indexOf(val) === -1)
      );

    return (
      <TreeItem
        key={getNodeKey(node)}
        nodeId={getNodeKey(node)}
        tabIndex={0}
        label={
          <div className={`${classes.treeNode} ${isLevelTree ? "enhanced-tree__level-selector__node" : ""}`}>
            {checkable && (showDisabledCheckbox || isNodeCheckable) && (
              <Tooltip
                title={checked
                  ? t("components.enhancedTree.checkBox.uncheck.tooltip", {label: label})
                  : t("components.enhancedTree.checkBox.check.tooltip", {label: label})
                }
              >
                <Checkbox
                  className={classes.treeNodeCheckbox}
                  icon={checkboxUncheckedIcon ? checkboxUncheckedIcon : undefined}
                  checkedIcon={checkboxCheckedIcon ? checkboxCheckedIcon : undefined}
                  checked={checked}
                  onClick={event => {
                    onCheck(node, event.target.checked);
                    event.stopPropagation();
                  }}
                  disabled={!isNodeCheckable}
                  inputProps={{
                    "aria-label": checked
                      ? t("components.enhancedTree.checkBox.uncheck.ariaLabel", {label: label})
                      : t("components.enhancedTree.checkBox.check.ariaLabel", {label: label})
                  }}
                />
              </Tooltip>
            )}
            <Tooltip title={label}>
              <div
                className={`${classes.treeNodeLabel} ${isLevelTree ? classes.levelTreeNodeLabel : ""}`}
                style={{maxWidth: `calc(100% - ${(checkable ? 40 : 0) + ((actions && actions.length > 0) ? 4 + (32 * actions.length) : 0)}px)`}}
              >
                {label}
              </div>
            </Tooltip>
            {!isLevelTree && (
              <div className={classes.treeNodeAction}>
                {actions
                  .filter(action => (action !== null && action !== undefined))
                  .map((action, idx) =>
                    <Tooltip key={idx} title={action.title}>
                      <IconButton
                        onClick={event => {
                          action.onClick(node);
                          event.stopPropagation();
                        }}
                        aria-label={action.title}
                      >
                        {action.icon}
                      </IconButton>
                    </Tooltip>
                  )}
              </div>
            )}
          </div>
        }
        onLabelClick={evt => {
          if (checkable && isNodeCheckable) {
            onCheck(node, !checkedKeys.includes(getNodeKey(node)));
            evt.preventDefault()
          }
        }}
      >
        {node[childrenKey]
          ? node[childrenKey]
            .filter((child, index) => withoutPagination || index < treePageSize * ((showMoreKeyClicks[getNodeKey(node)] || 0) + 1))
            .map(child => getTreeNode(child, getNodeKey, isLevelTree, checkable, checkedKeys, onCheck, getIsCheckable))
            .concat(!withoutPagination && node[childrenKey].length > treePageSize * ((showMoreKeyClicks[getNodeKey(node)] || 0) + 1)
              ? [getShowMoreNode(node)]
              : []
            )
          : null
        }
      </TreeItem>
    )
  };

  let levelTree = [];
  if (withLevelSelector) {
    const maxTreeDepth = getMaxTreeDepth(filteredTree, childrenKey);
    levelTree = [{
      id: TREE_NODE_KEY_OF_LEVEL_PREFIX + 0,
      [labelKey]: isLabelNotMultilingual
        ? t("components.enhancedTree.levelSelector", {level: 0})
        : {
          [defaultLanguage]: t("components.enhancedTree.levelSelector", {level: 0})
        },
      level: 0
    }];
    if (maxTreeDepth !== null && maxTreeDepth !== 0) {
      [...Array(maxTreeDepth - 1)].forEach(() => {
        let pointer = levelTree[0];
        let counter = 0;
        while (pointer[childrenKey]) {
          pointer = pointer[childrenKey][0];
          counter++;
        }
        pointer[childrenKey] = [{
          id: TREE_NODE_KEY_OF_LEVEL_PREFIX + Number(counter + 1),
          [labelKey]: isLabelNotMultilingual
            ? t("components.enhancedTree.levelSelector", {level: counter + 1})
            : {
              [defaultLanguage]: t("components.enhancedTree.levelSelector", {level: counter + 1})
            },
          level: counter + 1
        }];
      });
    }
  }

  return (
    <div id={`enhanced-tree__${uuid}`} className={`enhanced-tree ${classes.root}`}>
      <div className={`enhanced-tree__actions ${classes.treeActions}`}>
        <Grid container spacing={2} justify="space-between" direction={treeActionsDirection || "row"}>
          <Grid item xs={6}>
            <Grid container spacing={1} justify="flex-start">
              {!withoutCheckControls && (
                <Grid item>
                  <Grid container>
                    <Grid item>
                      <Tooltip title={t("components.enhancedTree.selectAll.tooltip")}>
                        <div>
                          <IconButton
                            aria-label={t("components.enhancedTree.selectAll.ariaLabel")}
                            onClick={() => {
                              const newCheckedKeys = getKeys(filteredTree, childrenKey, getNodeKey, getIsCheckable);
                              setCheckedKeys(newCheckedKeys)
                              if (props.onCheck) {
                                props.onCheck(newCheckedKeys)
                              }
                            }}
                            className={classes.treeAction}
                            disabled={isCheckDisabled}
                          >
                            <LibraryAddCheckIcon/>
                          </IconButton>
                        </div>
                      </Tooltip>
                    </Grid>
                    <Grid item>
                      <Tooltip title={t("components.enhancedTree.deselectAll.tooltip")}>
                        <div>
                          <IconButton
                            aria-label={t("components.enhancedTree.deselectAll.ariaLabel")}
                            onClick={() => {
                              setCheckedKeys([])
                              if (props.onCheck) {
                                props.onCheck([])
                              }
                            }}
                            className={classes.treeAction}
                            disabled={isCheckDisabled}
                          >
                            <FilterNoneIcon style={{padding: 1}}/>
                          </IconButton>
                        </div>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Grid>
              )}
              {!withoutExpandControls && (
                <Grid item>
                  <Grid container>
                    <Grid item>
                      <Tooltip title={t("components.enhancedTree.expandAll.tooltip")}>
                        <IconButton
                          aria-label={t("components.enhancedTree.expandAll.ariaLabel")}
                          className={classes.treeAction}
                          onClick={() => handleExpand(getKeys(filteredTree, childrenKey, getNodeKey))}
                        >
                          <HeightIcon/>
                        </IconButton>
                      </Tooltip>
                    </Grid>
                    <Grid item>
                      <Tooltip title={t("components.enhancedTree.collapseAll.tooltip")}>
                        <IconButton
                          aria-label={t("components.enhancedTree.collapseAll.ariaLabel")}
                          className={classes.treeAction}
                          onClick={() => handleExpand([])}
                        >
                          <VerticalAlignCenterIcon/>
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item xs={6}>
            {!withoutSearch && (
              <AutoSearchInput
                onSearch={handleSearch}
              />
            )}
          </Grid>
        </Grid>
      </div>
      {withLevelSelector && getKeys(filteredTree, childrenKey, getNodeKey).length > 1 && (
        <div className={`enhanced-tree__level-selector ${classes.treeLevelSelector}`}>
          <TreeView
            defaultCollapseIcon={<ExpandMoreIcon/>}
            defaultExpandIcon={<ChevronRightIcon/>}
            disableSelection
            expanded={lsExpandedKeys}
            onNodeToggle={(ev, nodeIds) => handleLsExpand(nodeIds)}
          >
            {levelTree.map(node => getTreeNode(node, ({id}) => id, true, checkable, (props.checkedKeys || checkedKeys), handleLevelCheck, getIsCheckable))}
          </TreeView>
          <Divider className={classes.divider}/>
        </div>
      )}
      <div className={`enhanced-tree__tree ${classes.tree}`}>
        {(filteredTree && filteredTree.length > 0 && expandedKeys)
          ? (
            <TreeView
              defaultCollapseIcon={<ExpandMoreIcon/>}
              defaultExpandIcon={<ChevronRightIcon/>}
              disableSelection
              expanded={expandedKeys}
              onNodeToggle={(ev, nodeIds) => handleExpand(nodeIds)}
            >
              {filteredTree.map(node => getTreeNode(node, getNodeKey, false, checkable, (props.checkedKeys || checkedKeys), handleCheck, getIsCheckable))}
            </TreeView>
          )
          : <CustomEmpty/>
        }
      </div>
    </div>
  )
}

export default compose(
  withStyles(styles),
  connect(state => ({
    languages: state.app.languages,
    defaultLanguage: state.app.language,
    hubExtras: state.hub.hub?.extras
  }))
)(EnhancedTree);