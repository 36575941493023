import React, {Component, Fragment} from 'react';
import Hero from "../hero";
import CardsGrid from "../cards-grid";
import Container from "@material-ui/core/Container";
import PageSection from "../page-section";
import {withStyles} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import NodeHeader from "../node-header";
import Page from "../page";
import {getNodeInternalUrl, goToNodeCategories, goToNodeDashboards} from "../../links";
import Box from "@material-ui/core/Box";
import {connect} from "react-redux";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import SanitizedHTML from "../sanitized-html";
import {HashLink} from 'react-router-hash-link';
import {withRouter} from "react-router";
import {getPageTitle} from "../../utils/other";
import Footer from "../footer";
import Grid from "@material-ui/core/Grid";

const styles = theme => ({
  main: {
    width: "100%",
    height: "100%"
  },
  fullWidthContainer: {
    backgroundColor: "#f5f5f5",
    width: "100%"
  },
  container: {
    paddingTop: theme.spacing(3)
  },
  anchorContainer: {
    position: "relative"
  },
  anchor: {
    position: "absolute",
    top: -theme.mixins.toolbar.minHeight
  },
  section: {
    paddingTop: theme.spacing(3)
  },
  nodesContainer: {
    marginTop: theme.spacing(2)
  }
});

const mapStateToProps = state => ({
  baseURL: state.config.baseURL,
  isA11y: state.app.isA11y
});

class Node extends Component {

  info = React.createRef();
  nodes = React.createRef();

  scroll(ref) {
    ref.current.scrollIntoView({
      behavior: 'smooth',
      alignToTop: true
    });
  }

  render() {

    const {
      classes,
      defaultTreeOpen,
      defaultNodeConfigOpen,
      defaultAppConfigOpen,
      defaultUserConfigOpen,
      defaultNodesConfigOpen,
      node,
      nodeCode,
      hub,
      catalog,
      baseURL,
      isDefault,
      isA11y,
      t,
      location
    } = this.props;

    const nodeMinimalInfo = hub.nodes.find(({code}) => code.toLowerCase() === nodeCode.toLowerCase());

    const otherNodes = hub.nodes.filter(n => n.code.toLowerCase() !== nodeCode.toLowerCase());

    const params = new URLSearchParams(location.search);
    params.set("accessible", "true");
    const paramsStr = params.toString();
    const path = location.pathname;

    return (
      <Fragment>
        {!isA11y && (
          <a
            href={"./#" + path + (paramsStr.length > 0 ? "?" : "") + paramsStr}
            target="_self"
            className="skip-link sr-only"
          >
            {t("commons.hashLinks.accessible")}
          </a>
        )}
        <HashLink
          to={{hash: "#main", search: location.search}}
          className="skip-link sr-only"
        >
          {t("commons.hashLinks.main")}
        </HashLink>
        {node && node.description && node.description.length > 0 && (
          <HashLink
            to={{hash: "#node-information", search: location.search}}
            className="skip-link sr-only"
          >
            {t("commons.hashLinks.nodeInformation")}
          </HashLink>
        )}
        {isDefault && otherNodes.length > 0 && (
          <HashLink
            to={{hash: "#other-nodes", search: location.search}}
            className="skip-link sr-only"
          >
            {t("commons.hashLinks.otherNodes")}
          </HashLink>
        )}
        <HashLink
          to={{hash: "#footer", search: location.search}}
          className="skip-link sr-only"
        >
          {t("commons.hashLinks.footer")}
        </HashLink>
        <Page id={isDefault ? "landing-page" : null} title={getPageTitle([nodeMinimalInfo?.name, hub?.hub?.name], t)}>
          <NodeHeader
            hub={hub.hub}
            nodes={hub.nodes}
            catalog={catalog}
            defaultTreeOpen={defaultTreeOpen}
            title={nodeMinimalInfo.name}
            node={node}
            defaultNodeConfigOpen={defaultNodeConfigOpen}
            defaultAppConfigOpen={defaultAppConfigOpen}
            defaultUserConfigOpen={defaultUserConfigOpen}
            defaultNodesConfigOpen={defaultNodesConfigOpen}
            nodeId={nodeMinimalInfo.nodeId}
            isDefault={isDefault}
          />
          <main id="main" className={classes.main}>
            <Hero
              title={nodeMinimalInfo.name}
              slogan={node && node.slogan}
              logo={node && nodeMinimalInfo.logoURL && <img src={baseURL + nodeMinimalInfo.logoURL} alt=""/>}
              background={nodeMinimalInfo.backgroundMediaURL
                ? nodeMinimalInfo.backgroundMediaURL.match(/\.(jpeg|jpg|gif|png|JPEG|JPG|GIF|PNG|svg|SVG)$/)
                  ? <div style={{background: `url("${baseURL + nodeMinimalInfo.backgroundMediaURL}") center center / cover no-repeat`}}/>
                  : (
                    <video autoPlay muted loop>
                      <source src={baseURL + nodeMinimalInfo.backgroundMediaURL}/>
                    </video>
                  )
                : <div style={{background: `url("./images/default-node-background.jpg") center center / cover no-repeat`}}/>
              }
            >
              <Grid container justify="center" spacing={2}>
                {node && node.description && node.description.length > 0 && (
                  <Grid item>
                    <Button
                      size="large"
                      color="secondary"
                      variant="contained"
                      onClick={() => {
                        this.scroll(this.info);
                        this.info.current.focus({preventScroll: true});
                      }}
                    >
                      {t("scenes.node.informations")}
                    </Button>
                  </Grid>
                )}
                {catalog && !catalog.isEmpty && (
                  <Grid item>
                    <Button
                      id="explore-btn"
                      size="large"
                      color="secondary"
                      variant="contained"
                      onClick={() => goToNodeCategories(nodeCode)}
                    >
                      {t("scenes.node.explore")}
                    </Button>
                  </Grid>
                )}
                {!isA11y && nodeMinimalInfo.dashboards && nodeMinimalInfo.dashboards.length > 0 && (
                  <Grid item>
                    <Button
                      size="large"
                      color="secondary"
                      variant="contained"
                      onClick={() => goToNodeDashboards(nodeCode)}
                    >
                      {t("scenes.node.dashboards")}
                    </Button>
                  </Grid>
                )}
                {isDefault && otherNodes.length > 0 && (
                  <Grid item>
                    <Button
                      id="other-nodes-btn"
                      size="large"
                      color="secondary"
                      variant="contained"
                      onClick={() => {
                        this.scroll(this.nodes);
                        this.nodes.current.focus({preventScroll: true});
                      }}
                    >
                      {t("scenes.node.otherNodes")}
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Hero>
            <div className={classes.fullWidthContainer}>
              <Container className={classes.container}>
                {node && node.description && node.description.length > 0 && (
                  <div className={classes.anchorContainer}>
                    <div
                      id="node-information"
                      className={classes.anchor}
                      ref={this.info}
                      tabIndex={-1}
                    />
                    <PageSection
                      className={`node-information ${classes.section}`}
                      sectiontitle={t("scenes.node.informations")}
                    >
                      <Box textAlign="justify">
                        <SanitizedHTML html={node.description} allowTarget/>
                      </Box>
                    </PageSection>
                  </div>
                )}
                {isDefault && otherNodes.length > 0 && (
                  <div id="other-nodes-section" className={classes.anchorContainer}>
                    <div
                      id="other-nodes"
                      className={classes.anchor}
                      ref={this.nodes}
                      tabIndex={-1}
                    />
                    <PageSection className={classes.section} sectiontitle={t("scenes.node.otherNodes")}>
                      <Box className={classes.nodesContainer}>
                        <nav aria-label={t("scenes.node.otherNodes")}>
                          <CardsGrid
                            list={otherNodes.sort((a, b) => a.order - b.order).map(({code, name, backgroundMediaURL}) => ({
                              code,
                              id: code,
                              label: name,
                              image: backgroundMediaURL ? baseURL + backgroundMediaURL : "./images/default-node-background.jpg"
                            }))}
                            getHref={node => getNodeInternalUrl(node?.code)}
                          />
                        </nav>
                      </Box>
                    </PageSection>
                  </div>
                )}
              </Container>
              <Footer/>
            </div>
          </main>
        </Page>
      </Fragment>
    );
  }
}

export default compose(
  withStyles(styles),
  withTranslation(),
  connect(mapStateToProps),
  withRouter
)(Node);