import {IHubNode} from "../../model/IHubNode";
import {Reducer} from "redux";
import {REQUEST_ERROR, REQUEST_INIT, REQUEST_SUCCESS} from "../../middlewares/request/requestActions";
import {NODE_CLEAR, NODE_FETCH} from "./nodeActions";

export type NodeState = IHubNode | null;

const nodeReducer: Reducer<NodeState> = (
  state = null,
  action
) => {
  switch (action.type) {
    case NODE_CLEAR: {
      return null;
    }
    case REQUEST_INIT: {
      switch (action.payload.label) {
        case NODE_FETCH: {
          return null;
        }
        default:
          return state;
      }
    }
    case REQUEST_SUCCESS: {
      switch (action.payload.label) {
        case NODE_FETCH: {
          return action.payload.response;
        }
        default:
          return state;
      }
    }
    case REQUEST_ERROR: {
      switch (action.payload.label) {
        case NODE_FETCH: {
          return state;
        }
        default:
          return state;
      }
    }
    default:
      return state;
  }
};

export default nodeReducer;