import React from 'react';
import SvgIcon from "@material-ui/core/SvgIcon";

const AreaIcon = ({props}) =>
  <SvgIcon {...props}>
    <path
      d="M 2.00,20.00
        C 2.00,20.00 22.00,20.00 22.00,20.00
         22.00,20.00 22.00,4.08 22.00,4.08
         21.58,4.28 20.69,5.19 20.32,5.56
         20.32,5.56 18.08,7.69 18.08,7.69
         17.82,7.95 16.82,8.94 16.56,9.04
         16.40,9.10 16.17,9.09 16.00,9.09
         16.00,9.09 13.60,9.09 13.60,9.09
         12.89,9.10 12.65,9.63 12.24,10.15
         12.24,10.15 10.54,12.29 10.54,12.29
         9.99,12.99 9.88,13.43 8.96,13.44
         8.96,13.44 6.80,13.44 6.80,13.44
         6.64,13.44 6.39,13.43 6.24,13.48
         5.89,13.62 5.26,14.58 5.01,14.91
         5.01,14.91 2.85,17.70 2.85,17.70
         2.41,18.27 2.00,18.60 2.00,19.34
         2.00,19.34 2.00,20.00 2.00,20.00 Z"
    />
  </SvgIcon>

export default AreaIcon;