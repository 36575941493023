import React, {Fragment, useEffect, useState} from "react";
import Grid from "@material-ui/core/Grid";
import Chip from "@material-ui/core/Chip";
import IconButton from "@material-ui/core/IconButton";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Card from "@material-ui/core/Card";
import Avatar from "@material-ui/core/Avatar";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import {useTranslation} from "react-i18next";
import getDownloadButtonStyle from './getDownloadButtonStyle';
import withStyles from "@material-ui/core/styles/withStyles";
import SanitizedHTML from "../sanitized-html";
import CatalogInfoButton from "../catalog-info-button";
import CatalogMetadataButton from "../catalog-metadata-button";
import Tooltip from "@material-ui/core/Tooltip";
import InfoIcon from '@material-ui/icons/Info';
import CustomLink from "../custom-link";
import StorageIcon from '@material-ui/icons/Storage';

const $ = window.jQuery;

const styles = () => ({
  dataset: {
    display: "flex",
    alignItems: "center"
  },
  actions: {
    display: "flex",
    alignItems: "center",
    padding: "8px 16px",
    "& > button": {
      padding: 12
    },
    "& > .MuiChip-root": {
      margin: 12
    },
    "& > .MuiChip-root:focus": {
      outline: "-webkit-focus-ring-color auto 1px"
    }
  },
  downloadChipDeleteIcon: {
    backgroundColor: "white"
  },
  counterAvatar: {
    width: 16,
    height: 16,
    marginLeft: -4,
    marginRight: 4
  },
  description: {
    width: "100%",
    padding: "16px !important"
  },
  descriptionTextWrapper: {
    fontSize: 16
  },
  descriptionTextWrapperCollapsed: {
    maxHeight: 48,
    overflow: "hidden"
  },
  descriptionTextWrapperExpanded: {
    minHeight: 48
  },
  descriptionText: {
    "& > p": {
      margin: 0
    }
  },
  descriptionIcon: {
    display: "none",
    justifyContent: "flex-end"
  }
});

// based on https://stackoverflow.com/a/7616484
const getIdFromDatasetIdentifier = identifier => {
  if (!identifier) {
    return undefined;
  }

  let hash = 0, i, chr;
  if (identifier.length === 0) return hash;
  for (i = 0; i < identifier.length; i++) {
    chr = identifier.charCodeAt(i);
    hash = ((hash << 5) - hash) + chr;
    hash |= 0; // convert to 32bit integer
  }
  return "id__" + hash;
};

const handleStyle = dataset => {
  const textHeight = $(`#${getIdFromDatasetIdentifier(dataset.identifier)}__description__text`).outerHeight() || 0;
  const textWrapperHeight = $(`#${getIdFromDatasetIdentifier(dataset.identifier)}__description__text-wrapper`).outerHeight() || 0;
  $(`#${getIdFromDatasetIdentifier(dataset.identifier)}__description__icon`).css({
    display: (textHeight > textWrapperHeight) ? "flex" : "none"
  });
};

const DatasetCard = ({
                       dataset,
                       classes,
                       isExpanded,
                       onViewDataset,
                       onExpand,
                       onCollapse,
                       onClick,
                       xs,
                       md,
                       isMultiCategorized
                     }) => {

  const {t} = useTranslation();

  const [isDownloadModalOpen, setIsDownloadModalOpen] = useState(false);
  const [downloadModal, setDownloadModal] = useState(null);

  useEffect(() => {
    const f = () => handleStyle(dataset);
    f();
    window.addEventListener("resize", f);
    return () => window.removeEventListener("resize", f)
  }, [dataset]);

  const groupedAttachments = [];
  (dataset.attachedDataFiles || []).forEach(({format, url}) => {
    const group = groupedAttachments.find(({extension}) => extension === format.toLowerCase());
    if (!group) {
      groupedAttachments.push({
        extension: format.toLowerCase(),
        urls: [url]
      })
    } else {
      group.urls.push(url);
    }
  });

  return (
    <Fragment>
      <Grid item xs={xs} md={md} id={getIdFromDatasetIdentifier(dataset.identifier)}>
        <Card>
          <div className={classes.dataset} style={{width: "100%"}}>
            <CustomLink
              to={onViewDataset}
              onClick={onClick}
              text={
                <Fragment>
                  {dataset.title}
                  {isMultiCategorized && (
                    <Tooltip title={t("components.datasetCard.isMultiCategorized.title")}>
                      <InfoIcon fontSize="small" style={{margin: "0 0 -4px 8px"}}/>
                    </Tooltip>
                  )}
                </Fragment>
              }
              icon={<StorageIcon fontSize="small"/>}
              textStyle={{
                fontSize: 18,
                minHeight: 48,
                padding: "16px 16px 0 16px"
              }}
              subText={dataset.source}
              subTextStyle={{
                fontSize: 14,
                fontWeight: "normal",
                color: "rgba(0, 0, 0, 0.54)",
                padding: "0 16px 16px 16px"
              }}
              disabled={dataset.catalogType === "ONLY_FILE"}
            />
            {(groupedAttachments.length > 0 || dataset.note || dataset.referenceMetadata) && (
              <div className={classes.actions}>
                {groupedAttachments.map((group, index) =>
                  <Chip
                    key={index}
                    className={`download-btn--${group.extension.toLowerCase()}`}
                    label={group.extension.toUpperCase()}
                    deleteIcon={group.urls.length > 1
                      ? (
                        <Avatar style={{fontSize: 12, color: "black"}} classes={{root: classes.counterAvatar}}>
                          {group.urls.length}
                        </Avatar>
                      )
                      : null
                    }
                    onDelete={group.urls.length > 1
                      ? e => {
                        e.stopPropagation();
                        setIsDownloadModalOpen(true);
                        setDownloadModal(group)
                      }
                      : null
                    }
                    size="small"
                    onClick={e => {
                      e.stopPropagation();
                      if (group.urls.length > 1) {
                        setIsDownloadModalOpen(true);
                        setDownloadModal(group)
                      } else {
                        window.open(group.urls[0], "_blank")
                      }
                    }}
                    style={{
                      cursor: "pointer",
                      ...getDownloadButtonStyle(group.extension)
                    }}
                    classes={{
                      deleteIconSmall: classes.downloadChipDeleteIcon
                    }}
                    aria-label={
                      t("scenes.results.datasetCard.downloadAria", {
                        title: dataset.title,
                        extension: group.extension.toUpperCase()
                      })
                    }
                  />
                )}
                {dataset.note && (
                  <CatalogInfoButton
                    info={dataset.note}
                    label={t("components.datasetCard.actions.notes.title")}
                    iconSize="small"
                  />
                )}
                {dataset.referenceMetadata && (
                  <CatalogMetadataButton
                    metadataUrl={dataset.referenceMetadata}
                    iconSize="small"
                  />
                )}
              </div>
            )}
          </div>
          {dataset.description && (
            <div
              className={classes.description}
            >
              <div
                id={`${getIdFromDatasetIdentifier(dataset.identifier)}__description__text-wrapper`}
                className={`${classes.descriptionTextWrapper} ${isExpanded ? classes.descriptionTextWrapperExpanded : classes.descriptionTextWrapperCollapsed}`}
              >
                {dataset.description && (
                  <SanitizedHTML
                    id={`${getIdFromDatasetIdentifier(dataset.identifier)}__description__text`}
                    html={dataset.description}
                    className={classes.descriptionText}
                  />
                )}
              </div>
              <div
                id={`${getIdFromDatasetIdentifier(dataset.identifier)}__description__icon`}
                className={classes.descriptionIcon}
              >
                <IconButton
                  onClick={isExpanded ? onCollapse : onExpand}
                  title={isExpanded ? t("commons.confirm.collapse") : t("commons.confirm.expand")}
                >
                  {isExpanded
                    ? <ExpandLessIcon/>
                    : <ExpandMoreIcon/>
                  }
                </IconButton>
              </div>
            </div>
          )}
        </Card>
      </Grid>

      <Dialog
        open={!!isDownloadModalOpen}
        disableEscapeKeyDown
        disableBackdropClick
        onClose={() => setIsDownloadModalOpen(false)}
        onExited={() => setDownloadModal(null)}
      >
        <DialogContent>
          <List>
            {downloadModal?.urls.map((url, key) =>
              <ListItem key={key}>
                <a href={url} target="_blank" rel="noopener noreferrer">{url}</a>
              </ListItem>
            )}
          </List>
          <DialogActions>
            <Button onClick={() => setIsDownloadModalOpen(false)}>
              {t("commons.confirm.close")}
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>

    </Fragment>
  );
};

export default withStyles(styles)(DatasetCard);