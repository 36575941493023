import {Reducer} from "@reduxjs/toolkit";
import {REQUEST_ERROR, REQUEST_INIT, REQUEST_SUCCESS} from "../../middlewares/request/requestActions";
import {
  ALL_FULL,
  ALL_PARTIAL,
  CRITERIA_SELECTION_MODE_ALL,
  CRITERIA_SELECTION_MODE_STEP_BY_STEP,
  CRITERIA_SELECTION_TYPE_DYNAMIC,
  CRITERIA_SELECTION_TYPE_FULL,
  CRITERIA_SELECTION_TYPE_PARTIAL,
  DATASET_CHART_FILTERS_TREE_SUBMIT,
  DATASET_CHART_LAYOUT_SUBMIT,
  DATASET_CHART_SETTINGS_SET,
  DATASET_CRITERIA_ALERT_HIDE,
  DATASET_CRITERIA_HIDE,
  DATASET_CRITERIA_SHOW,
  DATASET_DOWNLOAD_SUBMIT,
  DATASET_DOWNLOAD_WARNING_HIDE,
  DATASET_FETCH_ENABLE,
  DATASET_GEOMETRIES_FETCH,
  DATASET_HTML_GENERATING_TIME_SET,
  DATASET_LABEL_FORMAT_SET,
  DATASET_MAP_DETAIL_LEVEL_SET,
  DATASET_MAP_LAYOUT_SUBMIT,
  DATASET_MAP_SETTINGS_SET,
  DATASET_SDMX_QUERY_FETCH,
  DATASET_SDMX_QUERY_HIDE,
  DATASET_SDMX_QUERY_SHOW,
  DATASET_STATE_BACKUP,
  DATASET_STATE_RESET,
  DATASET_STRUCTURE_CODELIST_CRITERIA_SET,
  DATASET_STRUCTURE_CODELIST_FETCH,
  DATASET_STRUCTURE_CODELIST_TIME_PERIOD_SET,
  DATASET_STRUCTURE_CODELISTS_FETCH,
  DATASET_STRUCTURE_FETCH,
  DATASET_STRUCTURE_FETCH_ENABLE,
  DATASET_TABLE_FILTERS_TREE_SUBMIT,
  DATASET_TABLE_LAYOUT_SUBMIT,
  DATASET_UNAVAILABLE_VIEW_WARNING_HIDE,
  DATASET_VARIATION_SET,
  DATASET_VIEW_ERROR_HIDE,
  DATASET_VIEW_TEMPLATE_GEOMETRIES_FETCH,
  DATASET_VIEW_TEMPLATE_HIDE,
  DATASET_VIEW_TEMPLATE_SHOW,
  DATASET_VIEW_TEMPLATE_SUBMIT,
  DATASET_VIEWER_SET,
  STEP_BY_STEP_FULL,
  STEP_BY_STEP_PARTIAL
} from "./datasetActions";
import {getTreeFromArray} from "../../utils/tree";
import {FREQ_DIMENSION_KEY, TIME_PERIOD_DIMENSION_KEY} from "../../utils/jsonStat";
import {LABEL_FORMAT_SELECTOR_LABEL_FORMAT_NAME} from "../../components/label-format-selector/constants";
import {getViewerIdxFromType, getViewerTypeFromIdx} from "../../components/data-viewer";
import {localizeI18nObj} from "../../utils/i18n";
import {
  FETCH_DATASET_ASYNC_HANDLER_ERROR,
  FETCH_DATASET_ASYNC_HANDLER_INIT,
  FETCH_DATASET_ASYNC_HANDLER_SUCCESS
} from "../../middlewares/fetchDatasetAsyncHandler/actions";
import {
  CRITERIA_FILTER_TYPE_CODES,
  CRITERIA_FILTER_TYPE_EMPTY,
  CRITERIA_FILTER_TYPE_PERIODS,
  CRITERIA_FILTER_TYPE_RANGE,
  getCriteriaArrayFromObject,
  getCriteriaObjectFromArray,
  getFreq,
  getTimePeriod
} from "../../utils/criteria";
import _ from "lodash";
import {CHART_DATA_LABEL_TYPE_DEFAULT, CHART_LEGEND_POSITION_DEFAULT} from "../../components/chart";
import {TABLE_EMPTY_CHAR_DEFAULT} from "../../utils/formatters";
import {getGeometryDetailLevels, getStorageDatasetKey} from "../../utils/other";
import {
  MAP_CLASSIFICATION_METHOD_DEFAULT,
  MAP_IS_LEGEND_COLLAPSED_DEFAULT,
  MAP_OPACITY_DEFAULT,
  MAP_PALETTE_CARDINALITY_DEFAULT,
  MAP_PALETTE_COLOR_END_DEFAULT,
  MAP_PALETTE_COLOR_START_DEFAULT
} from "../../components/map";
import {isValidIntegerInInclusiveRange} from "../../utils/validator";
import {getCurrentNodeConfig} from "../../middlewares/action-decorator/actionDecoratorMiddlewareFactory";

export const GENERATING_HTML_TIME_KEY = "generatingHtml";
export const OBSERVATION_COUNT_KEY = "observationCount";
export const SERVER_TIMINGS_KEY = "serverTimings";

const MAX_ALLOWED_CELLS = 1000000;

type TimePeriod = {
  selectorType: string,
  freq: string | null,
  minDate: string | null,
  maxDate: string | null,
  fromDate: string | null,
  toDate: string | null,
  periods: number | null,
  missingRange: boolean
};

const initialTimePeriod = {
  selectorType: CRITERIA_FILTER_TYPE_RANGE,
  freq: null,
  minDate: null,
  maxDate: null,
  fromDate: null,
  toDate: null,
  periods: null,
  missingRange: false
};

type ChartSettings = {
  chartStacked: boolean,
  chartLegendPosition: string,
  chartColors: any,
  chartShowTitle: boolean,
  chartTitle: { [key: string]: string },
  chartShowFilters: boolean,
  chartShowAxesLabel: boolean,
  chartCustomizeCategoryAxis: boolean,
  chartCategoryAxisLabel: { [key: string]: string }
  chartValueAxisLabel: { [key: string]: string }
  chartDataLabelType: string
};

const initialChartSettings = {
  chartStacked: false,
  chartLegendPosition: CHART_LEGEND_POSITION_DEFAULT,
  chartColors: {},
  chartShowTitle: true,
  chartTitle: {},
  chartShowFilters: true,
  chartShowAxesLabel: true,
  chartCustomizeCategoryAxis: false,
  chartCategoryAxisLabel: {},
  chartValueAxisLabel: {},
  chartDataLabelType: CHART_DATA_LABEL_TYPE_DEFAULT
};

type MapSettings = {
  mapClassificationMethod: string,
  mapPaletteStartColor: string,
  mapPaletteEndColor: string,
  mapPaletteCardinality: number,
  mapOpacity: number,
  mapIsLegendCollapsed: boolean,
  mapShowTitle: boolean,
  mapTitle: { [key: string]: string },
  mapShowFilters: boolean
};

const initialMapSettings = {
  mapClassificationMethod: MAP_CLASSIFICATION_METHOD_DEFAULT,
  mapPaletteStartColor: MAP_PALETTE_COLOR_START_DEFAULT,
  mapPaletteEndColor: MAP_PALETTE_COLOR_END_DEFAULT,
  mapPaletteCardinality: MAP_PALETTE_CARDINALITY_DEFAULT,
  mapOpacity: MAP_OPACITY_DEFAULT,
  mapIsLegendCollapsed: MAP_IS_LEGEND_COLLAPSED_DEFAULT,
  mapShowTitle: true,
  mapTitle: {},
  mapShowFilters: true
};

type DatasetState = {
  datasetId: string | null,
  nodeCode: string | null
  categoryPath: string[] | null,
  viewId: string | null,
  dataset: any | null,
  isFirstFetch: boolean,
  isPartialData: boolean,
  isEmptyData: boolean,
  isTooBigData: boolean,
  isTooLongQuery: boolean,
  isCriteriaVisible: boolean,
  isCriteriaAlertVisible: boolean,
  dimensions: any[] | null,
  freqDimension: string | null,
  mode: string | null,
  type: string | null,
  viewerIdx: number,
  view: any | null,
  template: any | null,
  hasViewLayout: boolean,
  hasTemplateLayout: boolean,
  hasAnnotationLayout: boolean,
  tableLayout: any | null,
  mapLayout: any | null,
  chartLayout: any | null,
  tableFilterTree: any | null,
  mapFilterTree: any | null,
  chartFilterTree: any | null,
  timePeriodsByFreq: any | null,
  labelFormat: string | null,
  showTrend: boolean,
  showCyclical: boolean,
  criteria: object,
  initialCriteria: any | null,
  decimalSeparator: string | null,
  decimalPlaces: number | null
  tableEmptyChar: string,
  chartSettings: ChartSettings,
  mapDetailLevel: number | null,
  mapSettings: MapSettings
  hasMapStartColor: boolean | null,
  hasMapEndColor: boolean | null,
  enableCriteria: boolean,
  enableLayout: boolean,
  enableVariation: boolean,
  maxAllowedCells: number,
  codes: any[] | null,
  isCodesFlat: boolean,
  codelists: any[] | null,
  areCodelistsFlat: boolean[],
  codelistsLength: number[] | null,
  timePeriod: TimePeriod,
  criteriaObsCount: number | null,
  timings: any | null,
  isFetchStructureDisabled: boolean,
  isFetchDatasetDisabled: boolean,
  isDownloadWarningVisible: boolean,
  isUnavailableViewWarningVisible: boolean,
  geometries: any | null,
  geometryDetailLevels: any | null,
  backup: any | null,
  isViewVisible: boolean,
  isViewErrorVisible: boolean,
  viewErrorMessage: string | null,
  isTemplateVisible: boolean,
  templateGeometries: any | null,
  templateGeometryDetailLevels: any | null,
  isQueryVisible: boolean,
  structureQuery: string | null,
  dataQuery: string | null
};

const initialState = {
  datasetId: null,
  nodeCode: null,
  categoryPath: null,
  viewId: null,
  dataset: null,
  isFirstFetch: false,
  isPartialData: false,
  isEmptyData: false,
  isTooBigData: false,
  isTooLongQuery: false,
  isCriteriaVisible: false,
  isCriteriaAlertVisible: false,
  dimensions: null,
  freqDimension: null,
  mode: null,
  type: null,
  viewerIdx: 0,
  view: null,
  template: null,
  hasViewLayout: false,
  hasTemplateLayout: false,
  hasAnnotationLayout: false,
  tableLayout: null,
  mapLayout: null,
  chartLayout: null,
  tableFilterTree: null,
  mapFilterTree: null,
  chartFilterTree: null,
  timePeriodsByFreq: null,
  labelFormat: null,
  showTrend: false,
  showCyclical: false,
  criteria: {},
  initialCriteria: null,
  decimalSeparator: null,
  decimalPlaces: null,
  tableEmptyChar: TABLE_EMPTY_CHAR_DEFAULT,
  chartSettings: initialChartSettings,
  mapDetailLevel: null,
  mapSettings: initialMapSettings,
  hasMapStartColor: null,
  hasMapEndColor: null,
  enableCriteria: true,
  enableLayout: true,
  enableVariation: false,
  maxAllowedCells: MAX_ALLOWED_CELLS,
  codes: null,
  isCodesFlat: false,
  codelists: null,
  areCodelistsFlat: [],
  codelistsLength: null,
  timePeriod: initialTimePeriod,
  criteriaObsCount: null,
  timings: null,
  isFetchStructureDisabled: true,
  isFetchDatasetDisabled: true,
  isDownloadWarningVisible: false,
  isUnavailableViewWarningVisible: false,
  geometries: null,
  geometryDetailLevels: null,
  backup: null,
  isViewVisible: false,
  isViewErrorVisible: false,
  viewErrorMessage: null,
  isTemplateVisible: false,
  templateGeometries: null,
  templateGeometryDetailLevels: null,
  isQueryVisible: false,
  structureQuery: null,
  dataQuery: null
};

const datasetReducer: Reducer<DatasetState> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case DATASET_STATE_BACKUP: {
      return {
        ...state,
        backup: {
          viewId: state.viewId,
          datasetId: state.datasetId,
          nodeCode: state.nodeCode,
          categoryPath: state.categoryPath,
          template: {
            defaultView: getViewerTypeFromIdx(state.viewerIdx),
            criteria: getCriteriaArrayFromObject(state.criteria),
            layouts: JSON.stringify({
              labelFormat: state.labelFormat,
              showTrend: state.showTrend,
              showCyclical: state.showCyclical,
              tableLayout: state.tableLayout,
              mapLayout: state.mapLayout,
              chartLayout: state.chartLayout,
              tableEmptyChar: state.tableEmptyChar,
              ...state.chartSettings,
              mapDetailLevel: state.mapDetailLevel,
              ...state.mapSettings
            })
          }
        }
      }
    }
    case DATASET_STATE_RESET: {
      if (state.dataset) {
        const storageKey = getStorageDatasetKey({
          nodeCode: state.nodeCode,
          categoryPath: state.categoryPath,
          datasetId: state.datasetId,
          viewId: state.viewId
        });
        if (sessionStorage.getItem(storageKey) !== null && sessionStorage.getItem(storageKey) !== undefined) {
          sessionStorage.setItem(
            storageKey,
            JSON.stringify({
              defaultView: getViewerTypeFromIdx(state.viewerIdx),
              criteria: getCriteriaArrayFromObject(state.criteria),
              layouts: JSON.stringify({
                labelFormat: state.labelFormat,
                showTrend: state.showTrend,
                showCyclical: state.showCyclical,
                tableLayout: state.tableLayout,
                mapLayout: state.mapLayout,
                chartLayout: state.chartLayout,
                tableEmptyChar: state.tableEmptyChar,
                ...state.chartSettings,
                mapDetailLevel: state.mapDetailLevel,
                ...state.mapSettings
              })
            })
          );
        }
      }
      return {
        ...initialState,
        backup: state.backup
      }
    }
    case DATASET_STRUCTURE_FETCH_ENABLE: {
      return {
        ...initialState,
        isFetchStructureDisabled: false,
        datasetId: action.payload.datasetId,
        nodeCode: action.payload.nodeCode,
        categoryPath: action.payload.categoryPath,
        viewId: (action.payload.viewId || null),
        backup: state.backup
      }
    }
    case DATASET_FETCH_ENABLE: {
      return {
        ...state,
        isFetchDatasetDisabled: false,
        criteriaObsCount: null
      }
    }
    case DATASET_CRITERIA_SHOW: {
      return {
        ...state,
        isCriteriaVisible: true
      }
    }
    case DATASET_CRITERIA_HIDE: {
      return {
        ...state,
        isCriteriaVisible: false,
        criteria: state.initialCriteria,
        codes: null,
        isCodesFlat: false,
        codelists: null,
        areCodelistsFlat: [],
        timePeriod: initialTimePeriod
      }
    }
    case DATASET_CRITERIA_ALERT_HIDE: {
      return {
        ...state,
        isCriteriaAlertVisible: false
      }
    }
    case DATASET_STRUCTURE_CODELIST_CRITERIA_SET: {
      return {
        ...state,
        criteria: action.criteria,
        timePeriod: {
          ...state.timePeriod,
          freq: action.criteria[FREQ_DIMENSION_KEY]
            ? getFreq(action.criteria)
            : state.timePeriod.freq
        },
        criteriaObsCount: null
      }
    }
    case DATASET_STRUCTURE_CODELIST_TIME_PERIOD_SET: {
      let newCriteria = _.cloneDeep(state.criteria);
      const type = (action.timePeriod.selectorType || CRITERIA_FILTER_TYPE_EMPTY);

      let timePeriodCriteria = undefined;
      if (type !== CRITERIA_FILTER_TYPE_EMPTY || (type === CRITERIA_FILTER_TYPE_PERIODS && action.timePeriod.periods !== null)) {
        timePeriodCriteria = {
          id: TIME_PERIOD_DIMENSION_KEY,
          type: type,
          filterValues: null,
          period: type === CRITERIA_FILTER_TYPE_PERIODS ? action.timePeriod.periods : null,
          from: type === CRITERIA_FILTER_TYPE_RANGE ? action.timePeriod.fromDate : null,
          to: type === CRITERIA_FILTER_TYPE_RANGE ? action.timePeriod.toDate : null
        };
      }

      newCriteria = {
        ...newCriteria,
        [TIME_PERIOD_DIMENSION_KEY]: timePeriodCriteria
      };

      return {
        ...state,
        criteria: newCriteria,
        timePeriod: action.timePeriod,
        criteriaObsCount: null
      }
    }
    case DATASET_VIEWER_SET: {
      return {
        ...state,
        viewerIdx: action.viewerIdx
      }
    }
    case DATASET_TABLE_LAYOUT_SUBMIT: {
      return {
        ...state,
        tableLayout: action.layout
      }
    }
    case DATASET_TABLE_FILTERS_TREE_SUBMIT: {
      return {
        ...state,
        tableFilterTree: action.filterTree
      }
    }
    case DATASET_MAP_LAYOUT_SUBMIT: {
      return {
        ...state,
        mapLayout: action.layout
      }
    }
    case DATASET_CHART_LAYOUT_SUBMIT: {
      return {
        ...state,
        chartLayout: action.layout
      }
    }
    case DATASET_CHART_FILTERS_TREE_SUBMIT: {
      return {
        ...state,
        chartFilterTree: action.filterTree
      }
    }
    case DATASET_LABEL_FORMAT_SET: {
      return {
        ...state,
        labelFormat: action.labelFormat
      }
    }
    case DATASET_VARIATION_SET: {
      return {
        ...state,
        showTrend: action.variation?.showTrend || false,
        showCyclical: action.variation?.showCyclical || false
      }
    }
    case DATASET_CHART_SETTINGS_SET: {
      return {
        ...state,
        chartSettings: {
          ...state.chartSettings,
          ...action.chartSettings
        }
      }
    }
    case DATASET_MAP_DETAIL_LEVEL_SET: {
      return {
        ...state,
        mapDetailLevel: action.detailLevel
      }
    }
    case DATASET_MAP_SETTINGS_SET: {
      return {
        ...state,
        mapSettings: {
          ...state.mapSettings,
          ...action.mapSettings
        }
      }
    }
    case DATASET_DOWNLOAD_WARNING_HIDE: {
      return {
        ...state,
        isDownloadWarningVisible: false
      }
    }
    case DATASET_UNAVAILABLE_VIEW_WARNING_HIDE: {
      return {
        ...state,
        isUnavailableViewWarningVisible: false
      }
    }
    case DATASET_HTML_GENERATING_TIME_SET: {
      return {
        ...state,
        timings: {
          ...state.timings,
          [GENERATING_HTML_TIME_KEY]: action.time
        }
      }
    }
    case FETCH_DATASET_ASYNC_HANDLER_INIT: {
      return {
        ...state,
        isFetchDatasetDisabled: true
      }
    }
    case FETCH_DATASET_ASYNC_HANDLER_SUCCESS: {
      if (action.payload.worker) {
        action.payload.worker.terminate();
      }

      const isResponseValid = !action.payload.isResponseNotValid;

      return {
        ...state,
        isFirstFetch: false,

        dataset: isResponseValid ? action.payload.response : state.dataset,

        isTooBigData: action.payload.isTooBigData,
        isEmptyData: action.payload.isEmptyData,
        isPartialData: action.payload.isPartialData,

        isCriteriaVisible: !isResponseValid,
        isCriteriaAlertVisible: !isResponseValid,

        tableLayout: isResponseValid ? action.payload.tableLayout : state.tableLayout,
        mapLayout: isResponseValid ? action.payload.mapLayout : state.mapLayout,
        chartLayout: isResponseValid ? action.payload.chartLayout : state.chartLayout,

        tableFilterTree: isResponseValid ? action.payload.tableFilterTree : state.tableFilterTree,
        mapFilterTree: isResponseValid ? action.payload.mapFilterTree : state.mapFilterTree,
        chartFilterTree: isResponseValid ? action.payload.chartFilterTree : state.chartFilterTree,
        timePeriodsByFreq: isResponseValid ? action.payload.timePeriodsByFreq : state.timePeriodsByFreq,

        chartSettings: isResponseValid
          ? {
            ...state.chartSettings,
            chartTitle: {
              ...state.chartSettings.chartTitle,
              [action.payload.language]: (state.chartSettings.chartTitle[action.payload.language] !== null && state.chartSettings.chartTitle[action.payload.language] !== undefined)
                ? state.chartSettings.chartTitle[action.payload.language]
                : action.payload.response.label
            }
          }
          : state.chartSettings,

        mapSettings: isResponseValid
          ? {
            ...state.mapSettings,
            mapTitle: {
              ...state.mapSettings.mapTitle,
              [action.payload.language]: (state.mapSettings.mapTitle[action.payload.language] !== null && state.mapSettings.mapTitle[action.payload.language] !== undefined)
                ? state.mapSettings.mapTitle[action.payload.language]
                : action.payload.response.label
            }
          }
          : state.mapSettings,

        geometries: isResponseValid ? null : state.geometries,
        geometryDetailLevels: isResponseValid ? null : state.geometryDetailLevels,

        initialCriteria: isResponseValid ? state.criteria : state.initialCriteria,

        codes: isResponseValid ? null : state.codes,
        isCodesFlat: isResponseValid ? false : state.isCodesFlat,
        codelists: isResponseValid ? null : state.codelists,
        areCodelistsFlat: isResponseValid ? [] : state.areCodelistsFlat,

        timings: {
          [OBSERVATION_COUNT_KEY]: action.payload.response?.value ? Object.keys(action.payload.response.value).length : null,
          [SERVER_TIMINGS_KEY]: action.payload.responseHeaders.backendtimers
            ? JSON.parse(action.payload.responseHeaders.backendtimers)
            : null
        },

        structureQuery: null,
        dataQuery: null
      }
    }
    case FETCH_DATASET_ASYNC_HANDLER_ERROR: {
      const isPayloadTooLarge = action.payload.statusCode === 413;
      const isTooLongQuery = action.payload.statusCode === 414;

      return {
        ...state,
        dataset: null,
        isPartialData: false,
        isEmptyData: false,
        isTooBigData: isPayloadTooLarge,
        isTooLongQuery: isTooLongQuery,
        isCriteriaAlertVisible: (isPayloadTooLarge || isTooLongQuery)
      }
    }
    case DATASET_VIEW_TEMPLATE_SHOW: {
      return {
        ...state,
        isViewVisible: action.isView ? true : state.isViewVisible,
        isTemplateVisible: !action.isView ? true : state.isTemplateVisible
      }
    }
    case DATASET_VIEW_TEMPLATE_HIDE: {
      return {
        ...state,
        isViewVisible: action.isView ? false : state.isViewVisible,
        isTemplateVisible: !action.isView ? false : state.isTemplateVisible,
        templateGeometries: null,
        templateGeometryDetailLevels: null
      }
    }
    case DATASET_VIEW_ERROR_HIDE: {
      return {
        ...state,
        isViewErrorVisible: false,
        viewErrorMessage: null
      }
    }
    case DATASET_SDMX_QUERY_SHOW: {
      return {
        ...state,
        isQueryVisible: true
      }
    }
    case DATASET_SDMX_QUERY_HIDE: {
      return {
        ...state,
        isQueryVisible: false
      }
    }
    case REQUEST_INIT: {
      switch (action.payload.label) {
        case DATASET_STRUCTURE_FETCH: {
          return {
            ...state,
            isFirstFetch: true,
            isFetchStructureDisabled: true
          }
        }
        case DATASET_STRUCTURE_CODELIST_FETCH: {
          return {
            ...state,
            codes: null,
            isCodesFlat: false,
            criteriaObsCount: null
          }
        }
        default:
          return state
      }
    }
    case REQUEST_SUCCESS: {
      switch (action.payload.label) {
        case DATASET_STRUCTURE_FETCH: {
          const criteriaView = action.payload.response.criteriaView;

          const VIEW_KEY = "view";
          const TEMPLATE_KEY = "template";

          const storageKey = getStorageDatasetKey({
            nodeCode: state.nodeCode,
            categoryPath: state.categoryPath,
            datasetId: state.datasetId,
            viewId: state.viewId
          });

          const storageItem = sessionStorage.getItem(storageKey);
          const hasSessionBackup = (storageItem !== null && storageItem !== undefined && storageItem !== "");

          let viewTemplate = hasSessionBackup
            ? JSON.parse(storageItem || "")
            : action.payload.response[VIEW_KEY]
              ? action.payload.response[VIEW_KEY]
              : action.payload.response[TEMPLATE_KEY]
                ? action.payload.response[TEMPLATE_KEY]
                : null;

          const hasBackup = state.backup !== null && (
            state.backup.nodeCode === state.nodeCode &&
            (state.backup.categoryPath || []).join() === (state.categoryPath || []).join() &&
            state.backup.datasetId === state.datasetId &&
            (state.backup.viewId || "") === (state.viewId || "")
          );

          if (hasBackup && !hasSessionBackup) {
            viewTemplate = {
              ...viewTemplate,
              ...state.backup?.template
            }
          }

          let viewTemplateLayouts = viewTemplate
            ? JSON.parse(viewTemplate.layouts)
            : null;

          if (action.payload.response[VIEW_KEY]) {
            if (action.payload.response[TEMPLATE_KEY]) {
              const templateLayouts = JSON.parse(action.payload.response[TEMPLATE_KEY].layouts);
              viewTemplate = {
                ...viewTemplate,
                enableCriteria: action.payload.response[TEMPLATE_KEY].enableCriteria,
                enableLayout: action.payload.response[TEMPLATE_KEY].enableLayout,
                enableVariation: action.payload.response[TEMPLATE_KEY].enableVariation
              };
              viewTemplateLayouts = {
                ...viewTemplateLayouts,
                tableEmptyChar: templateLayouts.tableEmptyChar,
                tableLayout: (viewTemplateLayouts.tableLayout || templateLayouts.tableLayout),
                mapLayout: (viewTemplateLayouts.mapLayout || templateLayouts.mapLayout),
                chartLayout: (viewTemplateLayouts.chartLayout || templateLayouts.chartLayout)
              };

            } else {
              viewTemplate = {
                ...viewTemplate,
                enableCriteria: true,
                enableLayout: true,
                enableVariation: false
              };
              viewTemplateLayouts = {
                ...viewTemplateLayouts,
                tableEmptyChar: null
              };
            }
          }

          const tableLayout = viewTemplateLayouts?.tableLayout
            ? viewTemplateLayouts.tableLayout
            : action.payload.response.layout
              ? action.payload.response.layout
              : null;

          const mapLayout = viewTemplateLayouts?.mapLayout
            ? viewTemplateLayouts.mapLayout
            : null;

          const chartLayout = viewTemplateLayouts?.chartLayout
            ? viewTemplateLayouts.chartLayout
            : action.payload.response.layoutChart
              ? {
                ...action.payload.response.layoutChart,
                filters: undefined
              }
              : null;

          let structureCriteria = viewTemplate
            ? viewTemplate.criteria
            : action.payload.response.filters
              ? action.payload.response.filters
              : null;

          const criteria = getCriteriaObjectFromArray(structureCriteria);

          let viewerIdx = 0;

          if (viewTemplate) {
            viewerIdx = getViewerIdxFromType(viewTemplate.defaultView);

          } else if (action.payload.response.defaultView) {
            const type = action.payload.response.defaultView;
            if ((type || "").toLowerCase() === "table") {
              viewerIdx = 0;
            } else if ((type || "").toLowerCase() === "map") {
              viewerIdx = 1;
            } else if ((type || "").toLowerCase() === "graph") {
              viewerIdx = 2;
            }
          }

          const dimensions = (action.payload.response?.criteria || []);

          return {
            ...state,
            backup: null,
            hasViewLayout: !!action.payload.response[VIEW_KEY],
            hasTemplateLayout: !!action.payload.response[TEMPLATE_KEY],
            hasAnnotationLayout: !!action.payload.response.layout,
            view: action.payload.response[VIEW_KEY]
              ? {
                ...action.payload.response[VIEW_KEY],
                layouts: JSON.parse(action.payload.response[VIEW_KEY].layouts)
              }
              : null,
            template: action.payload.response[TEMPLATE_KEY]
              ? {
                ...action.payload.response[TEMPLATE_KEY],
                layouts: JSON.parse(action.payload.response[TEMPLATE_KEY].layouts)
              }
              : null,
            dimensions: dimensions,
            freqDimension: action.payload.response.freqDimension || null,
            mode: (criteriaView === ALL_FULL || criteriaView === ALL_PARTIAL)
              ? CRITERIA_SELECTION_MODE_ALL
              : CRITERIA_SELECTION_MODE_STEP_BY_STEP,
            type: (criteriaView === ALL_FULL || criteriaView === STEP_BY_STEP_FULL)
              ? CRITERIA_SELECTION_TYPE_FULL
              : (criteriaView === ALL_PARTIAL || criteriaView === STEP_BY_STEP_PARTIAL)
                ? CRITERIA_SELECTION_TYPE_PARTIAL
                : CRITERIA_SELECTION_TYPE_DYNAMIC,
            codelistsLength: state.codelistsLength ? state.codelistsLength : action.payload.response.criteria.map(() => null),
            isCriteriaVisible: structureCriteria === null,
            viewerIdx: viewerIdx,
            isUnavailableViewWarningVisible: (!!action.payload.extra?.viewId && !action.payload.response?.[VIEW_KEY]),
            tableLayout: tableLayout,
            mapLayout: mapLayout,
            chartLayout: chartLayout,
            labelFormat: (viewTemplateLayouts?.labelFormat || LABEL_FORMAT_SELECTOR_LABEL_FORMAT_NAME),
            showTrend: (viewTemplateLayouts?.showTrend || false),
            showCyclical: (viewTemplateLayouts?.showCyclical || false),
            criteria: criteria,
            initialCriteria: criteria,
            decimalSeparator: (Object.keys(viewTemplate?.decimalSeparator || {}).length > 0)
              ? localizeI18nObj(viewTemplate.decimalSeparator, action.payload.extra.defaultLanguage, action.payload.extra.languages)
              : action.payload.response.decimalSeparator,
            decimalPlaces: (viewTemplate?.decimalNumber !== null && viewTemplate?.decimalNumber !== undefined && viewTemplate?.decimalNumber !== -1)
              ? viewTemplate?.decimalNumber
              : action.payload.response.decimalPlaces,
            tableEmptyChar: (viewTemplateLayouts?.tableEmptyChar !== null && viewTemplateLayouts?.tableEmptyChar !== undefined)
              ? viewTemplateLayouts.tableEmptyChar
              : action.payload.response.emptyCellPlaceHolder,
            chartSettings: {
              chartStacked: (viewTemplateLayouts?.chartStacked !== null && viewTemplateLayouts?.chartStacked !== undefined)
                ? viewTemplateLayouts.chartStacked
                : state.chartSettings.chartStacked,
              chartLegendPosition: (viewTemplateLayouts?.chartLegendPosition !== null && viewTemplateLayouts?.chartLegendPosition !== undefined)
                ? viewTemplateLayouts.chartLegendPosition
                : state.chartSettings.chartLegendPosition,
              chartColors: (viewTemplateLayouts?.chartColors !== null && viewTemplateLayouts?.chartColors !== undefined)
                ? viewTemplateLayouts.chartColors
                : state.chartSettings.chartColors,
              chartShowTitle: (viewTemplateLayouts?.chartShowTitle !== null && viewTemplateLayouts?.chartShowTitle !== undefined)
                ? viewTemplateLayouts.chartShowTitle
                : state.chartSettings.chartShowTitle,
              chartTitle: (viewTemplateLayouts?.chartTitle !== null && viewTemplateLayouts?.chartTitle !== undefined)
                ? viewTemplateLayouts.chartTitle
                : state.chartSettings.chartTitle,
              chartShowFilters: (viewTemplateLayouts?.chartShowFilters !== null && viewTemplateLayouts?.chartShowFilters !== undefined)
                ? viewTemplateLayouts.chartShowFilters
                : state.chartSettings.chartShowFilters,
              chartShowAxesLabel: (viewTemplateLayouts?.chartShowAxesLabel !== null && viewTemplateLayouts?.chartShowAxesLabel !== undefined)
                ? viewTemplateLayouts.chartShowAxesLabel
                : state.chartSettings.chartShowAxesLabel,
              chartCustomizeCategoryAxis: (viewTemplateLayouts?.chartCustomizeCategoryAxis !== null && viewTemplateLayouts?.chartCustomizeCategoryAxis !== undefined)
                ? viewTemplateLayouts.chartCustomizeCategoryAxis
                : state.chartSettings.chartCustomizeCategoryAxis,
              chartCategoryAxisLabel: (viewTemplateLayouts?.chartCategoryAxisLabel !== null && viewTemplateLayouts?.chartCategoryAxisLabel !== undefined)
                ? viewTemplateLayouts.chartCategoryAxisLabel
                : state.chartSettings.chartCategoryAxisLabel,
              chartValueAxisLabel: (viewTemplateLayouts?.chartValueAxisLabel !== null && viewTemplateLayouts?.chartValueAxisLabel !== undefined)
                ? viewTemplateLayouts.chartValueAxisLabel
                : state.chartSettings.chartValueAxisLabel,
              chartDataLabelType: (viewTemplateLayouts?.chartDataLabelType !== null && viewTemplateLayouts?.chartDataLabelType !== undefined)
                ? viewTemplateLayouts.chartDataLabelType
                : state.chartSettings.chartDataLabelType
            },
            mapDetailLevel: (viewTemplateLayouts?.mapDetailLevel !== null && viewTemplateLayouts?.mapDetailLevel !== undefined)
              ? viewTemplateLayouts.mapDetailLevel
              : state.mapDetailLevel,
            mapSettings: {
              mapClassificationMethod: (viewTemplateLayouts?.mapClassificationMethod !== null && viewTemplateLayouts?.mapClassificationMethod !== undefined)
                ? viewTemplateLayouts.mapClassificationMethod
                : state.mapSettings.mapClassificationMethod,
              mapPaletteStartColor: (viewTemplateLayouts?.mapPaletteStartColor !== null && viewTemplateLayouts?.mapPaletteStartColor !== undefined)
                ? viewTemplateLayouts.mapPaletteStartColor
                : state.mapSettings.mapPaletteStartColor,
              mapPaletteEndColor: (viewTemplateLayouts?.mapPaletteEndColor !== null && viewTemplateLayouts?.mapPaletteEndColor !== undefined)
                ? viewTemplateLayouts.mapPaletteEndColor
                : state.mapSettings.mapPaletteEndColor,
              mapPaletteCardinality: (viewTemplateLayouts?.mapPaletteCardinality !== null && viewTemplateLayouts?.mapPaletteCardinality !== undefined)
                ? viewTemplateLayouts.mapPaletteCardinality
                : state.mapSettings.mapPaletteCardinality,
              mapOpacity: (viewTemplateLayouts?.mapOpacity !== null && viewTemplateLayouts?.mapOpacity !== undefined)
                ? viewTemplateLayouts.mapOpacity
                : state.mapSettings.mapOpacity,
              mapIsLegendCollapsed: false, // proprietà cablata per evitare doppia update
              // mapIsLegendCollapsed: (viewTemplateLayouts?.mapIsLegendCollapsed !== null && viewTemplateLayouts?.mapIsLegendCollapsed !== undefined)
              //   ? viewTemplateLayouts.mapIsLegendCollapsed
              //   : state.mapSettings.mapIsLegendCollapsed,
              mapShowTitle: (viewTemplateLayouts?.mapShowTitle !== null && viewTemplateLayouts?.mapShowTitle !== undefined)
                ? viewTemplateLayouts.mapShowTitle
                : state.mapSettings.mapShowTitle,
              mapTitle: (viewTemplateLayouts?.mapTitle !== null && viewTemplateLayouts?.mapTitle !== undefined)
                ? viewTemplateLayouts.mapTitle
                : state.mapSettings.mapTitle,
              mapShowFilters: (viewTemplateLayouts?.mapShowFilters !== null && viewTemplateLayouts?.mapShowFilters !== undefined)
                ? viewTemplateLayouts.mapShowFilters
                : state.mapSettings.mapShowFilters,
            },
            hasMapStartColor: (viewTemplateLayouts?.mapPaletteStartColor !== null && viewTemplateLayouts?.mapPaletteStartColor !== undefined),
            hasMapEndColor: (viewTemplateLayouts?.mapPaletteEndColor !== null && viewTemplateLayouts?.mapPaletteEndColor !== undefined),
            enableCriteria: (viewTemplate?.enableCriteria !== false && dimensions.length > 0),
            enableLayout: viewTemplate?.enableLayout !== false,
            enableVariation: viewTemplate?.enableVariation === true,
            isFetchDatasetDisabled: structureCriteria === null,
            maxAllowedCells: (action.payload.response.maxTableCells || MAX_ALLOWED_CELLS),
          }
        }
        case DATASET_STRUCTURE_CODELIST_FETCH: {

          const dimensionCodelist = action.payload.response.criteria[0] || {};

          let flatCodes = dimensionCodelist.values ? dimensionCodelist.values : [];
          const codelistIdx = dimensionCodelist.id ? (state.dimensions || []).map(({id}: any) => id).indexOf(dimensionCodelist.id) : -1;

          const newCodelistsLength = state.codelistsLength ? [...state.codelistsLength] : [];
          if (codelistIdx >= 0 && dimensionCodelist.id !== TIME_PERIOD_DIMENSION_KEY) {
            newCodelistsLength[codelistIdx] = flatCodes.length;
          }

          let isCodesFlat = true;
          flatCodes.forEach((item: any) => {
            if (isCodesFlat && item.parentId !== null && item.parentId !== undefined) {
              isCodesFlat = false;
            }
          });

          let timePeriod = null;

          let newCriteria: any = _.cloneDeep(state.criteria);

          if (dimensionCodelist.id !== TIME_PERIOD_DIMENSION_KEY) {
            if (flatCodes.length === 1) {
              newCriteria = {
                ...newCriteria,
                [dimensionCodelist.id]: {
                  id: dimensionCodelist.id,
                  type: CRITERIA_FILTER_TYPE_CODES,
                  filterValues: [flatCodes[0].id]
                }
              };
            }

          } else if (!newCriteria[TIME_PERIOD_DIMENSION_KEY]) {
            if (
              isValidIntegerInInclusiveRange(action.payload.extra.defaultLastNPeriods, 1) &&
              (state.type === CRITERIA_SELECTION_TYPE_DYNAMIC || state.type === CRITERIA_SELECTION_TYPE_PARTIAL)
            ) {
              newCriteria = {
                ...newCriteria,
                [TIME_PERIOD_DIMENSION_KEY]: {
                  id: TIME_PERIOD_DIMENSION_KEY,
                  type: CRITERIA_FILTER_TYPE_PERIODS,
                  period: action.payload.extra.defaultLastNPeriods
                }
              };

            } else {
              timePeriod = getTimePeriod(
                state.timePeriod,
                newCriteria,
                dimensionCodelist,
                null,
                getCurrentNodeConfig(action)
              );

              newCriteria = {
                ...newCriteria,
                [TIME_PERIOD_DIMENSION_KEY]: {
                  id: TIME_PERIOD_DIMENSION_KEY,
                  type: CRITERIA_FILTER_TYPE_RANGE,
                  from: timePeriod.fromDate,
                  to: timePeriod.toDate
                }
              };
            }
          }

          return {
            ...state,
            criteria: newCriteria,
            codes: getTreeFromArray((flatCodes || []), "parentId", "children"),
            isCodesFlat: isCodesFlat,
            codelistsLength: newCodelistsLength,
            timePeriod: timePeriod || getTimePeriod(
              state.timePeriod,
              newCriteria,
              dimensionCodelist.id === TIME_PERIOD_DIMENSION_KEY ? dimensionCodelist : null,
              dimensionCodelist.id === FREQ_DIMENSION_KEY ? dimensionCodelist : null,
              getCurrentNodeConfig(action)
            ),
            criteriaObsCount: (state.mode === CRITERIA_SELECTION_MODE_STEP_BY_STEP && state.type === CRITERIA_SELECTION_TYPE_DYNAMIC)
              ? (action.payload.response?.obsCount || null)
              : null
          }
        }
        case DATASET_STRUCTURE_CODELISTS_FETCH: {

          const dimensionCodelists = (state.dimensions || []).map(({id: dimensionId}) => {
            const found = action.payload.response.criteria.find((criteria: any) => criteria.id === dimensionId);
            return {
              id: dimensionId,
              values: (found?.values || [])
            }
          });

          const flatCodelists = dimensionCodelists ? dimensionCodelists.map(({values}: any) => (values || [])) : [];

          let areCodelistsFlat = flatCodelists.map(() => true);
          flatCodelists.forEach((codelist: any, idx: number) => {
            codelist.forEach((item: any) => {
              if (areCodelistsFlat[idx] && item.parentId !== null && item.parentId !== undefined) {
                areCodelistsFlat[idx] = false;
              }
            })
          })

          const timePeriodIdx = (state.dimensions || []).map(({id}: any) => id).indexOf(TIME_PERIOD_DIMENSION_KEY);
          const freqIdx = (state.dimensions || []).map(({id}: any) => id).indexOf(FREQ_DIMENSION_KEY);

          let timePeriod = null;

          let newCriteria: any = _.cloneDeep(state.criteria);

          dimensionCodelists.forEach((dimensionCodelist, idx) => {

            if (dimensionCodelist.id !== TIME_PERIOD_DIMENSION_KEY) {
              if (flatCodelists[idx].length === 1) {
                newCriteria = {
                  ...newCriteria,
                  [dimensionCodelist.id]: {
                    id: dimensionCodelist.id,
                    type: CRITERIA_FILTER_TYPE_CODES,
                    filterValues: [flatCodelists[idx][0].id],
                  }
                };
              }

            } else if (!newCriteria[TIME_PERIOD_DIMENSION_KEY]) {
              if (
                isValidIntegerInInclusiveRange(action.payload.extra.defaultLastNPeriods, 1) &&
                state.type === CRITERIA_SELECTION_TYPE_PARTIAL
              ) {
                newCriteria = {
                  ...newCriteria,
                  [TIME_PERIOD_DIMENSION_KEY]: {
                    id: TIME_PERIOD_DIMENSION_KEY,
                    type: CRITERIA_FILTER_TYPE_PERIODS,
                    period: action.payload.extra.defaultLastNPeriods,
                  }
                };

              } else {
                timePeriod = getTimePeriod(
                  state.timePeriod,
                  newCriteria,
                  dimensionCodelist,
                  dimensionCodelists[freqIdx],
                  getCurrentNodeConfig(action)
                );

                newCriteria = {
                  ...newCriteria,
                  [TIME_PERIOD_DIMENSION_KEY]: {
                    id: TIME_PERIOD_DIMENSION_KEY,
                    type: CRITERIA_FILTER_TYPE_RANGE,
                    from: timePeriod.fromDate,
                    to: timePeriod.toDate
                  }
                };
              }
            }
          });

          return {
            ...state,
            codelists: flatCodelists.map((flatCodes: any) => getTreeFromArray((flatCodes || []), "parentId", "children")),
            areCodelistsFlat: areCodelistsFlat,
            timePeriod: timePeriod || getTimePeriod(
              state.timePeriod,
              newCriteria,
              dimensionCodelists[timePeriodIdx],
              dimensionCodelists[freqIdx],
              getCurrentNodeConfig(action)
            ),
            codelistsLength: flatCodelists.map((codelist: any, idx: number) => idx !== timePeriodIdx ? codelist.length : null),
            criteria: newCriteria
          }
        }
        case DATASET_GEOMETRIES_FETCH: {
          return {
            ...state,
            geometries: action.payload.response,
            geometryDetailLevels: getGeometryDetailLevels(action.payload.response, true, action.payload.extra.t)
          }
        }
        case DATASET_VIEW_TEMPLATE_GEOMETRIES_FETCH: {
          return {
            ...state,
            templateGeometries: action.payload.response,
            templateGeometryDetailLevels: getGeometryDetailLevels(action.payload.response, true, action.payload.extra.t)
          }
        }
        case DATASET_VIEW_TEMPLATE_SUBMIT: {
          return action.payload.extra.isView
            ? {
              ...state,
              isViewVisible: false,
              isTemplateVisible: false,
              templateGeometries: null,
              templateGeometryDetailLevels: null
            }
            : {
              ...initialState,
              isFetchStructureDisabled: false,
              datasetId: state.datasetId,
              nodeCode: state.nodeCode,
              categoryPath: state.categoryPath,
              viewId: state.viewId
            }
        }
        case DATASET_SDMX_QUERY_FETCH: {
          return {
            ...state,
            structureQuery: action.payload.response?.structureUrl || null,
            dataQuery: action.payload.response?.dataflowUrl || null
          }
        }
        default:
          return state
      }
    }
    case REQUEST_ERROR: {
      switch (action.payload.label) {
        case DATASET_STRUCTURE_FETCH: {
          return {
            ...initialState,
            datasetId: state.datasetId,
            nodeCode: state.nodeCode,
            categoryPath: state.categoryPath,
            viewId: state.viewId,
            enableCriteria: false,
            enableLayout: false
          }
        }
        case DATASET_STRUCTURE_CODELIST_FETCH: {
          const isTooLongQuery = action.payload.statusCode === 414;

          return {
            ...state,
            isTooLongQuery: isTooLongQuery,
            isCriteriaAlertVisible: isTooLongQuery
          }
        }
        case DATASET_STRUCTURE_CODELISTS_FETCH: {
          return {
            ...state
          }
        }
        case DATASET_GEOMETRIES_FETCH: {
          return {
            ...state,
            dataset: null,
            geometries: null,
            geometryDetailLevels: null
          }
        }
        case DATASET_DOWNLOAD_SUBMIT: {
          return {
            ...state,
            isDownloadWarningVisible: action.payload.statusCode === 406
          }
        }
        case DATASET_VIEW_TEMPLATE_SUBMIT: {
          return {
            ...state,
            isViewErrorVisible: !!(action.payload.extra.isView && action.payload.statusCode === 409 && action.payload.response),
            viewErrorMessage: action.payload.response
          }
        }
        case DATASET_SDMX_QUERY_FETCH: {
          return {
            ...state,
            isQueryVisible: false,
            structureQuery: null,
            dataQuery: null
          }
        }
        default:
          return state
      }
    }
    default:
      return state
  }
};

export default datasetReducer;