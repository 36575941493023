import {
  getCoordinatesArrayFromDataIdx,
  getDataIdxFromCoordinatesArray,
  getDimensionAttributeMap,
  getDimensionLabelFromJsonStat,
  getDimensionValueLabelFromJsonStat,
  getDimensionValuesIndexesMap,
  getObservationAttributeMap,
  TIME_PERIOD_DIMENSION_KEY,
  VARIATION_DIMENSION_KEY,
  VARIATION_VALUE_CYCLICAL_KEY,
  VARIATION_VALUE_TREND_KEY,
  VARIATION_VALUE_VALUE_KEY
} from "../../utils/jsonStat";
import {getFormattedValue} from "../../utils/formatters";
import {getCombinationArrays, getNthValorizedElementIndexInBooleanArray} from "../../utils/other";

const TABLE_PREVIEW_PLACEHOLDER = "xxx";
const TABLE_SECTION_DIMENSIONS_SEPARATOR = '<svg height="6px" width="6px" fill="#00295a"><path d="M 0 0 H 6 V 6 H 0 Z"/></svg>';

const TABLE_HEADER_CELL_TEXT_MAX_ROW_COUNT = 2;

const extendArr = (array, n) => {
  let arrays = Array.apply(null, new Array(n / array.length));
  arrays = arrays.map(() => array);
  return [].concat.apply([], arrays);
};

const getRepCountMap = (jsonStat, arr, length) => {
  const obj = {};
  arr.forEach((el, idx) => {
    obj[el] = idx === 0
      ? length / jsonStat.size[jsonStat.id.indexOf(el)]
      : obj[arr[idx - 1]] / jsonStat.size[jsonStat.id.indexOf(el)];
  });

  return obj;
};

const getDimValuesMap = (jsonStat, repCount, arr, length) => {
  const obj = {};

  arr.forEach(dim => {
    if (repCount[dim]) {
      obj[dim] = [];
      jsonStat.dimension[dim].category.index.forEach(value => {
        for (let j = 0; j < repCount[dim]; j++) {
          obj[dim].push(value);
        }
      });
      if (obj[dim].length < length) {
        obj[dim] = extendArr(obj[dim], length);
      }
    }
  });

  return obj;
};

const getValorizedElCount = array => {
  let count = 0;

  array.forEach(el => {
    if (el === true) {
      count++;
    }
  });

  return count;
};

const getJsonStatLayoutObjects = (jsonStat, rows, cols, sections, isPreview) => {

  /** rows handling **/
  let sectionRowCount = 1;
  (rows || []).forEach(row => sectionRowCount *= jsonStat.size[jsonStat.id.indexOf(row)]);

  /** cols handling **/
  let colCount = 1;
  (cols || []).forEach(col => colCount *= jsonStat.size[jsonStat.id.indexOf(col)]);

  /** sections handling **/
  const sectionArray = [];
  (sections || []).forEach(section => sectionArray.push(jsonStat.dimension[section].category.index));
  const sectionDimCombinations = sectionArray.length > 0
    ? getCombinationArrays(sectionArray)
    : [];

  const indexesMap = getDimensionValuesIndexesMap(jsonStat);

  const repCountMap = {
    ...getRepCountMap(jsonStat, rows, sectionRowCount),
    ...getRepCountMap(jsonStat, cols, colCount)
  };

  let dimValuesMap = null;
  if (!isPreview) {
    dimValuesMap = {
      ...getDimValuesMap(jsonStat, repCountMap, rows, sectionRowCount),
      ...getDimValuesMap(jsonStat, repCountMap, cols, colCount)
    };
  }

  return {
    sectionRowCount,
    colCount,
    sectionDimCombinations,
    indexesMap,
    repCountMap,
    dimValuesMap
  };
};

const getCellAttributeIdsElem = (ids, htmlElemId, isDataCell) => {
  let idElems;
  const astElem = ' <span class="ct-a">(*)</span> ';

  if (!ids || ids.length === 0) {
    return "";

  } else {
    if (ids.length === 1 && ids[0].length <= 2) {
      idElems = ` <span class="${ids[0] === "(*)" ? 'ct-a' : 'ct-c'}">${ids[0]}</span> `;
    } else {
      idElems = astElem;
    }
  }

  return `<span id="${htmlElemId}" class="ct ${isDataCell ? 'ctd' : 'ctsh'}">${idElems}</span>`;
};

export const getTableSupportStructures = (jsonStat, layout, isPreview, removeEmptyLines, hiddenAttributes, showTrend, showCyclical) => {

  if (isPreview) {
    return {
      layout,
      isPreview,
      showTrend,
      showCyclical
    };
  }

  const {
    rows,
    cols,
    filtersValue,
    sections
  } = layout;

  const {
    sectionRowCount,
    colCount,
    sectionDimCombinations,
    indexesMap,
    repCountMap,
    dimValuesMap
  } = getJsonStatLayoutObjects(jsonStat, rows, cols, sections, isPreview);

  /** empty rows & cols handling **/

  let valorizedRowsPerSection = (sectionDimCombinations && sectionDimCombinations.length > 0)
    ? sectionDimCombinations.map(() => [])
    : [[]];
  let valorizedCols = [];

  if (removeEmptyLines) {
    valorizedRowsPerSection = valorizedRowsPerSection.map(() => new Array(sectionRowCount).fill(false));
    valorizedCols = new Array(colCount).fill(false);

    const filterValueArray = jsonStat.id.map(dim => {
      if (filtersValue[dim]) {
        return indexesMap[dim][filtersValue[dim]]
      } else {
        return null
      }
    });
    Object.keys(jsonStat.value).forEach(key => {
      if (jsonStat.value[key] !== undefined) {
        const coordinates = getCoordinatesArrayFromDataIdx(key, jsonStat.size);

        let sectionIdx = 0;
        if (sections.length > 0) {
          const sectionValueArray = new Array(sections.length);
          coordinates.forEach((val, idx) => {
            const dim = jsonStat.id[idx];
            if (sections.includes(dim)) {
              sectionValueArray[sections.indexOf(dim)] = jsonStat.dimension[dim].category.index[val];
            }
          });
          sectionIdx = sectionDimCombinations.findIndex(combination => combination.join("+") === sectionValueArray.join("+"));
        }

        if (filterValueArray.filter((elem, idx) => elem !== null && elem !== coordinates[idx]).length === 0) {
          let y = 0;
          rows.forEach(row => {
            const val = coordinates[jsonStat.id.indexOf(row)];
            y += val * repCountMap[row];
          });

          let x = 0;
          cols.forEach(col => {
            const val = coordinates[jsonStat.id.indexOf(col)];
            x += val * repCountMap[col];
          });

          valorizedRowsPerSection[sectionIdx][y] = true;

          if (jsonStat.id.includes(VARIATION_DIMENSION_KEY)) {
            const variationDimValIdx = coordinates[jsonStat.id.indexOf(VARIATION_DIMENSION_KEY)];
            const variationDimVal = jsonStat.dimension[VARIATION_DIMENSION_KEY].category.index[variationDimValIdx];

            valorizedCols[x] = variationDimVal === VARIATION_VALUE_VALUE_KEY ||
              (variationDimVal === VARIATION_VALUE_TREND_KEY && showTrend) ||
              (variationDimVal === VARIATION_VALUE_CYCLICAL_KEY && showCyclical);

          } else {
            valorizedCols[x] = true;
          }
        }
      }
    });

  } else {
    valorizedRowsPerSection = valorizedRowsPerSection.map(() => new Array(sectionRowCount).fill(true));
    valorizedCols = new Array(colCount).fill(true);
  }

  let valorizedRows;
  if (Array.prototype.flat) {
    valorizedRows = valorizedRowsPerSection.flat();
  } else {
    valorizedRows = [];
    valorizedRowsPerSection.forEach(section => valorizedRows = valorizedRows.concat(section));
  }

  /** dimension attributes handling **/
  const dimAttributeMap = getDimensionAttributeMap(
    jsonStat,
    hiddenAttributes,
    (ids, dim, dimValue) => getCellAttributeIdsElem(ids, `${dim},${dimValue}`, false)
  );

  /** observation attributes handling **/
  const obsAttributeMap = getObservationAttributeMap(
    jsonStat,
    hiddenAttributes,
    (ids, obsIdx) => getCellAttributeIdsElem(ids, obsIdx, true)
  );

  return {
    layout,
    colCount: getValorizedElCount(valorizedCols),
    rowCount: getValorizedElCount(valorizedRows),
    repCountMap,
    dimValuesMap,
    sectionDimCombinations,
    indexesMap,
    valorizedCols,
    valorizedRows,
    valorizedRowsPerSection,
    sectionsLength: valorizedRowsPerSection[0].length,
    dimAttributeMap,
    obsAttributeMap,
    isPreview,
    showTrend,
    showCyclical
  };
};

const getTableRightPadding = () => `<td class="c c-rb"/>`;

const getTableHeaderRightPadding = () => `<th class="c c-rb"/>`;

export const getTableHtml = (uuid, labelFormat, fontSize, decimalSeparator, decimalPlaces, emptyChar, paginationParams, tableSupportStructures, onTimeSet, t) => {

  const {
    jsonStat,
    layout,
    colCount,
    repCountMap,
    dimValuesMap,
    sectionDimCombinations,
    indexesMap,
    valorizedCols,
    valorizedRows,
    valorizedRowsPerSection,
    sectionsLength,
    dimAttributeMap,
    obsAttributeMap,
    isPreview,
    showTrend,
    showCyclical
  } = tableSupportStructures;

  const {
    rows,
    cols,
    filtersValue,
    sections
  } = layout;

  const t0 = performance.now();

  /** pagination **/

  let origColStart, origColEnd, rowStart, rowEnd, colStart, colEnd;

  if (!isPreview) {

    origColStart = paginationParams ? paginationParams.colStart : 0;
    origColEnd = paginationParams ? paginationParams.colEnd : colCount;

    rowStart = paginationParams
      ? getNthValorizedElementIndexInBooleanArray(valorizedRows, paginationParams.rowStart)
      : getNthValorizedElementIndexInBooleanArray(valorizedRows, 0);

    rowEnd = paginationParams
      ? getNthValorizedElementIndexInBooleanArray(valorizedRows, paginationParams.rowEnd)
      : getValorizedElCount(valorizedRows);

    colStart = paginationParams
      ? getNthValorizedElementIndexInBooleanArray(valorizedCols, paginationParams.colStart)
      : getNthValorizedElementIndexInBooleanArray(valorizedCols, 0);

    colEnd = paginationParams
      ? getNthValorizedElementIndexInBooleanArray(valorizedCols, paginationParams.colEnd)
      : getValorizedElCount(valorizedCols);

  }

  const getTextWidthEl = window.jQuery("<span/>")
    .css({visibility: 'hidden'})
    .appendTo(`.jsonstat-table__${uuid}`).get(0);

  /** HTML generating **/

  let table = `<table id="${uuid}">`;

  /** table head **/

  table += '<thead>';

  cols
    .filter(col => col !== VARIATION_DIMENSION_KEY || showTrend || showCyclical)
    .forEach((col, idx) => {
      table += `<tr data-row-key="h-${idx}">`;

      const cellText = getDimensionLabelFromJsonStat(jsonStat, col, labelFormat, t);

      window.jQuery(getTextWidthEl).addClass(`c cf${fontSize} ch cl0`);
      window.jQuery(`<span>${cellText}<span/>`).appendTo(getTextWidthEl);
      const minWidth = window.jQuery(getTextWidthEl).innerWidth();
      window.jQuery(getTextWidthEl).removeClass().empty();

      table += `<th class="c cf${fontSize} ch cl0" colspan="${rows.length}" style="min-width: ${(minWidth / TABLE_HEADER_CELL_TEXT_MAX_ROW_COUNT) + 24}px">${cellText}</th>`;

      if (!isPreview) {

        let c = colStart;
        let colVisited = origColStart;
        while (colVisited < origColEnd) {

          let fullColSpan = repCountMap[col] - (c % repCountMap[col]);
          let colSpan = valorizedCols.slice(c, c + fullColSpan).filter(el => el === true).length;

          if (colVisited + colSpan > origColEnd) {
            colSpan = origColEnd - colVisited;
          }

          if (colSpan > 0) {

            const cellText = getDimensionValueLabelFromJsonStat(jsonStat, col, dimValuesMap[col][c], labelFormat, t);
            const htmlString = (dimAttributeMap?.[col]?.[dimValuesMap[col][c]]?.htmlString || "");

            window.jQuery(getTextWidthEl).addClass(`c cf${fontSize} csh ${htmlString.length > 0 ? 'ca' : ''}`);
            window.jQuery(`<span>${cellText + htmlString}<span/>`).appendTo(getTextWidthEl);
            const minWidth = window.jQuery(getTextWidthEl).innerWidth();
            window.jQuery(getTextWidthEl).removeClass().empty();

            table += (
              `<th class="c cf${fontSize} csh ${htmlString.length > 0 ? 'ca' : ''}" colspan="${colSpan}" style="white-space: ${col === TIME_PERIOD_DIMENSION_KEY ? "nowrap" : "normal"}; min-width: ${(minWidth / TABLE_HEADER_CELL_TEXT_MAX_ROW_COUNT) + 24}px">` +
              cellText +
              htmlString +
              `</th>`
            );
          }

          c += fullColSpan;
          colVisited += colSpan;
        }

      } else {
        for (let c = 0; c < 3; c++) {
          table += `<th class="c cf${fontSize} csh" colspan="1">${TABLE_PREVIEW_PLACEHOLDER}</th>`;
        }
      }

      table += getTableHeaderRightPadding();

      table += '</tr>';
    });

  if (rows.length > 0) {
    table += `<tr data-row-key="hh">`;
    rows.forEach((row, idx) => table += `<th class="c cf${fontSize} ch cl${idx}">${getDimensionLabelFromJsonStat(jsonStat, row, labelFormat, t)}</th>`);
    if (!isPreview) {
      table += `<th class="c cf${fontSize} csh" colspan="${origColEnd - origColStart}"/>`;
    } else {
      table += `<th class="c cf${fontSize} csh" colspan="${cols.length > 0 ? 3 : 1}"/>`;
    }
    table += getTableHeaderRightPadding();
    table += '</tr>';
  }

  table += '</thead>';

  /** table body **/

  table += '<tbody id="body">';

  if (!isPreview) {

    const sectionsStarts = [0];
    valorizedRowsPerSection.forEach((valorizedRows, idx) => sectionsStarts.push(sectionsStarts[idx] + valorizedRows.length));

    const subHeaderHandled = {};
    rows.forEach(row => subHeaderHandled[row] = -1);

    let currentSectionIdx = 0;
    for (let i = 0; i < sectionsStarts.length; i++) {
      if (rowStart >= sectionsStarts[i] && (i === sectionsStarts.length - 1 || rowStart < sectionsStarts[i + 1])) {
        currentSectionIdx = i;
      }
    }

    const getSectionRow = currentSectionIdx => {
      if (!valorizedRowsPerSection[currentSectionIdx].find(el => el)) {
        return "";
      }

      let sectionRow = `<tr data-row-key="s-${currentSectionIdx}" class="rs">`;
      let sectionLabel = "";
      const currentSectionIdxClone = currentSectionIdx;
      sections.forEach((section, idx) => {

        const htmlString = (dimAttributeMap?.[section]?.[sectionDimCombinations[currentSectionIdxClone][idx]]?.htmlString || "");

        sectionLabel += (
          `<span class="${htmlString.length > 0 ? 'ca' : ''}" style="display: inline-block;">` +
          `<span class="cs-d">${getDimensionLabelFromJsonStat(jsonStat, section, labelFormat, t)}:</span> ${getDimensionValueLabelFromJsonStat(jsonStat, section, sectionDimCombinations[currentSectionIdxClone][idx], labelFormat, t)}` +
          htmlString +
          '</span>'
        );
        sectionLabel += idx < (sections.length - 1) ? `<span style="display: inline-block; margin: 0 8px">${TABLE_SECTION_DIMENSIONS_SEPARATOR}</span>` : '';
      });

      sectionRow += `<th class="c cf${fontSize} cs" colspan="${origColEnd - origColStart + (rows.length || 1)}">${sectionLabel}</th>`;

      sectionRow += getTableRightPadding();

      sectionRow += '</tr>';

      return sectionRow;
    }

    if (sections && sections.length > 0) {
      table += getSectionRow(currentSectionIdx);
    }

    for (let r = rowStart; r < rowEnd; r++) {

      if (sections && sections.length > 0) {
        if (r > rowStart && sectionsStarts.indexOf(r) !== -1) {
          currentSectionIdx = sectionsStarts.indexOf(r);
          table += getSectionRow(currentSectionIdx);
        }
      }

      if (valorizedRows[r] === true) {

        table += `<tr data-row-key="r-${r}">`;

        let subHeader = "";

        if (rows.length > 0) {
          for (let rr = 0; rr < rows.length; rr++) {

            const htmlString = (dimAttributeMap?.[rows[rr]]?.[dimValuesMap[rows[rr]][r % sectionsLength]]?.htmlString || "");

            if (r > subHeaderHandled[rows[rr]]) {

              let rowSpan = valorizedRows.slice(r, r + (repCountMap[rows[rr]] - (r % repCountMap[rows[rr]]))).filter(el => el === true).length;
              if (r + rowSpan > rowEnd) {
                rowSpan = rowEnd - r;
              }

              subHeaderHandled[rows[rr]] = r + (repCountMap[rows[rr]] - (r % repCountMap[rows[rr]])) - 1;

              if (rowSpan > 0) {
                subHeader += (
                  `<th class="c cf${fontSize} csh cl${rr} ${htmlString.length > 0 ? 'ca' : ''}" rowspan="${rowSpan}" style="white-space: ${rows[rr] === TIME_PERIOD_DIMENSION_KEY ? "nowrap" : "normal"}">` +
                  getDimensionValueLabelFromJsonStat(jsonStat, rows[rr], dimValuesMap[rows[rr]][r % sectionsLength], labelFormat, t) +
                  htmlString +
                  `</th>`
                );
              }
            }
          }

        } else {
          subHeader += `<th class="c cf${fontSize} csh cl0"/>`
        }

        table += subHeader;

        const getDataCell = (c, currentSectionIdx) => {

          const dataObj = {
            ...filtersValue
          };
          rows.forEach(row => dataObj[row] = dimValuesMap[row][r % sectionsLength]);
          cols.forEach(col => dataObj[col] = dimValuesMap[col][c]);
          sections.forEach((section, idx) => dataObj[section] = sectionDimCombinations[currentSectionIdx][idx]);

          const dataIndexArr = jsonStat.id.map(dim => indexesMap[dim][dataObj[dim]]);

          const dataIdx = getDataIdxFromCoordinatesArray(dataIndexArr, jsonStat.size);
          const value = jsonStat.value[dataIdx];

          const htmlString = (obsAttributeMap?.[dataIdx]?.htmlString || "");

          return (
            `<td id="${dataIdx}" class="c cf${fontSize} ${htmlString.length > 0 ? 'ca' : ''}">` +
            htmlString +
            getFormattedValue(value, decimalSeparator, decimalPlaces, emptyChar) +
            `</td>`
          );
        }

        for (let c = colStart; c < colEnd; c++) {
          if (valorizedCols[c] === true) {
            table += getDataCell(c, currentSectionIdx);
          }
        }

        table += getTableRightPadding();

        table += '</tr>';
      }

    }

    table += `<tr><td class="c c-bb" colspan="${rows.length + (origColEnd - origColStart + (rows.length > 0 ? 0 : 1))}"></td></tr>`;

  } else {

    if (sections && sections.length > 0) {
      table += `<tr data-row-key="s-0" class="rs">`;
      let sectionLabel = "";
      sections.forEach((section, idx) => {
        sectionLabel += `<span style="display: inline-block;"><span class="cs-d">${getDimensionLabelFromJsonStat(jsonStat, section, labelFormat, t)}:</span> ${TABLE_PREVIEW_PLACEHOLDER}</span>`;
        sectionLabel += idx < (sections.length - 1) ? `<span style="display: inline-block; margin: 0 8px">${TABLE_SECTION_DIMENSIONS_SEPARATOR}</span>` : '';
      });
      table += `<th class="c cf${fontSize} cs" colspan="${(rows.length || 1) + (cols.length > 0 ? 3 : 1)}">${sectionLabel}</th>`;
      table += getTableRightPadding();
      table += '</tr>';
    }
    for (let r = 0; r < (rows.length > 0 ? 3 : 1); r++) {
      table += `<tr data-row-key="r-${r}">`;
      if (rows.length > 0) {
        for (let rr = 0; rr < rows.length; rr++) {
          table += `<th class="c cf${fontSize} csh cl${rr}">xxx</th>`;
        }
      } else {
        table += `<th class="c cf${fontSize} csh cl0">&nbsp;</th>`
      }
      for (let c = 0; c < (cols.length > 0 ? 3 : 1); c++) {
        table += `<td class="c cf${fontSize}"/>`;
      }
      table += getTableRightPadding();
      table += '</tr>';
    }
    table += `<tr><td class="c c-bb" colspan="${(rows.length || 1) + (cols.length > 0 ? 3 : 1)}"></td></tr>`;
  }

  table += '</tbody>';

  table += '</table>';

  table += '<div id="jsonstat-table__tooltip" class="ctt"/>'

  window.jQuery(getTextWidthEl).remove();

  const t1 = performance.now();
  if (!isPreview) {
    onTimeSet(Math.round((t1 - t0) * 100) / 100);
  }

  return table;
};