import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  DASHBOARD_ELEM_FILTER_DIMENSION_KEY,
  DASHBOARD_ELEM_TYPE_KEY,
  DASHBOARD_ELEM_TYPE_VALUE_VIEW,
  getViewIdxFromRowAndCol
} from "../../utils/dashboards";
import DashboardCol from "./DashboardCol";

const styles = theme => ({
  row: {
    width: "100%"
  }
});

const DashboardRow = ({
                        classes,
                        dashboardId,
                        dashboard,
                        filterValue,
                        row,
                        rowIdx,
                        jsonStats,
                        layouts,
                        filterTrees,
                        timePeriodsByFreq,
                        onSelect,
                        maps,
                        fetchMapGeometries,
                        onDownloadSubmit
                      }) => {
  const rowWithView = row.find(dashboardElem => dashboardElem[DASHBOARD_ELEM_TYPE_KEY] === DASHBOARD_ELEM_TYPE_VALUE_VIEW) || false;

  return (
    <div className={`${classes.row} dashboard__row`}>
      {row.map((col, idx) => {
        const viewIdx = getViewIdxFromRowAndCol(rowIdx, idx);
        return (
          <DashboardCol
            key={idx}
            dashboardId={dashboardId}
            dashboard={dashboard}
            filterValue={col[DASHBOARD_ELEM_FILTER_DIMENSION_KEY] ? filterValue : null}
            rowIdx={rowIdx}
            colIdx={idx}
            dashboardElem={col}
            jsonStat={jsonStats?.[viewIdx]}
            layout={layouts?.[viewIdx]}
            filterTree={filterTrees?.[viewIdx]}
            timePeriodsByFreq={timePeriodsByFreq?.[viewIdx]}
            map={maps?.[viewIdx]}
            rowWithView={rowWithView}
            onSelect={onSelect}
            fetchMapGeometries={fetchMapGeometries}
            onDownloadSubmit={onDownloadSubmit}
          />
        )
      })}
    </div>
  )
};


export default withStyles(styles)(DashboardRow);