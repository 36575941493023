import {initRequest, RequestMethod} from "../../middlewares/request/requestActions";
import {
  getCreateTemplateUrl,
  getCreateViewUrl,
  getDatasetDownloadCustomUrl,
  getDatasetDownloadUrl,
  getDatasetSdmxQueryUrl,
  getDatasetStructureCodelistsUrl,
  getDatasetStructureCodelistUrl,
  getDatasetStructureDynamicCodelistUrl,
  getDatasetStructureUrl,
  getDatasetUrl,
  getGeometryUrl
} from "../../serverApi/urls";
import {getCriteriaArrayFromObject} from "../../utils/criteria";
import {DOWNLOAD_FORMAT_CSV, DOWNLOAD_FORMAT_EXCEL, downloadFormats, getCustomExportRequestBody} from "../../utils/download";

export const DATASET_STRUCTURE_FETCH_ENABLE = "dataset/structure/fetch/enable";
export const DATASET_STRUCTURE_FETCH = "dataset/structure/fetch";
export const DATASET_FETCH_ENABLE = "dataset/fetch/enable";
export const DATASET_FETCH = "dataset/fetch";
export const DATASET_CRITERIA_SHOW = "dataset/criteria/show";
export const DATASET_CRITERIA_HIDE = "dataset/criteria/hide";
export const DATASET_CRITERIA_ALERT_HIDE = "dataset/criteria/alert/hide";
export const DATASET_STRUCTURE_CODELIST_FETCH = "dataset/structure/codelist/fetch";
export const DATASET_STRUCTURE_CODELISTS_FETCH = "dataset/structure/codelists/fetch";
export const DATASET_STRUCTURE_CODELIST_CRITERIA_SET = "dataset/structure/codelist/criteria/set";
export const DATASET_STRUCTURE_CODELIST_TIME_PERIOD_SET = "dataset/structure/codelist/timePeriod/set";

export const DATASET_STATE_BACKUP = "dataset/state/backup";
export const DATASET_STATE_RESET = "dataset/state/reset";

export const DATASET_VIEWER_SET = "dataset/viewer/set";

export const DATASET_TABLE_LAYOUT_SUBMIT = "dataset/table/layout/submit";
export const DATASET_TABLE_FILTERS_TREE_SUBMIT = "dataset/table/filters/tree/submit";
export const DATASET_MAP_LAYOUT_SUBMIT = "dataset/map/layout/submit";
export const DATASET_CHART_LAYOUT_SUBMIT = "dataset/chart/layout/submit";
export const DATASET_CHART_FILTERS_TREE_SUBMIT = "dataset/chart/filters/tree/submit";

export const DATASET_GEOMETRIES_FETCH = "dataset/geometries/fetch";

export const DATASET_LABEL_FORMAT_SET = "dataset/label/format/set";

export const DATASET_VARIATION_SET = "dataset/variation/set";

export const DATASET_CHART_SETTINGS_SET = "dataset/chart/settings/set";

export const DATASET_MAP_DETAIL_LEVEL_SET = "dataset/map/detail/level/set";
export const DATASET_MAP_SETTINGS_SET = "dataset/map/settings/set";

export const DATASET_DOWNLOAD_SUBMIT = "dataset/download/submit";
export const DATASET_DOWNLOAD_WARNING_HIDE = "dataset/download/warning/hide";

export const DATASET_VIEW_TEMPLATE_SHOW = "dataset/view/template/show";
export const DATASET_VIEW_TEMPLATE_HIDE = "dataset/view/template/hide";
export const DATASET_VIEW_TEMPLATE_SUBMIT = "dataset/view/template/submit";
export const DATASET_VIEW_ERROR_HIDE = "dataset/view/error/hide";
export const DATASET_VIEW_TEMPLATE_GEOMETRIES_FETCH = "dataset/view/template/geometries/fetch";

export const DATASET_UNAVAILABLE_VIEW_WARNING_HIDE = "dataset/unavailable/view/warning/hide";

export const DATASET_HTML_GENERATING_TIME_SET = "dataset/html/generating/time/set";

export const DATASET_SDMX_QUERY_SHOW = "dataset/sdmx/query/show";
export const DATASET_SDMX_QUERY_HIDE = "dataset/sdmx/query/hide";
export const DATASET_SDMX_QUERY_FETCH = "dataset/sdmx/query/fetch";

export const ALL_FULL = "FullAll";
export const ALL_PARTIAL = "PartialAll";
export const STEP_BY_STEP_FULL = "FullStep";
export const STEP_BY_STEP_PARTIAL = "PartialStep";
export const STEP_BY_STEP_DYNAMIC = "Dynamic";

export const CRITERIA_SELECTION_MODE_STEP_BY_STEP = "stepByStep";
export const CRITERIA_SELECTION_MODE_ALL = "all";

export const CRITERIA_SELECTION_TYPE_FULL = "full";
export const CRITERIA_SELECTION_TYPE_PARTIAL = "partial";
export const CRITERIA_SELECTION_TYPE_DYNAMIC = "dynamic";

export const enableDatasetStructureFetch = (nodeCode: string, categoryPath: string[], datasetId: string, viewId: string) => ({
  type: DATASET_STRUCTURE_FETCH_ENABLE,
  payload: {
    datasetId: datasetId,
    nodeCode: nodeCode,
    categoryPath: categoryPath,
    viewId: viewId
  }
});

export const fetchDatasetStructure = (nodeId: number, datasetId: string, viewId: string, defaultLanguage: string, languages: string[]) => initRequest(
  DATASET_STRUCTURE_FETCH,
  getDatasetStructureUrl(nodeId, datasetId, viewId),
  RequestMethod.GET,
  undefined,
  t => ({
    onStart: t("scenes.dashboard.actions.fetchingDatasetStructure")
  }),
  {
    viewId: viewId,
    defaultLanguage: defaultLanguage,
    languages: languages
  }
);

export const enableDatasetFetch = () => ({
  type: DATASET_FETCH_ENABLE
});

export const fetchDataset = (nodeId: number, datasetId: string, criteria: any, datasetTitle: string) =>
  initRequest(
    DATASET_FETCH,
    getDatasetUrl(nodeId, datasetId),
    RequestMethod.POST,
    getCriteriaArrayFromObject(criteria),
    t => ({
      onStart: t("scenes.dataset.actions.fetchingDataset", {title: datasetTitle})
    }),
    undefined,
    undefined,
    (statusCode: number) => (statusCode === 413 || statusCode === 414)
  );

export const showDatasetCriteria = () => ({
  type: DATASET_CRITERIA_SHOW
});

export const hideDatasetCriteria = () => ({
  type: DATASET_CRITERIA_HIDE
});

export const hideDatasetCriteriaAlert = () => ({
  type: DATASET_CRITERIA_ALERT_HIDE
});

export const fetchDatasetStructureCodelist = (nodeId: number, datasetId: string, dimensionId: string, type: string, criteria: any, defaultLastNPeriods: number) => {

  let flatCriteria = getCriteriaArrayFromObject(criteria);
  // @ts-ignore
  flatCriteria = flatCriteria.filter(({id}) => id !== dimensionId)

  return type === CRITERIA_SELECTION_TYPE_DYNAMIC
    ? (
      initRequest(
        DATASET_STRUCTURE_CODELIST_FETCH,
        getDatasetStructureDynamicCodelistUrl(nodeId, datasetId, dimensionId),
        RequestMethod.POST,
        flatCriteria,
        t => ({
          onStart: t("scenes.dataset.actions.fetchingCodelist")
        }),
        {
          defaultLastNPeriods: defaultLastNPeriods
        },
        undefined,
        (statusCode: number) => statusCode === 414
      )
    )
    : (
      initRequest(
        DATASET_STRUCTURE_CODELIST_FETCH,
        getDatasetStructureCodelistUrl(nodeId, datasetId, dimensionId, type === CRITERIA_SELECTION_TYPE_FULL),
        undefined,
        undefined,
        t => ({
          onStart: t("scenes.dataset.actions.fetchingCodelist")
        }),
        {
          defaultLastNPeriods: defaultLastNPeriods
        }
      )
    );
};

export const fetchDatasetStructureCodelists = (nodeId: number, datasetId: string, type: string, defaultLastNPeriods: number) => initRequest(
  DATASET_STRUCTURE_CODELISTS_FETCH,
  getDatasetStructureCodelistsUrl(nodeId, datasetId, type === CRITERIA_SELECTION_TYPE_FULL),
  undefined,
  undefined,
  t => ({
    onStart: t("scenes.dataset.actions.fetchingCodelists")
  }),
  {
    defaultLastNPeriods: defaultLastNPeriods
  }
);

export const setDatasetStructureCodelistCriteria = (criteria: any) => ({
  type: DATASET_STRUCTURE_CODELIST_CRITERIA_SET,
  criteria
});

export const setDatasetStructureCodelistTimePeriod = (timePeriod: any) => ({
  type: DATASET_STRUCTURE_CODELIST_TIME_PERIOD_SET,
  timePeriod
});

export const backupDatasetState = () => ({
  type: DATASET_STATE_BACKUP
});

export const resetDatasetState = () => ({
  type: DATASET_STATE_RESET
});

export const setDatasetViewer = (viewerIdx: number) => ({
  type: DATASET_VIEWER_SET,
  viewerIdx
});

export const submitDatasetTableLayout = (layout: any) => ({
  type: DATASET_TABLE_LAYOUT_SUBMIT,
  layout
});

export const submitDatasetTableFilterTree = (filterTree: any) => ({
  type: DATASET_TABLE_FILTERS_TREE_SUBMIT,
  filterTree
});

export const submitDatasetMapLayout = (layout: any) => ({
  type: DATASET_MAP_LAYOUT_SUBMIT,
  layout
});

export const submitDatasetChartLayout = (layout: any) => ({
  type: DATASET_CHART_LAYOUT_SUBMIT,
  layout
});

export const submitDatasetChartFilterTree = (filterTree: any) => ({
  type: DATASET_CHART_FILTERS_TREE_SUBMIT,
  filterTree
});

export const fetchDatasetGeometries = (idList: string[], t: any, nodeId: number) => initRequest(
  DATASET_GEOMETRIES_FETCH,
  getGeometryUrl(nodeId),
  RequestMethod.POST,
  idList,
  t => ({
    onStart: t(`scenes.dataset.actions.fetchingGeometries`)
  }),
  {
    t
  }
);

export const setDatasetLabelFormat = (labelFormat: string) => ({
  type: DATASET_LABEL_FORMAT_SET,
  labelFormat
});

export const setDatasetVariation = (variation: { [key: string]: boolean }) => ({
  type: DATASET_VARIATION_SET,
  variation
});

export const setDatasetChartSettings = (chartSettings: any) => ({
  type: DATASET_CHART_SETTINGS_SET,
  chartSettings
});

export const setDatasetMapDetailLevel = (detailLevel: number) => ({
  type: DATASET_MAP_DETAIL_LEVEL_SET,
  detailLevel
});

export const setDatasetMapSettings = (mapSettings: any) => ({
  type: DATASET_MAP_SETTINGS_SET,
  mapSettings
});

export const submitDatasetDownload = (
  nodeId: number,
  datasetId: string,
  datasetTitle: string,
  format: string,
  extension: string,
  zipped: boolean,
  criteria: any,
  layout: any,
  params: any,
  t: any
) => {
  let url, body, type;

  if (format === DOWNLOAD_FORMAT_EXCEL || format === DOWNLOAD_FORMAT_CSV) {
    url = getDatasetDownloadCustomUrl(nodeId, format, zipped);
    body = getCustomExportRequestBody(datasetId, datasetTitle, criteria, layout, params, t);

  } else {
    // @ts-ignore
    const formatParam = downloadFormats()[format]?.param || format;
    url = getDatasetDownloadUrl(nodeId, datasetId, formatParam, zipped);
    body = getCriteriaArrayFromObject(criteria);
  }

  type = format === DOWNLOAD_FORMAT_EXCEL
    ? "application/vnd.ms-excel"
    : "text/plain;charset=utf-8";

  return initRequest(
    DATASET_DOWNLOAD_SUBMIT,
    url,
    RequestMethod.POST,
    body,
    t => ({
      onStart: t("scenes.dataset.actions.downloadingDataset")
    }),
    {
      fileSave: {
        name: `${datasetTitle} (${datasetId})`,
        extension: extension,
        type: type
      }
    },
    undefined,
    (statusCode: number) => statusCode === 406,
    undefined,
    undefined,
    true
  );
};

export const hideDatasetDownloadWarning = () => ({
  type: DATASET_DOWNLOAD_WARNING_HIDE
});

export const showDatasetViewTemplate = (isView: boolean) => ({
  type: DATASET_VIEW_TEMPLATE_SHOW,
  isView
});

export const hideDatasetViewTemplate = (isView: boolean) => ({
  type: DATASET_VIEW_TEMPLATE_HIDE,
  isView
});

export const submitDatasetViewTemplate = (nodeId: number, viewTemplate: any, isView: boolean) => initRequest(
  DATASET_VIEW_TEMPLATE_SUBMIT,
  isView
    ? getCreateViewUrl(nodeId)
    : getCreateTemplateUrl(nodeId),
  RequestMethod.POST,
  viewTemplate,
  t => ({
    onStart: isView
      ? t("scenes.dataset.actions.savingView")
      : t("scenes.dataset.actions.savingTemplate")
  }),
  {
    isView: isView
  },
  undefined,
  (statusCode: number) => statusCode === 409,
);

export const hideDatasetViewError = () => ({
  type: DATASET_VIEW_ERROR_HIDE
});

export const fetchDatasetViewTemplateGeometries = (idList: string[], t: any, nodeId: number) => initRequest(
  DATASET_VIEW_TEMPLATE_GEOMETRIES_FETCH,
  getGeometryUrl(nodeId),
  RequestMethod.POST,
  idList,
  t => ({
    onStart: t(`scenes.dataset.actions.fetchingGeometries`)
  }),
  {
    t
  }
);

export const hideDatasetUnavailableViewWarning = () => ({
  type: DATASET_UNAVAILABLE_VIEW_WARNING_HIDE
});

export const setDatasetHtmlGeneratingTime = (time: number) => ({
  type: DATASET_HTML_GENERATING_TIME_SET,
  time
});

export const showDatasetSdmxQuery = () => ({
  type: DATASET_SDMX_QUERY_SHOW
});

export const hideDatasetSdmxQuery = () => ({
  type: DATASET_SDMX_QUERY_HIDE
});

export const fetchDatasetSdmxQuery = (nodeId: number, datasetId: string, criteria: any, datasetTitle: string) => initRequest(
  DATASET_SDMX_QUERY_FETCH,
  getDatasetSdmxQueryUrl(nodeId, datasetId),
  RequestMethod.POST,
  getCriteriaArrayFromObject(criteria),
  t => ({
    onStart: t("scenes.dataset.actions.fetchingDatasetSdmxQuery", {title: datasetTitle})
  })
);