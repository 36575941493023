import {CancelTokenSource} from "axios";

export const PENDING_REQUEST_ADD = "request/add";
export const PENDING_REQUESTS_CANCEL = "requests/cancel";
export const PENDING_REQUESTS_CLEAR = "requests/clear";

export const addPendingRequest = (requestUuid: string, requestLabel: string, cancelTokenSource: CancelTokenSource) => ({
  type: PENDING_REQUEST_ADD,
  payload: {
    requestUuid,
    requestLabel,
    cancelTokenSource
  }
});

export const cancelPendingRequests = (requestUuids: string[]) => ({
  type: PENDING_REQUESTS_CANCEL,
  payload: {
    requestUuids
  }
});

export const clearPendingRequests = () => ({
  type: PENDING_REQUESTS_CLEAR
});