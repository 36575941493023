import React, {Fragment, useRef, useState} from 'react';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import Button from "@material-ui/core/Button";
import {connect} from "react-redux";
import {
  clearUser,
  fetchUser,
  hideUserChangePasswordForm,
  hideUserEditUserModal,
  hideUserLoginForm,
  hideUserRegisterModal,
  showUserChangePasswordForm,
  showUserEditUserModal,
  showUserLoginForm,
  showUserRegisterModal
} from "../../state/user/userActions";
import UserLoginDialog from "../user-login-dialog";
import {useTranslation} from "react-i18next";
import UserSettingsForm from "../settings-select/users-settings-form/user-settings-form";
import SettingsDialog from "../settings-dialog";
import Tooltip from "@material-ui/core/Tooltip";
import ButtonSelect from "../button-select";
import {withStyles} from "@material-ui/core";
import {compose} from "redux";
import UserChangePasswordForm from "./user-change-password-form";
import EditUserForm from "./edit-user-form";
import {canDisplayUserViews, canManagePersonalDashboards} from "../../utils/user";
import UserViewsSettingsForm from "../settings-select/user-views-settings-form";
import UserDashboardsSettingsForm from "../settings-select/user-dashboards-settings-form";

const mapStateToProps = state => ({
  user: state.user
});

const mapDispatchToProps = dispatch => ({
  onLogin: (email, password) => dispatch(fetchUser(email, password)),
  onLogout: () => dispatch(clearUser()),
  onLoginFormShow: () => dispatch(showUserLoginForm()),
  onLoginFormHide: () => dispatch(hideUserLoginForm()),
  onChangePasswordShow: () => dispatch(showUserChangePasswordForm()),
  onChangePasswordHide: () => dispatch(hideUserChangePasswordForm()),
  onRegisterModalShow: () => dispatch(showUserRegisterModal()),
  onRegisterModalHide: () => dispatch(hideUserRegisterModal()),
  onEditModalShow: () => dispatch(showUserEditUserModal()),
  onEditModalHide: () => dispatch(hideUserEditUserModal())
});

const styles = () => ({
  email: {
    cursor: "initial",
    color: "gray"
  },
  divider: {
    width: "100%",
    color: "gray",
    fontStyle: "italic",
    fontSize: 14
  },
});

const UserSelect = ({
                      classes,
                      user,
                      onLogin,
                      onLogout,
                      onLoginFormShow,
                      onLoginFormHide,
                      onChangePasswordShow,
                      onChangePasswordHide,
                      onRegisterModalShow,
                      onRegisterModalHide,
                      onEditModalShow,
                      onEditModalHide,
                      nodes,
                      disabled
                    }) => {

  const {t} = useTranslation();

  const registerFormRef = useRef();
  const changePasswordFormRef = useRef();
  const editUserFormRef = useRef();
  const viewsSettigsRef = useRef();
  const dashboardsSettingsRef = useRef();

  const [isViewsOpen, setIsViewsOpen] = useState(false);
  const [isDashboardsOpen, setIsDashboardsOpen] = useState(false);

  const onDashboardsOpen = () => {
    setIsDashboardsOpen(true);
  };

  const onDashboardsClose = () => {
    setIsDashboardsOpen(false);
  };

  const onViewsOpen = () => {
    setIsViewsOpen(true);
  };

  const onViewsClose = () => {
    setIsViewsOpen(false);
  };

  const unselectableIndexes = [0];
  let count = 0;
  if (canDisplayUserViews(user)) {
    count++;
  }
  if (canManagePersonalDashboards(user)) {
    count++;
  }
  if (count === 1) {
    unselectableIndexes.push(1);
    unselectableIndexes.push(3);
  } else if (count === 2) {
    unselectableIndexes.push(1);
    unselectableIndexes.push(4);
  }

  return (
    <Fragment>
      {user.isAuthenticated
        ? (
          <Tooltip title={user.email}>
            <ButtonSelect
              value=""
              icon={<AccountCircleIcon/>}
              ariaLabel={t("components.header.actions.user.ariaLabel")}
              tooltip={t("components.header.actions.user.title")}
              unselectableIndexes={unselectableIndexes}
            >
              <div className={classes.email}>
                {user.email}
              </div>
              {(canDisplayUserViews(user) || canManagePersonalDashboards(user)) && (
                <div className={classes.divider}>
                  {t("components.userSelect.userObjects")}
                </div>)}
              {canDisplayUserViews(user) && (
                <div onClick={onViewsOpen} className={classes.option}>
                  {t("components.userSelect.views")}
                </div>
              )}
              {canManagePersonalDashboards(user) && (
                <div onClick={onDashboardsOpen} className={classes.option}>
                  {t("components.userSelect.dashboards")}
                </div>
              )}
              <div className={classes.divider}>
                {t("components.userSelect.accountSettings")}
              </div>
              <div onClick={() => onChangePasswordShow()}>
                {t("components.userSelect.changePassword")}
              </div>
              <div onClick={() => onEditModalShow()}>
                {t("components.userSelect.editUser")}
              </div>
              <div onClick={onLogout}>
                {t("components.userSelect.logout")}
              </div>
            </ButtonSelect>
          </Tooltip>
        )
        : (
          <Button color="inherit" onClick={onLoginFormShow}>
            {t("components.userSelect.login")}
          </Button>
        )
      }

      <UserLoginDialog
        open={user.isLoginDialogOpen && !disabled}
        onSubmit={({email, password}) => onLogin(email, password)}
        onRegister={() => onRegisterModalShow()}
        onHide={onLoginFormHide}
        alert={user.message}
      />

      <SettingsDialog
        title={t("components.userSelect.registerModal.title")}
        open={user.isRegisterDialogOpen && !disabled}
        onClose={() => {
          if (registerFormRef.current) {
            registerFormRef.current.cancel(() => {
              onRegisterModalHide();
            });
          } else {
            onRegisterModalHide();
          }
        }}
        onSubmit={() => {
          if (registerFormRef.current) {
            registerFormRef.current.submit(() => {
            });
          }
        }}
        hasSubmit
        customSubmitLabel={t("components.userSelect.registerModal.submit.label")}
      >
        <UserSettingsForm ref={registerFormRef} userId={-1} isAnonymous/>
      </SettingsDialog>

      <SettingsDialog
        title={t("components.userSelect.editUserModal.title")}
        open={user.isEditUserDialogOpen && !disabled}
        onClose={() => {
          if (editUserFormRef.current) {
            editUserFormRef.current.cancel(() => {
              onEditModalHide();
            });
          } else {
            onEditModalHide();
          }
        }}
        onSubmit={() => {
          if (editUserFormRef.current) {
            editUserFormRef.current.submit(() => {
            });
          }
        }}
        hasSubmit
        noMinHeight
        noFullScreen
      >
        <EditUserForm ref={editUserFormRef}/>
      </SettingsDialog>

      <SettingsDialog
        title={t("components.userSelect.changePasswordModal.title")}
        maxWidth={"xs"}
        open={user.isChangePasswordDialogOpen && !disabled}
        onClose={() => {
          if (changePasswordFormRef.current) {
            changePasswordFormRef.current.cancel(() => {
              onChangePasswordHide();
            });
          } else {
            onChangePasswordHide();
          }
        }}
        onSubmit={() => {
          if (changePasswordFormRef.current) {
            changePasswordFormRef.current.submit(() => {
            });
          }
        }}
        hasSubmit
        noMinHeight
        noFullScreen
      >
        <UserChangePasswordForm ref={changePasswordFormRef}/>
      </SettingsDialog>

      <SettingsDialog
        title={t("scenes.viewsSettings.title")}
        open={isViewsOpen}
        onClose={() => {
          if (viewsSettigsRef.current) {
            viewsSettigsRef.current.cancel(() => {
              onViewsClose();
            });
          } else {
            onViewsClose();
          }
        }}
      >
        <UserViewsSettingsForm
          ref={viewsSettigsRef}
          nodes={nodes}
          onViewsClose={onViewsClose}
        />
      </SettingsDialog>

      <SettingsDialog
        title={t("scenes.dashboardsSettings.title")}
        open={isDashboardsOpen}
        onClose={() => {
          if (dashboardsSettingsRef.current) {
            dashboardsSettingsRef.current.cancel(() => {
              onDashboardsClose();
            });
          } else {
            onDashboardsClose();
          }
        }}
      >
        <UserDashboardsSettingsForm
          ref={dashboardsSettingsRef}
          nodes={nodes}
          onDashboardsClose={onDashboardsClose}
        />
      </SettingsDialog>

    </Fragment>
  );
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(UserSelect);