import {initRequest, RequestMethod} from "../../middlewares/request/requestActions";
import {getDashboardsUrl, getDatasetDownloadCustomUrl, getDatasetDownloadUrl, getDatasetUrl, getGeometryUrl} from "../../serverApi/urls";
import {getCriteriaArrayFromObject} from "../../utils/criteria";
import {DOWNLOAD_FORMAT_CSV, DOWNLOAD_FORMAT_EXCEL, downloadFormats, getCustomExportRequestBody} from "../../utils/download";

export const DASHBOARDS_DASHBOARD_FETCH = "dashboards/dashboard/fetch";
export const DASHBOARDS_DASHBOARDS_CLEAR = "dashboards/dashboards/clear";
export const DASHBOARDS_DATASET_FETCH = "dashboards/dataset/fetch";
export const DASHBOARDS_DATASET_FILTER_SET = "dashboards/dataset/filter/set";
export const DASHBOARDS_DATASET_FILTER_APPLY = "dashboards/dataset/filter/apply";
export const DASHBOARDS_MAP_GEOMETRIES_FETCH = "dashboards/map/geometries/fetch";
export const DASHBOARDS_DATASET_DOWNLOAD_SUBMIT = "dashboards/dataset/download/submit";

export const fetchDashboardsDashboard = (dashboardId: number) => initRequest(
  DASHBOARDS_DASHBOARD_FETCH,
  getDashboardsUrl(dashboardId),
  RequestMethod.GET,
  undefined,
  t => ({
    onStart: t("scenes.dashboard.actions.fetchingDashboard")
  }),
  {
    dashboardId: dashboardId
  }
);

export const clearDashboardsDashboards = () => ({
  type: DASHBOARDS_DASHBOARDS_CLEAR
});

export const fetchDashboardsDataset = (dashboardId: number, isFetchingDynamicView: boolean, nodeId: number, datasetId: string, criteria: any, requestIds: string[], worker: any) => initRequest(
  DASHBOARDS_DATASET_FETCH,
  getDatasetUrl(nodeId, datasetId),
  RequestMethod.POST,
  getCriteriaArrayFromObject(criteria),
  t => ({
    onStart: t("scenes.dashboard.actions.fetchingDataset")
  }),
  {
    dashboardId: dashboardId,
    isFetchingDynamicView: isFetchingDynamicView,
    requestIds: requestIds,
    criteria: criteria,
    worker: worker
  },
  "",
  () => true,
  true
);

export const setDashboardsDatasetFilter = (dashboardId: number, viewIdx: string, dimension: string, value: string) => ({
  type: DASHBOARDS_DATASET_FILTER_SET,
  payload: {
    dashboardId,
    viewIdx,
    dimension,
    value
  }
});

export const applyDashboardsDatasetFilter = () => ({
  type: DASHBOARDS_DATASET_FILTER_APPLY
});

export const fetchDashboardsMapGeometries = (dashboardId: number, viewIdx: string, idList: string[], t: any, nodeId: number) => initRequest(
  DASHBOARDS_MAP_GEOMETRIES_FETCH,
  getGeometryUrl(nodeId),
  RequestMethod.POST,
  idList,
  t => ({
    onStart: t(`scenes.dashboard.actions.fetchingGeometries`)
  }),
  {
    dashboardId: dashboardId,
    viewIdx: viewIdx,
    t: t
  },
  "",
  () => true,
  true
);

export const submitDashboardDatasetDownload = (
  nodeId: number,
  datasetId: string,
  datasetTitle: string,
  format: string,
  extension: string,
  zipped: boolean,
  criteria: any,
  layout: any,
  params: any,
  t: any
) => {
  let url, body, type;

  if (format === DOWNLOAD_FORMAT_EXCEL || format === DOWNLOAD_FORMAT_CSV) {
    url = getDatasetDownloadCustomUrl(nodeId, format, zipped);
    body = getCustomExportRequestBody(datasetId, datasetTitle, criteria, layout, params, t);

  } else {
    // @ts-ignore
    const formatParam = downloadFormats()[format]?.param || format;
    url = getDatasetDownloadUrl(nodeId, datasetId, formatParam, zipped);
    body = getCriteriaArrayFromObject(criteria);
  }

  type = format === DOWNLOAD_FORMAT_EXCEL
    ? "application/vnd.ms-excel"
    : "text/plain;charset=utf-8";

  return initRequest(
    DASHBOARDS_DATASET_DOWNLOAD_SUBMIT,
    url,
    RequestMethod.POST,
    body,
    t => ({
      onStart: t("scenes.dataset.actions.downloadingDataset")
    }),
    {
      fileSave: {
        name: `${datasetTitle} (${datasetId})`,
        extension: extension,
        type: type
      }
    },
    undefined,
    (statusCode: number) => statusCode === 406,
    undefined,
    undefined,
    true
  )
};