import React, {Fragment} from "react";
import {compose} from "redux";
import {Draggable, Droppable} from "react-beautiful-dnd";
import withStyles from "@material-ui/core/styles/withStyles";
import CardHeader from "@material-ui/core/CardHeader";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import Tooltip from "@material-ui/core/Tooltip";
import Grid from "@material-ui/core/Grid";

const styles = theme => ({
  root: {
    height: "100%",
    width: "100%",
    background: "#eeeeee"
  },
  sectionHeaderContainer: {
    height: 48,
    padding: "16px 16px 0"
  },
  sectionHeaderContent: {
    width: "100%"
  },
  sectionHeaderIcon: {
    width: 24,
    marginRight: 8,
    display: "inline-block",
    verticalAlign: "middle"
  },
  sectionHeaderTitle: {
    width: `calc(100% - 32px)`,
    height: 30,
    display: "inline-block",
    verticalAlign: "middle",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden"
  },
  sectionContentWrapper: {
    width: "100%",
    height: "calc(100% - 48px)",
    padding: 16
  },
  draggingHover: {
    border: "1px dashed #999999"
  },
  sectionContent: {
    width: "100%",
    height: "100%",
    overflowY: "auto",
    overflowX: "hidden"
  },
  sectionItem: {
    padding: "2px 16px"
  },
  dimension: {
    margin: 0
  },
  dimensionLabel: {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden"
  }
});

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: 'none',
  marginBottom: 8,
  ...draggableStyle
});

const LayoutSection = ({
                         classes,
                         id,
                         title,
                         Icon,
                         dimensions,
                         jsonStat,
                         disabled
                       }) =>

  <Card variant="outlined" className={classes.root}>
    <CardHeader
      className={classes.sectionHeaderContainer}
      title={
        <Tooltip title={title}>
          <Fragment>
            <span className={classes.sectionHeaderIcon}>{Icon}</span>
            <Typography className={classes.sectionHeaderTitle} variant="button">{title}</Typography>
          </Fragment>
        </Tooltip>
      }
      classes={{
        content: classes.sectionHeaderContent
      }}
    />
    <Droppable droppableId={id}>
      {(provided, snapshot) => (
        <div className={`${classes.sectionContentWrapper} ${snapshot.isDraggingOver ? classes.draggingHover : ""}`}>
          <div ref={provided.innerRef} className={classes.sectionContent}>
            {dimensions.map((item, idx) =>
              <Draggable
                key={idx}
                index={idx}
                draggableId={`${id}-${idx}`}
                isDragDisabled={disabled}
              >
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                  >
                    <Tooltip title={jsonStat.dimension[item].label ? `[${item}] ${jsonStat.dimension[item].label}` : item}>
                      <Paper className={classes.sectionItem}>
                        <Grid container spacing={1} justify="center" wrap="nowrap" className={classes.dimension}>
                          <Grid item className={classes.dimensionLabel}>
                            {jsonStat.dimension[item].label || item}
                          </Grid>
                          <Grid item>
                            {`(${jsonStat.size[jsonStat.id.indexOf(item)]})`}
                          </Grid>
                        </Grid>
                      </Paper>
                    </Tooltip>
                  </div>
                )}
              </Draggable>
            )}
            {provided.placeholder}
          </div>
        </div>
      )}
    </Droppable>
  </Card>;

export default compose(
  withStyles(styles)
)(LayoutSection);