import html2canvas from "html2canvas";
import {getDimensionLabelFromJsonStat, getDimensionValueLabelFromJsonStat, VARIATION_DIMENSION_KEY} from "./jsonStat";
import {getCriteriaArrayFromObject} from "./criteria";

const $ = window.jQuery;

export const DOWNLOAD_VIEWER_TABLE = "table";
export const DOWNLOAD_VIEWER_CHART = "chart";
export const DOWNLOAD_VIEWER_MAP = "map";

export const DOWNLOAD_FORMAT_IMAGE = "jpeg";
export const DOWNLOAD_FORMAT_EXCEL = "excel";
export const DOWNLOAD_FORMAT_CSV = "csv";

export const downloadFormats = t => ({
  "genericdata": {
    label: t ? t("commons.downloadFormat.genericdata") : "genericdata",
    extension: "xml"
  },
  "genericdata20": {
    label: t ? t("commons.downloadFormat.genericdata20") : "genericdata20",
    extension: "xml"
  },
  "compactdata": {
    label: t ? t("commons.downloadFormat.compactdata") : "compactdata",
    extension: "xml"
  },
  "structurespecificdata": {
    label: t ? t("commons.downloadFormat.structurespecificdata") : "structurespecificdata",
    extension: "xml"
  },
  "structure": {
    label: t ? t("commons.downloadFormat.structure") : "structure",
    extension: "xml"
  },
  "jsondata": {
    label: t ? t("commons.downloadFormat.jsondata") : "jsondata",
    extension: "json"
  },
  "sdmx-csv": {
    label: t ? t("commons.downloadFormat.sdmxCsv") : "sdmx-csv",
    extension: "csv",
    param: "csv"
  },
  [DOWNLOAD_FORMAT_IMAGE]: {
    label: t ? t("commons.downloadFormat.jpeg") : "jpeg",
    extension: "jpeg",
    viewers: [DOWNLOAD_VIEWER_CHART, DOWNLOAD_VIEWER_MAP]
  },
  [DOWNLOAD_FORMAT_EXCEL]: {
    label: t ? t("commons.downloadFormat.excel") : "excel",
    extension: "xlsx",
    viewers: [DOWNLOAD_VIEWER_TABLE]
  },
  [DOWNLOAD_FORMAT_CSV]: {
    label: t ? t("commons.downloadFormat.csv") : "csv",
    extension: "csv",
    viewers: [DOWNLOAD_VIEWER_TABLE]
  }
});

export const isDownloadFormatValid = format => downloadFormats()?.[format] !== undefined

export const getDownloadFormatLabelFromFormat = (format, t) => downloadFormats(t)[format]?.label;

export const getDownloadFormatExtensionFromFormat = format => downloadFormats()[format]?.extension;

export const getDownloadFormatViewersFromFormat = format => downloadFormats()[format]?.viewers;

function getClonedCanvas(oldCanvas) {
  const newCanvas = document.createElement('canvas');
  const context = newCanvas.getContext('2d');

  newCanvas.width = oldCanvas.width;
  newCanvas.height = oldCanvas.height;

  context.drawImage(oldCanvas, 0, 0);

  return newCanvas;
}

export const exportViewerCanvas = (canvas, fileName, title, layout, jsonStat) => {
  const div = $('<div>').css({
    position: 'absolute',
    left: -(canvas.width + 1)
  }).appendTo('body').get(0);

  if (title && title.length > 0) {
    $(`<div>${title}</div>`)
      .css({
        width: canvas.width,
        height: "100%",
        margin: "8px 0",
        padding: "0 8px",
        fontSize: 20
      })
      .appendTo(div);
  }

  if (layout && jsonStat && layout.filters && layout.filters.length > 0) {
    const staticFiltersDivs = $(`<div/>`)
      .css({
        width: canvas.width,
        height: "100%",
        margin: "8px 0",
        padding: "0 8px",
        fontSize: 14
      })
      .appendTo(div);

    layout.filters.forEach((dim, idx) => {
      const dimLabel = getDimensionLabelFromJsonStat(jsonStat, dim);
      const value = layout.filtersValue[dim];
      const valueLabel = getDimensionValueLabelFromJsonStat(jsonStat, dim, value);

      $(`<div><div style="display: inline-block"><b>${(dimLabel || dim)}</b>: <i>${(valueLabel || value)}</i></div><div style="display: inline-block">${idx < layout.filters.length - 1 ? "," : ""}</div></div>`)
        .css({
          display: "inline-block",
          marginRight: 8
        })
        .appendTo(staticFiltersDivs);
    });
  }

  div.appendChild(getClonedCanvas(canvas));

  html2canvas(div).then(function (canvas) {
    const link = document.createElement("a");
    link.download = fileName;
    link.target = "_blank";

    link.href = canvas.toDataURL("image/jpeg");

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    $(div).remove();
  });
};

export const getCustomExportRequestBody = (datasetId, datasetTitle, criteria, originalLayout, params, t) => {

  const ignoreFilters = params?.ignoreFilters === true;

  const layout = Object.assign({}, originalLayout);
  if (params?.hasVariation && (params?.showTrend || params?.showCyclical)) {
    layout.cols = [...layout.cols, VARIATION_DIMENSION_KEY];
  }
  if (ignoreFilters) {
    layout.filtersValue = null;
    layout.primaryDimValues = null;
    layout.secondaryDimValues = null;
  }

  return {
    data: {
      datasetId: datasetId,
      datasetLabel: datasetTitle,
      dataCriterias: getCriteriaArrayFromObject(criteria)
    },
    layout: layout,
    parameters: {
      decimalNumber: params?.decimalNumber || null,
      decimalSeparator: params?.decimalSeparator || null,
      emptyCellPlaceHolder: params?.emptyCellPlaceHolder || null,
      labelFormat: params?.labelFormat || null,
      customLabelFormat: params?.customLabelFormat || null,
      csvSeparator: params?.colsSeparator || null,
      csvTextQualifier: params?.textQualifier || null,
      datasetAttributesLabel: t("commons.export.custom.attributes.values.dataset"),
      seriesAttributesLabel: t("commons.export.custom.attributes.values.series"),
      excelLimitMessage: t("commons.export.custom.messages.excel.colsAndRowsLimitsExceeded"),
      excelRowsLimitMessage: t("commons.export.custom.messages.excel.rowsLimitsExceeded", {rowsLimit: params?.exportConfig?.maxRowsPerSheet}),
      excelColumnsLimitMessage: t("commons.export.custom.messages.excel.colsLimitsExceeded", {colsLimit: params?.exportConfig?.maxColsPerSheet}),
      observationValueLabel: t("commons.export.custom.messages.csv.observationLabel")
    },
    variation: {
      hasVariation: params?.hasVariation || false,
      showTrend: params?.showTrend || null,
      showCyclical: params?.showCyclical || null,
      variationLabel: t("commons.export.custom.variation.title"),
      valueLabel: t("commons.export.custom.variation.values.value"),
      trendLabel: t("commons.export.custom.variation.values.trend"),
      cyclicalLabel: t("commons.export.custom.variation.values.cyclical")
    }
  }
};