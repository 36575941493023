import React, {forwardRef} from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import IconButton from "@material-ui/core/IconButton";

const styles = theme => ({
  attributeIcon: {
    width: 24,
    height: 24,
    fontSize: 23,
    fontWeight: "bold",
    fontFamily: "Do Hyeon"
  }
});

const AttributeButton = forwardRef(({
                                      classes,
                                      onClick,
                                      iconStyle,
                                      ...props
                                    }, ref) =>
  <IconButton onClick={onClick} ref={ref} {...props}>
    <span className={classes.attributeIcon} style={iconStyle}>
      (*)
    </span>
  </IconButton>
)

export default withStyles(styles)(AttributeButton);