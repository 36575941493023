import {APP_LANGUAGE_SET} from "../../state/app/appActions";
import i18n from 'i18next';
import {clearHub} from "../../state/hub/hubActions";

const i18nMiddleware = ({getState, dispatch}) => next => action => {

  const res = next(action);

  window.language = getState().app.language;

  if (action.type === APP_LANGUAGE_SET) {
    i18n.changeLanguage(action.payload.language, () => {
      dispatch(clearHub());
    });
  }

  return res;

};

export default i18nMiddleware;