import React, {Fragment, useState} from "react";
import {compose} from "redux";
import withStyles from "@material-ui/core/styles/withStyles";
import {withTranslation} from "react-i18next";
import Tooltip from "@material-ui/core/Tooltip";
import Dialog from "@material-ui/core/Dialog";
import CustomDialogTitle from "../custom-dialog-title";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import DimensionAttributeList from "../dimension-attribute-list";
import {getDimensionValuesAttributeList} from "../../utils/jsonStat";

const styles = theme => ({
  attributeButton: {
    padding: 8,
    cursor: "pointer"
  },
  attributeIcon: {
    height: 18,
    lineHeight: "18px",
    padding: "0 4px",
    borderRadius: 3
  },
  attributeIconAst: {
    fontSize: 18,
    fontFamily: "Do Hyeon",
    color: theme.palette.primary.main
  },
  attributeIconChar: {
    fontSize: 13,
    color: "rgb(255, 255, 255)",
    backgroundColor: "rgb(108, 108, 108)",
    margin: "0 4px",
  }
});

const AttributeIcon = ({
                         t,
                         classes,
                         jsonStat,
                         layout,
                         dimension,
                         dimAttributeMap,
                         labelFormat,
                         iconStyle
                       }) => {

  const [isVisible, setVisibility] = useState(false);

  const dimValuesAttributeList = getDimensionValuesAttributeList(dimension, dimAttributeMap, layout);

  let ids = null;
  if (dimValuesAttributeList.length === 1) {
    ids = dimValuesAttributeList[0].ids
  }

  return (
    <Fragment>
      {dimValuesAttributeList.length > 0 && (
        <Tooltip
          title={
            <DimensionAttributeList
              jsonStat={jsonStat}
              dimension={dimension}
              dimValuesAttributeList={dimValuesAttributeList}
              labelFormat={labelFormat}
              style={{fontSize: 12}}
            />
          }
          placement="top"
        >
          <span
            className={classes.attributeButton}
            style={iconStyle}
            role="button"
            onClick={() => setVisibility(true)}
          >
            {(ids && ids.length === 1 && ids[0].length <= 2)
              ? (
                <span className={`${classes.attributeIcon} ${classes.attributeIconChar}`}>
                  {ids[0]}
                </span>
              )
              : (
                <span className={`${classes.attributeIcon} ${classes.attributeIconAst}`}>
                  (*)
                </span>
              )
            }
          </span>
        </Tooltip>
      )}

      <Dialog
        open={isVisible}
        onClose={() => setVisibility(false)}
      >
        <CustomDialogTitle onClose={() => setVisibility(false)}>
          {t("components.attributeIcons.dialog.title")}
        </CustomDialogTitle>
        <DialogContent>
          <DimensionAttributeList
            jsonStat={jsonStat}
            dimension={dimension}
            dimValuesAttributeList={dimValuesAttributeList}
            labelFormat={labelFormat}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setVisibility(false)}>
            {t("commons.confirm.close")}
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  )
};

export default compose(
  withStyles(styles),
  withTranslation()
)(AttributeIcon);