import React, {Fragment, useState} from 'react';
import {compose} from "redux";
import withStyles from "@material-ui/core/styles/withStyles";
import {withTranslation} from "react-i18next";
import Box from "@material-ui/core/Box";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Paper from "@material-ui/core/Paper";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import MenuItem from "@material-ui/core/MenuItem";
import {
  CHART_DATA_LABEL_TYPE_NONE,
  CHART_DATA_LABEL_TYPE_VALUE,
  CHART_LEGEND_POSITION_BOTTOM,
  CHART_LEGEND_POSITION_lEFT,
  CHART_LEGEND_POSITION_RIGHT,
  CHART_LEGEND_POSITION_TOP
} from "../chart";
import ChartSettingsColors from "./Colors";
import I18nTextField from "../i18n-text-field";

const styles = theme => ({
  root: {
    height: "100%",
    padding: 4
  },
  tabs: {
    height: 48,
    marginBottom: 4
  },
  tabContent: {
    overflowY: "auto",
    overflowX: "hidden",
    height: "calc(100% - 52px)"
  },
  paper: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(3),
    paddingLeft: theme.spacing(3) / 2,
    "& :first-child": {
      marginTop: 0
    }
  },
  field: {
    marginTop: theme.spacing(3),
    paddingLeft: theme.spacing(3) / 2
  }
});

function ChartSettings(props) {
  const {
    t,
    classes,
    jsonStat,
    settings,
    onSettingsSet
  } = props;

  const {
    chartStacked,
    chartLegendPosition,
    chartColors,
    chartShowTitle,
    chartTitle,
    chartShowFilters,
    chartShowAxesLabel,
    chartCustomizeCategoryAxis,
    chartCategoryAxisLabel,
    chartValueAxisLabel,
    chartDataLabelType
  } = settings

  const [tabId, setTabId] = useState(0);

  return (
    <div className={classes.root}>
      <Box className={classes.tabs}>
        <Tabs
          value={tabId}
          variant="scrollable"
          scrollButtons="auto"
          onChange={(event, newValue) => setTabId(newValue)}
        >
          <Tab key={0} label={t("scenes.dataViewer.chartSettings.tabs.general")}/>
          <Tab key={1} label={t("scenes.dataViewer.chartSettings.tabs.colors")}/>
          <Tab key={2} label={t("scenes.dataViewer.chartSettings.tabs.export")}/>
        </Tabs>
      </Box>
      <div className={classes.tabContent}>
        {tabId === 0 && (
          <Fragment>
            <FormControl fullWidth className={classes.field}>
              <FormControlLabel
                label={t("scenes.dataViewer.chartSettings.isStacked.label")}
                control={
                  <Checkbox
                    checked={chartStacked}
                    onChange={(e, value) => onSettingsSet({...settings, chartStacked: value})}
                  />
                }
              />
            </FormControl>
            <FormControl fullWidth className={classes.field}>
              <TextField
                select
                fullWidth
                label={t("scenes.dataViewer.chartSettings.legendPosition.label")}
                value={chartLegendPosition}
                variant="outlined"
                onChange={ev => onSettingsSet({...settings, chartLegendPosition: ev.target.value})}
                SelectProps={{SelectDisplayProps: {"aria-haspopup": true}}}
              >
                <MenuItem value={CHART_LEGEND_POSITION_TOP}>
                  {t("scenes.dataViewer.chartSettings.legendPosition.values.top")}
                </MenuItem>
                <MenuItem value={CHART_LEGEND_POSITION_RIGHT}>
                  {t("scenes.dataViewer.chartSettings.legendPosition.values.right")}
                </MenuItem>
                <MenuItem value={CHART_LEGEND_POSITION_BOTTOM}>
                  {t("scenes.dataViewer.chartSettings.legendPosition.values.bottom")}
                </MenuItem>
                <MenuItem value={CHART_LEGEND_POSITION_lEFT}>
                  {t("scenes.dataViewer.chartSettings.legendPosition.values.left")}
                </MenuItem>
              </TextField>
            </FormControl>
            <FormControl fullWidth className={classes.field}>
              <FormControlLabel
                label={t("scenes.dataViewer.chartSettings.dataLabelType.label")}
                control={
                  <Checkbox
                    checked={chartDataLabelType === CHART_DATA_LABEL_TYPE_VALUE}
                    onChange={(e, value) => {
                      onSettingsSet({
                        ...settings,
                        chartDataLabelType: value
                          ? CHART_DATA_LABEL_TYPE_VALUE
                          : CHART_DATA_LABEL_TYPE_NONE
                      })
                    }}
                  />
                }
              />
            </FormControl>
            <FormControl fullWidth className={classes.field}>
              <FormControlLabel
                label={t("scenes.dataViewer.chartSettings.showAxesLabel.label")}
                control={
                  <Checkbox
                    checked={chartShowAxesLabel}
                    onChange={(e, value) => onSettingsSet({...settings, chartShowAxesLabel: value})}
                  />
                }
              />
            </FormControl>
            {chartShowAxesLabel && (
              <Paper variant="outlined" className={classes.paper}>
                <FormControl fullWidth className={classes.field}>
                  <I18nTextField
                    label={t("scenes.dataViewer.chartSettings.valueAxisLabel.label")}
                    variant="outlined"
                    value={chartValueAxisLabel}
                    onChange={value => onSettingsSet({...settings, chartValueAxisLabel: value})}
                  />
                </FormControl>
                <FormControl fullWidth className={classes.field}>
                  <FormControlLabel
                    label={t("scenes.dataViewer.chartSettings.customizeCategoryAxis.label")}
                    control={
                      <Checkbox
                        checked={chartCustomizeCategoryAxis}
                        onChange={(e, value) => onSettingsSet({...settings, chartCustomizeCategoryAxis: value})}
                      />
                    }
                  />
                </FormControl>
                {chartCustomizeCategoryAxis && (
                  <FormControl fullWidth className={classes.field}>
                    <I18nTextField
                      label={t("scenes.dataViewer.chartSettings.categoryAxisLabel.label")}
                      variant="outlined"
                      value={chartCategoryAxisLabel}
                      onChange={value => onSettingsSet({...settings, chartCategoryAxisLabel: value})}
                    />
                  </FormControl>
                )}
              </Paper>
            )}
          </Fragment>
        )}
        {tabId === 1 && (
          <ChartSettingsColors
            jsonStat={jsonStat}
            colors={chartColors}
            onColorsSet={newColors => onSettingsSet({...settings, chartColors: newColors})}
          />
        )}
        {tabId === 2 && (
          <Fragment>
            <Paper variant="outlined" className={classes.paper}>
              <FormControl fullWidth className={classes.field}>
                <FormControlLabel
                  label={t("scenes.dataViewer.chartSettings.showTitle.label")}
                  control={
                    <Checkbox
                      checked={chartShowTitle}
                      onChange={(e, value) => onSettingsSet({...settings, chartShowTitle: value})}
                    />
                  }
                />
              </FormControl>
              <FormControl fullWidth className={classes.field}>
                <I18nTextField
                  label={t("scenes.dataViewer.chartSettings.title.label")}
                  variant="outlined"
                  value={chartTitle}
                  onChange={value => onSettingsSet({...settings, chartTitle: value})}
                />
              </FormControl>
            </Paper>
            <FormControl fullWidth className={classes.field}>
              <FormControlLabel
                label={t("scenes.dataViewer.chartSettings.showFilters.label")}
                control={
                  <Checkbox
                    checked={chartShowFilters}
                    onChange={(e, value) => onSettingsSet({...settings, chartShowFilters: value})}
                  />
                }
              />
            </FormControl>
          </Fragment>
        )}
      </div>
    </div>
  )
}

export default compose(
  withStyles(styles),
  withTranslation()
)(ChartSettings);