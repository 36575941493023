import React, {Fragment, useEffect, useState} from 'react';
import NodeHeader from "../node-header";
import DataViewer from "../data-viewer";
import Page from "../page";
import {withStyles} from "@material-ui/core";
import {useLocation} from "react-router";
import {useTranslation} from "react-i18next";
import {getPageTitle} from "../../utils/other";
import Footer from "../footer";
import {HashLink} from 'react-router-hash-link';

const styles = () => ({
  fullWidthContainer: {
    backgroundColor: "#f5f5f5",
    width: "100%",
    height: "100%",
    position: "fixed",
    display: "flex",
    flexDirection: "column",
    "& #footer": {
      paddingTop: 0
    }
  }
});

const Data = ({
                classes,
                hub,
                node,
                isDefault,
                catalog,
                categoryPath,
                isAccessible,
                datasetId,
                datasetTitle,
                viewId,
                notes,
                attachedFiles,
                referenceMetadataUrl
              }) => {

  const location = useLocation();
  const {t} = useTranslation();

  const [nodeExtras, setNodeExtras] = useState(null);
  const [hubExtras, setHubExtras] = useState(null);

  useEffect(() => {
    if (node?.extras) {
      const newNodeExtras = {};
      node.extras.forEach(({key, value}) => {
        try {
          newNodeExtras[key] = JSON.parse(value);
        } catch (e) {
          newNodeExtras[key] = value;
        }
      });
      setNodeExtras(newNodeExtras);
    }
  }, [node]);

  useEffect(() => {
    const newHubExtras = JSON.parse(hub.hub.extras || "{}");
    setHubExtras(newHubExtras);
  }, [hub]);

  const params = new URLSearchParams(location.search);
  params.set("accessible", "true");
  params.set("datasetId", datasetId)
  const paramsStr = params.toString();
  const path = `/${window.language}/${node?.code.toLowerCase()}/${categoryPath.join("/")}`;

  return (
    <Fragment>
      {!isAccessible && (
        <a
          href={"./#" + path + (paramsStr.length > 0 ? "?" : "") + paramsStr}
          target="_self"
          className="skip-link sr-only"
        >
          {t("commons.hashLinks.accessible")}
        </a>
      )}
      <HashLink
        to={{hash: "#main", search: location.search}}
        className="skip-link sr-only"
      >
        {t("commons.hashLinks.main")}
      </HashLink>
      <HashLink
        to={{hash: "#footer", search: location.search}}
        className="skip-link sr-only"
      >
        {t("commons.hashLinks.footer")}
      </HashLink>
      <Page title={getPageTitle([datasetTitle], t)}>
        <NodeHeader
          hub={hub.hub}
          nodes={hub?.nodes}
          catalog={catalog}
          title={node?.name}
          node={node}
          nodeId={node?.nodeId}
          isDefault={isDefault}
          selectedCategoryPath={categoryPath}
          getCustomA11yPath={isA11y => {
            if (isA11y) {
              return `/${window.language}/${node?.code.toLowerCase()}/${categoryPath.join("/")}`;
            } else {
              return false;
            }
          }}
          getAdditionalA11yUrlParams={isA11y => {
            if (isA11y) {
              return {datasetId};
            } else {
              return false;
            }
          }}
        />
        <main id="main" className={classes.fullWidthContainer}>
          <DataViewer
            nodeId={node?.nodeId}
            nodeCode={node?.code}
            categoryPath={categoryPath}
            isAccessible={isAccessible}
            datasetId={datasetId}
            datasetTitle={datasetTitle}
            viewId={viewId}
            notes={notes}
            attachedFiles={attachedFiles}
            referenceMetadataUrl={referenceMetadataUrl}
            hubExtras={hubExtras}
            nodeExtras={nodeExtras}
            maxObservation={hub?.hub?.maxObservationsAfterCriteria}
          />
          <Footer/>
        </main>
      </Page>
    </Fragment>
  )
};

export default withStyles(styles)(Data);