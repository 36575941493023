import React, {useEffect} from 'react';
import Dialog from "@material-ui/core/Dialog";

const $ = window.jQuery;

const FullscreenDialog = ({
                            children,
                            ...props
                          }) => {

  useEffect(() => {
    return () => {
      if ($(".MuiDialog-paperFullScreen").length <= 1) {
        document.body.classList.remove("body--hidden-scrollbar");
      }
    }
  }, []);

  return (
    <Dialog
      {...props}
      fullScreen
      TransitionProps={{
        onEnter: () => {
          document.body.classList.add("body--hidden-scrollbar");
        },
        onExit: () => {
          if ($(".MuiDialog-paperFullScreen").length <= 1) {
            document.body.classList.remove("body--hidden-scrollbar");
          }
        }
      }}
    >
      {children}
    </Dialog>
  );
};

export default FullscreenDialog;