import React, {Fragment, useState} from 'react';
import {compose} from "redux";
import withStyles from "@material-ui/core/styles/withStyles";
import {withTranslation} from "react-i18next";
import Box from "@material-ui/core/Box";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Paper from "@material-ui/core/Paper";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import I18nTextField from "../i18n-text-field";

const styles = theme => ({
  root: {
    height: "100%",
    padding: 4
  },
  tabs: {
    height: 48,
    marginBottom: 4
  },
  tabContent: {
    overflowY: "auto",
    overflowX: "hidden",
    height: "calc(100% - 52px)"
  },
  paper: {
    marginTop: theme.spacing(2),
    padding: 12,
    paddingLeft: theme.spacing(3) / 2,
    "& :first-child": {
      marginTop: 0
    }
  },
  field: {
    marginTop: theme.spacing(3),
    paddingLeft: theme.spacing(3) / 2
  }
});

function MapSettings(props) {
  const {
    t,
    classes,
    settings,
    onSettingsSet
  } = props;

  const {
    mapShowTitle,
    mapTitle,
    mapShowFilters
  } = settings

  const [tabId, setTabId] = useState(0);

  return (
    <div className={classes.root}>
      <Box className={classes.tabs}>
        <Tabs
          value={tabId}
          variant="scrollable"
          scrollButtons="auto"
          onChange={(event, newValue) => setTabId(newValue)}
        >
          <Tab key={0} label={t("scenes.dataViewer.mapSettings.tabs.export")}/>
        </Tabs>
      </Box>
      <div className={classes.tabContent}>
        {tabId === 0 && (
          <Fragment>
            <Paper variant="outlined" className={classes.paper}>
              <FormControl fullWidth className={classes.field}>
                <FormControlLabel
                  label={t("scenes.dataViewer.mapSettings.showTitle.label")}
                  control={
                    <Checkbox
                      checked={mapShowTitle}
                      onChange={(e, value) => onSettingsSet({...settings, mapShowTitle: value})}
                    />
                  }
                />
              </FormControl>
              <FormControl fullWidth className={classes.field}>
                <I18nTextField
                  label={t("scenes.dataViewer.mapSettings.title.label")}
                  variant="outlined"
                  value={mapTitle}
                  onChange={value => onSettingsSet({...settings, mapTitle: value})}
                />
              </FormControl>
            </Paper>
            <FormControl fullWidth className={classes.field}>
              <FormControlLabel
                label={t("scenes.dataViewer.mapSettings.showFilters.label")}
                control={
                  <Checkbox
                    checked={mapShowFilters}
                    onChange={(e, value) => onSettingsSet({...settings, mapShowFilters: value})}
                  />
                }
              />
            </FormControl>
          </Fragment>
        )}
      </div>
    </div>
  )
}

export default compose(
  withStyles(styles),
  withTranslation()
)(MapSettings);