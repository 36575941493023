import React from 'react';
import {withStyles} from "@material-ui/core";
import {useTranslation} from "react-i18next";

import Autocomplete from '@material-ui/lab/Autocomplete';
import ListSubheader from "@material-ui/core/ListSubheader";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import {VariableSizeList} from "react-window";
import SearchIcon from "@material-ui/icons/Search";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";

/* Based on https://material-ui.com/components/autocomplete/#virtualization */

const styles = theme => ({
  button: {
    borderRadius: theme.shape.borderRadius,
    backgroundColor: "#f5f5f5",
    borderColor: theme.palette.primary.main,
    borderWidth: 1,
    position: "relative",
    height: 35,
  },
  icon: {
    width: theme.spacing(6),
    height: 35,
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
    position: 'absolute',
    width: `calc(100% - ${theme.spacing(6)})`,
    left: theme.spacing(6)
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
  },
  listbox: {
    overflowX: "hidden",
    boxSizing: "border-box",
    "& ul": {
      padding: 0,
      margin: 0
    }
  }
});

const LISTBOX_PADDING = 8; // px

function renderRow(props) {
  const {data, index, style} = props;
  return React.cloneElement(data[index], {
    style: {
      ...style,
      top: style.top + LISTBOX_PADDING
    }
  });
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data) {
  const ref = React.useRef(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

// Adapter for react-window
const ListboxComponent = React.forwardRef(function ListboxComponent(props, ref) {
  const {children, ...other} = props;
  const itemData = React.Children.toArray(children);
  const itemCount = itemData.length;
  const itemSize = 36;

  const getChildSize = (child) => {
    if (React.isValidElement(child) && child.type === ListSubheader) {
      return 48;
    }

    return itemSize;
  };

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
  };

  const gridRef = useResetCache(itemCount);

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={(index) => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

const AutocompleteSearchInput = ({classes, query = "", options, onSubmit}) => {

  const {t} = useTranslation();

  return (
    <div className={classes.button} style={{width: "100%"}}>
      <label htmlFor="search-input" style={{display: "none"}}>
        {t("components.searchInput.label")}
      </label>
      <Autocomplete
        classes={{listbox: classes.listbox}}
        freeSolo
        defaultValue={null}
        disableListWrap
        ListboxComponent={ListboxComponent}
        options={options || []}
        renderOption={(option, state) => {
          const searchText = state?.inputValue || "";
          const start = (option || "").toLowerCase().indexOf(searchText.toLowerCase());
          const end = start + searchText.length;

          return (
            <Tooltip title={option}>
              <Typography noWrap>
                {option.substr(0, start)}
                <b>{option.substr(start, end - start)}</b>
                {option.substr(end)}
              </Typography>
            </Tooltip>
          )
        }}
        onChange={(event, newValue) => {
          setTimeout(
            () => {
              onSubmit(newValue);
            },
            1
          );
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            id="search-input"
            fullWidth
            size="small"
            variant="outlined"
            placeholder={t("components.searchInput.placeholder")}
            InputProps={{
              ...params.InputProps,
              startAdornment:
                <InputAdornment position="start">
                  <SearchIcon/>
                </InputAdornment>
            }}
          />
        )}
      />
    </div>
  );
};

export default withStyles(styles)(AutocompleteSearchInput);