import React from 'react';
import SvgIcon from "@material-ui/core/SvgIcon";

const RadarIcon = ({props}) =>
  <SvgIcon {...props}>
    <path
      d="M 5.45,2.06
        C 4.05,2.59 4.72,4.26 5.94,3.93
         7.01,3.64 6.79,1.93 5.45,2.06 Z
        M 6.63,3.58
        C 6.15,4.17 5.87,4.17 5.15,4.17
         5.15,4.17 3.38,9.39 3.38,9.39
         4.30,9.96 4.21,10.35 3.97,11.26
         3.97,11.26 8.60,13.82 8.60,13.82
         8.86,13.51 9.06,13.31 9.49,13.25
         10.18,13.17 10.85,13.82 10.74,14.51
         10.67,15.02 10.26,15.18 10.30,15.60
         10.33,15.84 10.80,16.61 10.94,16.88
         10.94,16.88 12.64,19.93 12.64,19.93
         13.46,19.75 13.75,19.67 14.32,20.42
         14.32,20.42 18.75,17.86 18.75,17.86
         18.75,17.86 18.57,17.17 18.57,17.17
         18.46,16.16 19.27,15.82 20.13,15.89
         20.13,15.89 21.01,11.85 21.01,11.85
         19.99,11.66 19.57,10.89 20.13,9.98
         19.75,9.53 18.96,9.04 18.45,8.70
         18.27,8.57 17.87,8.28 17.67,8.24
         17.39,8.19 17.18,8.37 16.88,8.42
         16.34,8.49 16.01,8.19 15.69,7.81
         15.08,8.14 14.97,8.41 14.51,8.90
         14.37,9.04 14.10,9.29 14.05,9.49
         13.96,9.83 14.32,10.00 14.39,10.47
         14.48,11.07 13.93,11.74 13.33,11.78
         13.33,11.78 12.44,11.66 12.44,11.66
         12.02,10.78 11.80,10.17 12.84,9.59
         12.48,9.10 11.99,8.73 11.56,8.31
         11.56,8.31 8.80,5.55 8.80,5.55
         8.25,5.00 7.28,3.88 6.63,3.58 Z
        M 16.29,6.42
        C 15.36,6.97 15.73,8.11 16.58,8.26
         17.81,8.47 18.20,6.50 16.88,6.35
         16.64,6.32 16.51,6.36 16.29,6.42 Z
        M 2.69,9.56
        C 2.37,9.73 2.14,9.90 2.04,10.28
         1.85,11.05 2.66,11.67 3.38,11.39
         4.35,11.00 4.12,9.25 2.69,9.56 Z
        M 12.84,9.78
        C 11.96,10.27 12.11,11.45 13.13,11.61
         14.19,11.78 14.63,10.28 13.72,9.78
         13.43,9.65 13.14,9.68 12.84,9.78 Z
        M 20.72,9.75
        C 19.47,10.30 20.12,11.94 21.31,11.61
         22.01,11.42 22.20,10.54 21.74,10.02
         21.45,9.70 21.11,9.68 20.72,9.75 Z
        M 9.20,13.50
        C 8.00,14.19 8.89,15.72 9.98,15.29
         10.69,15.00 10.72,14.08 10.25,13.67
         9.94,13.39 9.58,13.41 9.20,13.50 Z
        M 19.44,16.06
        C 18.03,16.65 18.89,18.33 20.03,17.90
         21.09,17.51 20.76,15.85 19.44,16.06 Z
        M 13.04,20.00
        C 11.75,20.55 12.43,22.19 13.62,21.86
         14.67,21.57 14.48,19.76 13.04,20.00 Z"
    />
  </SvgIcon>

export default RadarIcon;