import React, {Fragment, useState} from "react";
import {withTranslation} from "react-i18next";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Dialog from "@material-ui/core/Dialog";
import CustomDialogTitle from "../custom-dialog-title";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import SanitizedHTML from "../sanitized-html";

const CatalogInfoButton = ({
                             t,
                             info,
                             label,
                             iconSize
                           }) => {

  const [isVisible, setVisibility] = useState(false);

  const onOpen = () => {
    setVisibility(true);
  };

  const onClose = ev => {
    ev.stopPropagation();
    setVisibility(false);
  };

  if (!info) {
    return <span/>;
  }

  return (
    <Fragment>
      <Tooltip title={label || t("components.catalogInfoButton.label")}>
        <IconButton
          size={iconSize}
          onClick={ev => {
            ev.stopPropagation();
            onOpen();
          }}
          aria-label={label || t("components.catalogInfoButton.label")}
        >
          <InfoOutlinedIcon/>
        </IconButton>
      </Tooltip>

      <Dialog
        open={isVisible}
        maxWidth="sm"
        fullWidth
        onClose={onClose}
      >
        <CustomDialogTitle onClose={onClose}>
          {label || t("components.catalogInfoButton.label")}
        </CustomDialogTitle>
        <DialogContent>
          <SanitizedHTML html={info} allowTarget/>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>
            {t("commons.confirm.close")}
          </Button>
        </DialogActions>
      </Dialog>

    </Fragment>
  );
}

export default withTranslation()(CatalogInfoButton);