import React, {Fragment, useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import withStyles from "@material-ui/core/styles/withStyles";
import IconButton from "@material-ui/core/IconButton";
import InfoIcon from '@material-ui/icons/Info';
import ShareIcon from '@material-ui/icons/Share';
import GetAppIcon from '@material-ui/icons/GetApp';
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import CustomDialogTitle from "../custom-dialog-title";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import AttachFileIcon from '@material-ui/icons/AttachFile';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import ButtonSelect from "../button-select";
import SaveIcon from '@material-ui/icons/Save';
import AttributeList from "../attribute-list";
import {
  getDatasetAttributeList,
  getDimensionAttributeMap,
  getDimensionLabelFromJsonStat,
  getDimensionValueLabelFromJsonStat,
  getSeriesAttributeList
} from "../../utils/jsonStat";
import {DECIMAL_SEPARATOR_DEFAULT} from "../../utils/formatters";
import {canSaveAsView, canSaveTemplate, canShare, canViewTemplateOrAnnotationIcon, canViewTimesLog} from "../../utils/user";
import {connect} from "react-redux";
import {compose} from "redux";
import {localizeI18nObj, validateI18nObj} from "../../utils/i18n";
import {
  DOWNLOAD_FORMAT_IMAGE,
  DOWNLOAD_VIEWER_CHART,
  DOWNLOAD_VIEWER_MAP,
  DOWNLOAD_VIEWER_TABLE,
  exportViewerCanvas,
  getDownloadFormatExtensionFromFormat,
  getDownloadFormatLabelFromFormat,
  getDownloadFormatViewersFromFormat,
  isDownloadFormatValid
} from "../../utils/download";
import DataViewerTimings from "./Timings";
import {LABEL_FORMAT_SELECTOR_LABEL_FORMAT_NAME} from "../label-format-selector/constants";
import ViewBuilder from "./ViewBuilder";
import TemplateBuilder from "./TemplateBuilder";
import {getCriteriaArrayFromObject} from "../../utils/criteria";
import {
  fetchDatasetSdmxQuery,
  fetchDatasetViewTemplateGeometries,
  hideDatasetSdmxQuery,
  hideDatasetViewError,
  hideDatasetViewTemplate,
  showDatasetSdmxQuery,
  showDatasetViewTemplate
} from "../../state/dataset/datasetActions";
import FullscreenDialog from "../fullscreen-dialog";
import DeveloperModeIcon from '@material-ui/icons/DeveloperMode';
import Query from "../query";
import AttributeButton from "../attribute-button";
import {isValidIntegerInInclusiveRange} from "../../utils/validator";
import Call from "../../hocs/call";
import AttributeIcon from "../attribute-icon";
import Typography from "@material-ui/core/Typography";

const styles = theme => ({
  root: {
    width: "100%",
    height: "100%",
    padding: "4px 16px 12px 16px",
    color: theme.palette.primary.main
  },
  titleContainer: {
    paddingTop: 10,
    minHeight: 48
  },
  title: {
    display: "unset",
    fontSize: 28,
    fontWeight: 300,
    letterSpacing: 0,
    marginRight: 2
  },
  titleAction: {
    marginTop: -10
  },
  layoutIcon: {
    width: 48,
    height: 48,
    padding: 12,
    color: "rgba(0, 0, 0, 0.54)"
  },
  attributeId: {
    cursor: "default",
    fontSize: 13,
    color: "rgb(255, 255, 255)",
    backgroundColor: "rgb(108, 108, 108)",
    borderRadius: 3,
    padding: "0 4px"
  },
  attributeAst: {
    cursor: "default",
    fontSize: 15,
    color: theme.palette.primary.main,
    fontFamily: "Do Hyeon"
  },
  queryContainer: {
    '& > *': {
      marginBottom: theme.spacing(2)
    },
    '& > *:last-child': {
      marginBottom: 0
    }
  }
});

const mapStateToProps = state => ({
  user: state.user,
  defaultLanguage: state.app.language,
  languages: state.app.languages,
  view: state.dataset.view,
  template: state.dataset.template,
  isViewVisible: state.dataset.isViewVisible,
  isViewErrorVisible: state.dataset.isViewErrorVisible,
  viewErrorMessage: state.dataset.viewErrorMessage,
  isTemplateVisible: state.dataset.isTemplateVisible,
  templateGeometries: state.dataset.templateGeometries,
  templateGeometryDetailLevels: state.dataset.templateGeometryDetailLevels,
  isQueryVisible: state.dataset.isQueryVisible,
  structureQuery: state.dataset.structureQuery,
  dataQuery: state.dataset.dataQuery
});

const mapDispatchToProps = dispatch => ({
  onViewTemplateShow: isView => dispatch(showDatasetViewTemplate(isView)),
  onViewTemplateHide: isView => dispatch(hideDatasetViewTemplate(isView)),
  onViewErrorHide: isView => dispatch(hideDatasetViewError(isView)),
  onViewTemplateGeometriesFetch: (idList, t, nodeId) => dispatch(fetchDatasetViewTemplateGeometries(idList, t, nodeId)),
  onQueryShow: () => dispatch(showDatasetSdmxQuery()),
  onQueryHide: () => dispatch(hideDatasetSdmxQuery()),
  fetchQuery: ({nodeId, datasetId, criteria, datasetTitle}) =>
    dispatch(fetchDatasetSdmxQuery(nodeId, datasetId, criteria, datasetTitle)),
});

const getEmptyViewTemplate = (viewTemplate, datasetId, decimalPlaces, decimalSeparator, defaultLanguage) => ({
  datasetId: datasetId,
  type: "",
  title: {},
  defaultView: "table",
  criteria: {},
  layouts: {
    labelFormat: LABEL_FORMAT_SELECTOR_LABEL_FORMAT_NAME,
    showTrend: false,
    showCyclical: false,
    tableDefaultLayout: "custom",
    tableEmptyChar: "",
    tableLayout: {},
    mapLayout: {},
    chartLayout: {}
  },
  decimalSeparator: {
    [defaultLanguage]: (decimalSeparator !== null && decimalSeparator !== undefined) ? decimalSeparator : DECIMAL_SEPARATOR_DEFAULT
  },
  enableCriteria: true,
  enableLayout: true,
  enableVariation: false,
  hiddenDimensions: [],
  ...viewTemplate,
  decimalNumber: (decimalPlaces === null || decimalPlaces === undefined || decimalPlaces < 0)
    ? ""
    : decimalPlaces,
});

function DataViewerHeader(props) {

  const {
    classes,
    user,
    defaultLanguage,
    languages,
    nodeId,
    nodeDownloadFormats,
    hiddenAttributes,
    showQueryInfo,
    datasetId,
    datasetTitle,
    viewId,
    hasViewLayout,
    hasTemplateLayout,
    hasAnnotationLayout,
    view: structureView,
    template: structureTemplate,
    notes,
    attachedFiles,
    jsonStat,
    timePeriodsByFreq,
    labelFormat,
    showTrend,
    showCyclical,
    tableLayout,
    chartLayout,
    mapLayout,
    criteria,
    decimalSeparator,
    decimalPlaces,
    tableEmptyChar,
    chartSettings,
    mapDetailLevel,
    mapSettings,
    viewers,
    viewerIdx,
    isViewVisible,
    isViewErrorVisible,
    viewErrorMessage,
    isTemplateVisible,
    templateGeometries,
    templateGeometryDetailLevels,
    onViewTemplateShow,
    onViewTemplateHide,
    onViewTemplateSubmit,
    onViewErrorHide,
    onDownloadSubmit,
    onViewTemplateGeometriesFetch,
    timings,
    chartId,
    mapId,
    isQueryVisible,
    structureQuery,
    dataQuery,
    onQueryShow,
    onQueryHide,
    fetchQuery
  } = props;

  const {t} = useTranslation();

  const [initialView, setInitialView] = useState(null);
  const [view, setView] = useState(null);

  const [initialTemplate, setInitialTemplate] = useState(null);
  const [template, setTemplate] = useState(null);

  const [isAttributesVisible, setAttributesVisibility] = useState(false);
  const [isNotesVisible, setNotesVisibility] = useState(false);
  const [isTimingsVisible, setTimingsVisibility] = useState(false);

  const [downloadFormats, setDownloadFormats] = useState([]);

  const [isUpdatingView, setIsUpdatingView] = useState(false);

  useEffect(() => {
    const actionsWidth = document.getElementById("dataset-viewer-header__actions")
      ? document.getElementById("dataset-viewer-header__actions").offsetWidth
      : 0;
    document.getElementById("dataset-viewer-header__title").setAttribute("style", `width: calc(100% - ${actionsWidth}px - 8px)`);
  });

  useEffect(() => {
    const template = getEmptyViewTemplate(structureTemplate, datasetId, decimalPlaces, decimalSeparator, defaultLanguage);
    setInitialTemplate(template);
    setTemplate(template)
    const view = getEmptyViewTemplate(structureView, datasetId, decimalPlaces, decimalSeparator, defaultLanguage);
    setInitialView(view);
    setView(view);
  }, [structureView, structureTemplate, datasetId, tableEmptyChar, decimalPlaces, decimalSeparator, defaultLanguage]);

  useEffect(() => {
    const newDownloadFormats = [];
    (nodeDownloadFormats || []).forEach(format => {
      if (isDownloadFormatValid(format)) {
        newDownloadFormats.push({
          label: getDownloadFormatLabelFromFormat(format, t),
          format: format,
          extension: getDownloadFormatExtensionFromFormat(format),
          viewers: getDownloadFormatViewersFromFormat(format)
        })
      }
    });
    setDownloadFormats(newDownloadFormats);
  }, [nodeDownloadFormats, t]);

  const handleViewOpen = (defaultLanguage, isUpdate) => {
    onViewTemplateShow(true);
    setView({
      ...initialView,
      title: isUpdate
        ? view.title
        : {
          [defaultLanguage]: datasetTitle
        },
      defaultView: viewers[viewerIdx].type,
      criteria: criteria,
      layouts: {
        labelFormat: labelFormat,
        showTrend: showTrend,
        showCyclical: showCyclical,
        tableEmptyChar: tableEmptyChar,
        tableLayout: viewerIdx === 0 ? tableLayout : undefined,
        mapLayout: viewerIdx === 1 ? mapLayout : undefined,
        mapDetailLevel: viewerIdx === 1 ? mapDetailLevel : undefined,
        ...(viewerIdx === 1 ? mapSettings : {}),
        chartLayout: viewerIdx >= 2 ? chartLayout : undefined,
        ...(viewerIdx >= 2 ? chartSettings : {})
      }
    });
  };

  const handleViewClose = () => {
    onViewTemplateHide(true);
    setView(initialView);
  };

  const handleViewSubmit = viewId => {
    onViewTemplateSubmit({
      type: "view",
      viewTemplateId: viewId ? Number(viewId) : undefined,
      datasetId: view.datasetId,
      title: view.title,
      defaultView: view.defaultView,
      criteria: getCriteriaArrayFromObject(view.criteria),
      layouts: JSON.stringify(view.layouts)
    }, true);
    setView(initialView);
  };

  const handleTemplateOpen = (defaultLanguage, isUpdate) => {
    onViewTemplateShow(false);
    setTemplate({
      ...initialTemplate,
      title: isUpdate
        ? initialTemplate.title
        : {
          [defaultLanguage]: datasetTitle
        },
      criteria: criteria,
      layouts: {
        labelFormat: labelFormat,
        showTrend: showTrend,
        showCyclical: showCyclical,
        tableEmptyChar: tableEmptyChar,
        tableLayout: tableLayout,
        tableDefaultLayout: "custom",
        mapLayout: mapLayout,
        mapDetailLevel: mapLayout ? mapDetailLevel : undefined,
        ...(mapLayout ? mapSettings : {}),
        chartLayout: chartLayout,
        ...(chartLayout ? chartSettings : {})
      }
    });
  };

  const handleTemplateClose = () => {
    onViewTemplateHide(false);
    setTemplate(initialTemplate);
  };

  const handleTemplateSubmit = () => {
    onViewTemplateSubmit({
      ...template,
      type: "template",
      criteria: getCriteriaArrayFromObject(template.criteria),
      layouts: JSON.stringify({
        ...template.layouts,
        tableDefaultLayout: undefined
      }),
      decimalNumber: (template.decimalNumber === null || template.decimalNumber === undefined || template.decimalNumber === "")
        ? -1
        : template.decimalNumber
    }, false);
    setTemplate(initialTemplate);
  };

  const datasetAttributes = getDatasetAttributeList(jsonStat, hiddenAttributes);

  const seriesAttributes = getSeriesAttributeList(jsonStat, labelFormat, hiddenAttributes);

  const currentFilters = (viewers[viewerIdx].key === 0 && tableLayout?.filters)
    ? tableLayout.filters
    : (viewers[viewerIdx].key === 1 && mapLayout?.filters)
      ? mapLayout.filters
      : (viewers[viewerIdx].key >= 2 && chartLayout?.filters)
        ? chartLayout.filters
        : [];

  const subtitle = [];
  if (jsonStat && currentFilters) {
    currentFilters.forEach(filter => {
      if (jsonStat.size[jsonStat.id.indexOf(filter)] === 1) {
        subtitle.push({
          dim: filter,
          dimLabel: getDimensionLabelFromJsonStat(jsonStat, filter, labelFormat),
          value: jsonStat.dimension[filter].category.index[0],
          valueLabel: getDimensionValueLabelFromJsonStat(jsonStat, filter, jsonStat.dimension[filter].category.index[0], labelFormat)
        });
      }
    })
  }

  const dimAttributeMap = getDimensionAttributeMap(jsonStat, hiddenAttributes);

  const hasNotes = (notes || []).length > 0;
  const hasAttributes = datasetAttributes.concat(seriesAttributes).length > 0;

  return (
    <Fragment>
      <div className={classes.root}>
        <Grid container justify="space-between" alignItems="flex-start">
          <Grid item id="dataset-viewer-header__title" className={classes.titleContainer}>
            <Typography variant={"h1"} className={classes.title}>
              {datasetTitle}
            </Typography>
            {hasNotes && (
              <Tooltip title={t("scenes.dataViewer.header.action.information.tooltip")}>
                <IconButton
                  id="dataset-notes-btn"
                  onClick={() => setNotesVisibility(true)}
                  aria-label={t("scenes.dataViewer.header.action.information.tooltip")}
                  className={classes.titleAction}
                >
                  <InfoIcon/>
                </IconButton>
              </Tooltip>
            )}
            <Tooltip title={t("scenes.dataViewer.header.action.attributes.tooltip")}>
              <AttributeButton
                id="dataset-attributes-btn"
                onClick={() => setAttributesVisibility(true)}
                aria-label={t("scenes.dataViewer.header.action.attributes.tooltip")}
                className={classes.titleAction}
                style={{
                  visibility: hasAttributes ? undefined : "hidden"
                }}
              />
            </Tooltip>
          </Grid>
          <Grid item id="dataset-viewer-header__actions">
            <Grid container alignItems="center">
              {(() => {
                if (canViewTemplateOrAnnotationIcon(user, nodeId)) {

                  let title = null;
                  if (hasViewLayout) {
                    title = t("scenes.dataViewer.header.action.hasViewLayout.tooltip");
                  } else if (hasTemplateLayout) {
                    title = t("scenes.dataViewer.header.action.hasTemplateLayout.tooltip");
                  } else if (hasAnnotationLayout) {
                    title = t("scenes.dataViewer.header.action.hasAnnotationLayout.tooltip");
                  }

                  if (title) {
                    return (
                      <div className={classes.layoutIcon} tabIndex={0} aria-label={title}>
                        <Tooltip title={title}>
                          <InfoIcon/>
                        </Tooltip>
                      </div>
                    )
                  }
                }
              })()}
              {canViewTimesLog(user, nodeId) && (
                <Grid item id="dataset-times-log-btn">
                  <Tooltip title={t("scenes.dataViewer.header.action.timesLog.tooltip")}>
                    <div>
                      <IconButton
                        onClick={() => setTimingsVisibility(true)}
                        disabled={timings === null}
                        aria-label={t("scenes.dataViewer.header.action.timesLog.tooltip")}
                      >
                        <AccessTimeIcon/>
                      </IconButton>
                    </div>
                  </Tooltip>
                </Grid>
              )}
              {(canSaveAsView(user) || canSaveTemplate(user, nodeId)) && (
                <Grid item id="dataset-save-btn">
                  <ButtonSelect
                    icon={<SaveIcon/>}
                    tooltip={t("scenes.dataViewer.header.action.save.tooltip")}
                    ariaLabel={t("scenes.dataViewer.header.action.save.tooltip")}
                    color="default"
                    disabled={!tableLayout && !chartLayout && !mapLayout}
                  >
                    {canSaveAsView(user) && (
                      <div
                        onClick={() => {
                          handleViewOpen(defaultLanguage, false);
                          setIsUpdatingView(false);
                        }}
                      >
                        {t("scenes.dataViewer.header.action.save.values.createView")}
                      </div>
                    )}
                    {canSaveAsView(user) && hasViewLayout && viewId && (
                      <div
                        onClick={() => {
                          handleViewOpen(defaultLanguage, true);
                          setIsUpdatingView(true);
                        }}
                      >
                        {t("scenes.dataViewer.header.action.save.values.updateView")}
                      </div>
                    )}
                    {canSaveTemplate(user, nodeId) && (
                      <div onClick={() => handleTemplateOpen(defaultLanguage, hasTemplateLayout)}>
                        {hasTemplateLayout
                          ? t("scenes.dataViewer.header.action.save.values.updateTemplate")
                          : t("scenes.dataViewer.header.action.save.values.createTemplate")
                        }
                      </div>
                    )}
                  </ButtonSelect>
                </Grid>
              )}
              {canShare(user) && (
                <Grid item id="dataset-share-btn">
                  <Tooltip title={t("scenes.dataViewer.header.action.share.tooltip")}>
                    <IconButton
                      onClick={window.notImplemented.show}
                      aria-label={t("scenes.dataViewer.header.action.share.tooltip")}
                    >
                      <ShareIcon/>
                    </IconButton>
                  </Tooltip>
                </Grid>
              )}
              <Grid item id="dataset-attachments-btn">
                <ButtonSelect
                  icon={<AttachFileIcon/>}
                  tooltip={t("scenes.dataViewer.header.action.attachments.tooltip")}
                  ariaLabel={t("scenes.dataViewer.header.action.attachments.tooltip")}
                  color="default"
                  onChange={({attachedFile}) => window.open(attachedFile.url)}
                  disabled={!attachedFiles || attachedFiles.length === 0}
                >
                  {(attachedFiles || []).map((attachedFile, idx) => {
                    const fileName = attachedFile.url.split("/").pop();
                    return (
                      <div key={idx} data-value={{attachedFile}}>
                        {`${fileName} (${attachedFile.format})`}
                      </div>
                    )
                  })}
                </ButtonSelect>
              </Grid>
              {showQueryInfo && (
                <Grid item id="dataset-query-btn">
                  <Tooltip title={t("scenes.dataViewer.header.action.query.tooltip")}>
                    <IconButton
                      onClick={onQueryShow}
                      aria-label={t("scenes.dataViewer.header.action.query.tooltip")}
                    >
                      <DeveloperModeIcon/>
                    </IconButton>
                  </Tooltip>
                </Grid>
              )}
              {(() => {
                const formats = downloadFormats.filter(({viewers}) => {
                  if (viewerIdx === 0) {
                    return !viewers || viewers.includes(DOWNLOAD_VIEWER_TABLE);
                  } else if (viewerIdx === 1) {
                    return !viewers || viewers.includes(DOWNLOAD_VIEWER_MAP);
                  } else {
                    return !viewers || viewers.includes(DOWNLOAD_VIEWER_CHART);
                  }
                });

                return formats.length > 0
                  ? (
                    <Grid item id="dataset-export-btn">
                      <ButtonSelect
                        icon={<GetAppIcon/>}
                        tooltip={t("scenes.dataViewer.header.action.export.tooltip")}
                        ariaLabel={t("scenes.dataViewer.header.action.export.ariaLabel")}
                        color="default"
                        onChange={({format, extension}) => {
                          if (format === DOWNLOAD_FORMAT_IMAGE) {
                            if (viewerIdx === 1) {
                              window.LMap.getCanvas(
                                mapId,
                                canvas => {
                                  exportViewerCanvas(
                                    canvas,
                                    `${datasetTitle}.${extension}`,
                                    mapSettings.mapShowTitle === true
                                      ? localizeI18nObj(mapSettings.mapTitle, defaultLanguage, languages)
                                      : null,
                                    mapSettings.mapShowFilters === true
                                      ? mapLayout
                                      : null,
                                    mapSettings.mapShowFilters === true
                                      ? jsonStat
                                      : null
                                  );
                                }
                              );
                            } else if (viewerIdx >= 2) {
                              const chartCanvas = document.getElementById(chartId);
                              exportViewerCanvas(
                                chartCanvas,
                                `${datasetTitle}.${extension}`,
                                chartSettings.chartShowTitle === true
                                  ? localizeI18nObj(chartSettings.chartTitle, defaultLanguage, languages)
                                  : null,
                                chartSettings.chartShowFilters === true
                                  ? chartLayout
                                  : null,
                                chartSettings.chartShowFilters === true
                                  ? jsonStat
                                  : null
                              );
                            }
                          } else {
                            onDownloadSubmit(format, extension);
                          }
                        }}
                      >
                        {formats.map(({format, label, extension}, idx) =>
                          <div
                            key={idx}
                            data-value={{format, extension}}
                            aria-label={t("scenes.dataViewer.header.action.export.format.ariaLabel", {datasetTitle: datasetTitle, formatLabel: label})}
                          >
                            {label}
                          </div>
                        )}
                      </ButtonSelect>
                    </Grid>
                  )
                  : <span/>
              })()}
            </Grid>
          </Grid>
        </Grid>
        {subtitle && dimAttributeMap && subtitle.map(({dim, dimLabel, value, valueLabel}, idx) =>
          <Fragment key={idx}>
            <b>{dimLabel}</b>
            <span>:&nbsp;</span>
            <i>{valueLabel}</i>
            <AttributeIcon
              jsonStat={jsonStat}
              layout={viewerIdx === 0
                ? tableLayout
                : viewerIdx === 1
                  ? mapLayout
                  : chartLayout
              }
              dimension={dim}
              dimAttributeMap={dimAttributeMap}
              labelFormat={labelFormat}
              iconStyle={{padding: 0}}
            />
            {idx < subtitle.length - 1
              ? <span>,&nbsp;</span>
              : ""
            }
          </Fragment>
        )}
      </div>

      <Dialog
        open={isAttributesVisible}
        fullWidth
        maxWidth="md"
        onClose={() => setAttributesVisibility(false)}
      >
        <CustomDialogTitle onClose={() => setAttributesVisibility(false)}>
          {t("scenes.dataViewer.header.dialogs.attributes.title")}
        </CustomDialogTitle>
        <DialogContent>
          <AttributeList
            datasetAttributes={datasetAttributes}
            seriesAttributes={seriesAttributes}
            labelFormat={labelFormat}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setAttributesVisibility(false)}>
            {t("commons.confirm.close")}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={isTimingsVisible}
        onClose={() => setTimingsVisibility(false)}
      >
        <DialogContent style={{width: 400}}>
          <DataViewerTimings
            timings={timings}
          />
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => setTimingsVisibility(false)}>
            {t("commons.confirm.close")}
          </Button>
        </DialogActions>
      </Dialog>

      {initialView && (
        <Fragment>
          <Dialog
            open={isViewVisible}
            maxWidth="md"
            onClose={handleViewClose}
          >
            <CustomDialogTitle onClose={handleViewClose}>
              {isUpdatingView
                ? t("scenes.dataViewer.header.dialogs.view.title.update")
                : t("scenes.dataViewer.header.dialogs.view.title.create")
              }
            </CustomDialogTitle>
            <DialogContent>
              <ViewBuilder
                view={view}
                onChange={setView}
                criteria={criteria}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleViewClose}>
                {t("commons.confirm.cancel")}
              </Button>
              <Button
                color="primary"
                autoFocus
                onClick={() => handleViewSubmit(isUpdatingView ? viewId : null)}
                disabled={!validateI18nObj(view.title)}
              >
                {t("commons.confirm.save")}
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={isViewErrorVisible}
            maxWidth="md"
            onClose={onViewErrorHide}
          >
            <CustomDialogTitle onClose={onViewErrorHide}>
              {t("scenes.dataViewer.header.dialogs.duplicateViewError.title")}
            </CustomDialogTitle>
            <DialogContent>
              {viewErrorMessage && (
                <Fragment>
                  {t("scenes.dataViewer.header.dialogs.duplicateViewError.content") + ": "}
                  <b>{Object.keys(viewErrorMessage).join(", ")}</b>
                </Fragment>
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={onViewErrorHide}>
                {t("commons.confirm.close")}
              </Button>
            </DialogActions>
          </Dialog>
        </Fragment>
      )}

      {initialTemplate && (
        <FullscreenDialog
          open={isTemplateVisible}
          onClose={handleTemplateClose}
        >
          <CustomDialogTitle onClose={handleTemplateClose}>
            {hasTemplateLayout
              ? t("scenes.dataViewer.header.dialogs.template.title.update")
              : t("scenes.dataViewer.header.dialogs.template.title.create")
            }
          </CustomDialogTitle>
          <DialogContent>
            <TemplateBuilder
              defaultLanguage={defaultLanguage}
              languages={languages}
              nodeId={nodeId}
              template={template}
              onChange={setTemplate}
              viewers={viewers.filter(({hidden}) => hidden !== true)}
              jsonStat={jsonStat}
              timePeriodsByFreq={timePeriodsByFreq}
              labelFormat={labelFormat}
              tableLayout={tableLayout}
              chartLayout={chartLayout}
              mapLayout={mapLayout}
              templateGeometries={templateGeometries}
              templateGeometryDetailLevels={templateGeometryDetailLevels}
              onGeometryFetch={isTemplateVisible ? onViewTemplateGeometriesFetch : () => null}
              hiddenAttributes={hiddenAttributes}
              criteria={criteria}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleTemplateClose}>
              {t("commons.confirm.cancel")}
            </Button>
            <Button
              color="primary"
              autoFocus
              onClick={handleTemplateSubmit}
              disabled={(
                !validateI18nObj(template.title) ||
                !validateI18nObj(template.decimalSeparator) ||
                ((template.decimalNumber || "").length > 0 && !isValidIntegerInInclusiveRange(template.decimalNumber, 0, 20))
              )}
            >
              {t("commons.confirm.save")}
            </Button>
          </DialogActions>
        </FullscreenDialog>
      )}

      <Dialog
        open={isNotesVisible}
        fullWidth
        maxWidth="md"
        onClose={() => setNotesVisibility(false)}
      >
        <CustomDialogTitle onClose={() => setNotesVisibility(false)}>
          {t("scenes.dataViewer.header.dialogs.notes.title")}
        </CustomDialogTitle>
        <DialogContent>
          {notes}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setNotesVisibility(false)}>
            {t("commons.confirm.close")}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={isQueryVisible}
        fullWidth
        maxWidth="md"
        onClose={onQueryHide}
      >
        <CustomDialogTitle onClose={onQueryHide}>
          {t("scenes.dataViewer.header.dialogs.query.title")}
        </CustomDialogTitle>
        <DialogContent className={classes.queryContainer}>
          <Call
            cb={fetchQuery}
            cbParam={{nodeId, datasetId, criteria, datasetTitle}}
            disabled={structureQuery !== null && dataQuery !== null}
          >
            {structureQuery && (
              <Query
                title={t("scenes.dataViewer.header.dialogs.query.content.structureQuery.title")}
                query={structureQuery}
              />
            )}
            {dataQuery && (
              <Query
                title={t("scenes.dataViewer.header.dialogs.query.content.dataQuery.title")}
                query={dataQuery}
              />
            )}
          </Call>
        </DialogContent>
        <DialogActions>
          <Button onClick={onQueryHide}>
            {t("commons.confirm.close")}
          </Button>
        </DialogActions>
      </Dialog>

    </Fragment>
  )
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(DataViewerHeader)